import React, { useEffect, useState } from 'react';
import { Card, Form, Button, Spin, Input, Row, Col, DatePicker, Divider, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { CloseOutlined } from '@ant-design/icons';
import { AddAnotherCard } from '../../common/addAnotherCard';
import WarningModal from '../../common/modal/WarningModal';
import { getUserData } from '../../../helpers/functions';

const UniversityDegree = ({ handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const { eid } = useParams();
  const [form] = Form.useForm();
  const { isCertificateUpdating, certiMsg, certiErrorMsg } = useSelector(state => state.steps);
  const { isDegreeLoading, degreeList } = useSelector(state => state.expert);   
  const { isAdminDeleteDegreeLoading } = useSelector(state => state.admin);
  const [showWarningModal, setShowWarningModal] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'EXPERT_DEGREE_REQUEST',
      formData: { expert_id: eid || getUserData().id },
    });
  }, [eid, dispatch, isAdminDeleteDegreeLoading]);

  useEffect(() => {
    if (!isDegreeLoading) {
      form.setFieldsValue({
        degrees: degreeList.length
          ? degreeList.map(item => ({
              ...item,
              issuance_date: dayjs(item.issuance_date),
            }))
          : [{ expert_id: eid || getUserData().id, name: '', level: 1, issuance_date: null, institution: '' }],
      });
    }
  }, [isDegreeLoading, degreeList, form, eid]);

  const handleSubmit = values => {
    const degrees = values.degrees.map((item, index) => ({
      ...item,
      issuance_date: item.issuance_date.format('YYYY-MM-DD'),
      expert_id: eid || getUserData().id,
      level: values.degrees.length - index,
    }));

    if (degrees.length) {
      dispatch({ type: 'EXPERT_CERTIFICATE_UPDATE_REQUEST', formData: degrees, moduleType: 'degree' });
    } else {
      message.error('Please add at least one degree');
    }
  };

  const handleDelete = (field, id, remove) => {
    remove(field.name);
    if (id) dispatch({ type: 'ADMIN_DELETE_DEGREE', id });
  };

  useEffect(() => {
    if (!isCertificateUpdating && certiMsg === 'success' && !certiErrorMsg) {
      handleNext('certification');
      dispatch({ type: 'CLEAR_STEPS' });
    } else if (!isCertificateUpdating && certiErrorMsg) {
      message.error(certiErrorMsg || 'Something went wrong');
      dispatch({ type: 'CLEAR_STEPS' });
    }
  }, [isCertificateUpdating, certiMsg, certiErrorMsg, dispatch, handleNext]);

  return (
    <div className="site-card-border-less-wrapper card-wrapper">
      <Card bordered={false}>
        {isDegreeLoading ? (
          <Spin />
        ) : (
          <>
            <div className="m-title tl mb-7 flex-y">
              <p className="d-flex">
                <span className="back-icon" onClick={() => handleBack('degree')}>
                  <IoMdArrowRoundBack />
                </span>
                <span>I have the following education</span>
              </p>
              <p className="help-text d-flex">
                Insert the highest degree first, as this will appear on your public profile.
              </p>
            </div>
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
              <Form.List name="degrees">
                {(fields, { add, remove }) => (
                  <Col span={24}>
                    {fields.map(field => (
                      <Row key={field.name} gutter={30} className="mb20 certificate-section">
                        {fields.length > 1 && (
                          <CloseOutlined onClick={() => handleDelete(field, form.getFieldValue(['degrees', field.name, 'id']), remove)} className="cursor-p" />
                        )}
                        <Col span={24}>
                          <Form.Item label="Degree" name={[field.name, 'name']} rules={[{ required: true, message: 'Please enter degree name' }]}>
                            <Input size="large" placeholder="Degree" />
                          </Form.Item>
                          <Form.Item label="Issuance Date" name={[field.name, 'issuance_date']} rules={[{ required: true, message: 'Please select a date!' }]}>
                            <DatePicker style={{ width: '100%' }} size="large" />
                          </Form.Item>
                          <Form.Item label="Institution" name={[field.name, 'institution']} rules={[{ required: true, message: 'Please enter institution' }]}>
                            <Input size="large" placeholder="Institution" />
                          </Form.Item>
                        </Col>
                      </Row>
                    ))}
                    <AddAnotherCard handleClick={add} text="Add another degree" />
                  </Col>
                )}
              </Form.List>
              <Divider className="mt40 mb40" />
              <Form.Item>
                <Button type="primary" size="middle" htmlType="submit" loading={isCertificateUpdating}>
                  Next
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </Card>
      {showWarningModal && (
        <WarningModal
          showWarningModal={showWarningModal}
          handleWarningModelGotIt={() => setShowWarningModal(false)}
          text={`Make sure to add all your degrees! Including all your degrees helps clients understand your full educational background. Use the <strong>"Add Another Degree"</strong> button below for each one.`}
        />
      )}
    </div>
  );
};

export default UniversityDegree;
