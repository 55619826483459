import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { Button, Col, Modal, Rate, Row, Tooltip, message } from 'antd';
import Cookies from 'js-cookie';
import QuotationModal from './modal/quatationModal';
import PaymentModal from './modal/paymentModal';
import expertIcon from '../../assets/images/profile_placeholder.png';
import { AssignQuestionToExpertModal } from './modal/assignToModal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RequestRejectModal } from './modal/requestRejectModal';
import {
  breakName,
  createMarkup,
  getExpertiyInIndustery,
  getRanksOfExperts,
  getUserData,
  role,
} from '../../helpers/functions';
import useResponsive from "../../hooks/useBreakpoint"
import WhatsNextModal from './modal/WhatsNextModal';
import { nameRecommendationConstantForToolTip, ROLE_NAME } from '../../helpers/constant';
import Circleprogress from './circleprogress';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import paymentInfoIcon from '../../assets/images/paymentInfoIcon.jpeg';

export const AssignedToExpertCard = ({ data, setSelectedTab }) => {

  const dispatch = useDispatch();
  const [quotationModal, setQuotationModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [requestRejectModal, setRequestRejectModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(null);
  const [showFullProposal, setShowFullProposal] = useState(false);
  const [showWhatsNextModal, setShowWhatsNextModal] = useState(false);
  const [dontShowWhatsNext, setDontShowWhatsNext] = useState(false);
  const [ranks, setRanks] = useState([0, 0, 0, 0, 0]);
  const navigate = useNavigate();
  const proposalLimit = 500;
  const { isDontShowWhatsNext } = useSelector(state => state.common);
  const [infoModel, setInfoModel] = useState(false);
  const state = useSelector(state => state);

  useEffect(() => {
    if (role() != ROLE_NAME.ADMIN) {
      dispatch({ type: 'RECOMMENDED_EXPERT_LIST_REQUEST', questionId: data?.status?.question_id });
    }
  }, [data?.status?.question_id]);
  useEffect(() => {
    if (state?.expert?.recommendedExpertsList?.length > 0 && showAssignModal == null) {
      setRanks(getRanksOfExperts(state?.expert?.recommendedExpertsList, data?.expert?.id));
    } else {
      setRanks(ranks);
    }
  }, [state?.expert?.recommendedExpertsList.length, data?.status?.question_id]);

  useEffect(() => {
    if (!Cookies.get(`dontShowWhatsNext_${data?.user?.id}`)) {
      dispatch({ type: 'GET_DONT_SHOW_WHATS_NEXT_MODAL', userId: data?.user?.id });
    }
    if (isDontShowWhatsNext) {
      Cookies.set(`dontShowWhatsNext_${data?.user?.id}`, 'true', { expires: 365 });
    }
    const dontShowWhatsNext = Cookies.get(`dontShowWhatsNext_${data?.user?.id}`);
    if (!dontShowWhatsNext) {
      setDontShowWhatsNext(true);
    }
  }, [showAssignModal]);

  const handleExpertRequestAccept = () => {
    dispatch({
      type: 'POST_CLIENT_ACCEPT_OR_REJECT_EXPERT_REQUEST',
      formData: { question_id: data?.id, is_approve: 1 },
    });
  };

  const handleCloseModal = () => {
    if (dontShowWhatsNext) {
      setShowWhatsNextModal(true);
    }
  };

  const parseProposal = proposal => {
    if (data?.status?.question_status?.status_order >= 7) {
      return proposal;
    } else {
      let fullName = data?.expert?.first_name + ' ' + data?.expert?.last_name;
      let updatedProposal = proposal.replace(
        new RegExp(fullName, 'g'),
        breakName(data?.expert?.first_name, data?.expert?.last_name),
      );
      return updatedProposal;
    }
  };

  const breakpoint = useResponsive();
  return (
    <div className="request-assigned-to-container">
      {data?.status?.question_status_id === 2 && data?.assign_by_admin === 0 ? (
        <h3>Find another expert</h3>
      ) : (
        <h3>We recommend</h3>
      )}
      {data?.status?.question_status?.status_order <= 9 && (
        <Row className="assigned-to-profile-container"  >
          {data?.status?.question_status_id === 2 && data?.assign_by_admin === 0 ? (
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ maxWidth: '70%' }}>
              Unfortunately, the expert you previously selected has rejected your request. Please
              select a different expert to assist you.
            </Col>
          ) : (
            <>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}
                className="assigned-to-profile-left-container"
                onClick={() => navigate(`/find-expert-detail/${data?.expert?.id}`)}
              >
                <div className="assigned-to-profile-icon">
                  <img
                    alt="expert"
                    height={60}
                    width={60}
                    src={data?.expert_meta?.avatar ? data?.expert_meta?.avatar : expertIcon}
                    loading="lazy"
                  />
                </div>
                <div className="assigned-to-profile-detail">
                  <div className="assigned-to-profile-name">
                    {data?.status?.question_status?.status_order >= 7 ? (
                      data?.expert?.first_name + ' ' + data?.expert?.last_name
                    ) : (
                      <Tooltip
                        title={nameRecommendationConstantForToolTip?.title || ''}
                        color={nameRecommendationConstantForToolTip?.color}
                      >
                        {breakName(data?.expert?.first_name, data?.expert?.last_name)}
                      </Tooltip>
                    )}
                  </div>
                  <div className="assigned-to-profile-role">{data?.category?.name}</div>
                </div>
              </Col>
            </>
          )}

          {data?.question_review?.length &&
            (data?.status?.question_status_id === 10 || data?.status?.question_status_id === 11) ? (
            <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16} className="assigned-to-rating-container">
              <div className="assigned-to-rating-container-div">My Review</div>
              <Rate disabled value={data?.question_review[0]?.rating} />
            </Col>
          ) : null}

          <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16} className="assigned-to-profile-button-group">
            {setSelectedTab && (
              <Button
                className="assigned-to-profile-button"
                style={{
                  backgroundColor: 'white',
                  border: '1px solid #1c64f2',
                  color: '#1c64f2',
                }}
                onClick={() => setSelectedTab('messaging')}
                size={breakpoint === 'xs' ? 'small' : 'default'}
              >
                Messaging
              </Button>
            )}
            {data?.status?.question_status_id !== 10 &&
              data?.status?.question_status_id !== 11 &&
              data?.status?.question_status_id < 4 &&
              data?.assign_by_admin === 0 ? (
              <Button
                className="assigned-to-profile-button"
                type="primary"
                onClick={() => setShowAssignModal(true)}
              >
                Find Another
              </Button>
            ) : null}
            {data?.status?.question_status_id !== 10 &&
              data?.status?.question_status_id !== 11 &&
              // data?.status?.question_status_id < 4 &&
              data?.assign_by_admin === 1 &&
              data?.expert_approved !== 1 ? (
              <>
                <Button

                  className="assigned-to-profile-button"
                  type={data?.expert_approved === 1 ? 'default' : 'primary'}
                  disabled={data?.expert_approved === 1}
                  onClick={() => {
                    if (data?.expert_approved === 0) {
                      handleExpertRequestAccept();
                    } else {
                      message.error('Waiting for expert approval.');
                    }
                  }}
                  size={breakpoint === 'xs' ? 'small' : 'default'}
                >
                  Accept
                </Button>
                <Button
                  className="assigned-to-profile-button"
                  danger
                  disabled={data?.expert_approved === 1}
                  onClick={() => {
                    if (data?.expert_approved === 0) {
                      setShowAssignModal(true);
                    } else {
                      message.error('Waiting for expert approval.');
                    }
                  }}
                  size={breakpoint === 'xs' ? 'small' : 'default'}
                >
                  Other Options
                </Button>
              </>
            ) : null}
            {data?.status?.question_status_id === 5 && (
              <Button
                className="assigned-to-profile-button"
                type="primary"
                onClick={() => setQuotationModal(true)}
              >
                Quotation Details
              </Button>
            )}
          </Col>
        </Row>
      )}

      <Row style={{ display: 'flex', justifyContent: !(breakpoint === 'xs' || breakpoint === 'sm') && 'space-between' }}>

        <Col xs={8} sm={6} md={3}>
          <Circleprogress
            percentage={getExpertiyInIndustery(ranks[2] || 0)}
            heading="Experience"
            info={{
              perfect: 'Extensive hands-on experience in the field with consistent, proven results.',
              moderate: 'Solid track record with increasing responsibility and results over time.',
              unsure: 'Some familiarity or early-stage exposure to the field.',
            }}
          />
        </Col>
        <Col xs={8} sm={6} md={3}>
          <Circleprogress
            percentage={ranks[1] || 0}
            heading="subcategory"
            info={{
              perfect: 'Deep expertise specific to the service subcategory.',
              moderate: 'Experience with similar subcategories.',
              unsure: 'Limited exposure to the service subcategory.',
            }}
          />
        </Col>
        <Col xs={8} sm={6} md={3}>
          <Circleprogress
            percentage={ranks[4] || 0}
            heading="industry"
            info={{
              perfect: 'Extensive experience specific to the industry.',
              moderate: 'Proven track record in related industries.',
              unsure: 'Some familiarity with the industry.',
            }}
          />
        </Col>
        <Col xs={8} sm={6} md={3}>
          <Circleprogress
            percentage={ranks[6] || 0}
            heading="Reviews"
            info={{
              perfect: 'Consistently outstanding reviews.',
              moderate: 'Mostly positive reviews with minor issues.',
              unsure: 'Mixed reviews or limited feedback available.',
            }}
          />
        </Col>
        <Col xs={8} sm={6} md={3}>
          <Circleprogress
            percentage={ranks[5] || 0}
            heading="Location"
            info={{
              perfect: 'Located in the same province.',
              moderate: 'Located in a neighboring province.',
              unsure: 'Located in a different region of Canada.',
            }}
          />
        </Col>
        <Col xs={8} sm={6} md={3}>
          <Circleprogress
            percentage={ranks[7] || 0}
            heading="Language"
            info={{
              perfect: 'Communicate in one or more of your other language(s).',
              moderate: 'pending',
              unsure: 'Do not communicate in your other language(s).',
            }}
          />
        </Col>
        <Col xs={8} sm={6} md={3}>
          <Circleprogress
            percentage={ranks[8] || 0}
            heading="Availability"
            info={{
              perfect: 'High availability; more time to dedicate to new clients.',
              moderate: 'Moderate availability; balancing other clients.',
              unsure: 'Limited availability; minimal time for new clients.',
            }}
          />
        </Col>
      </Row>
      {data?.proposal ? (
        <div style={{ 'max-height': '100%' }}>
          <pre className="assigned-to-profile-proposal mt-4">
            {data.proposal.length > proposalLimit && !showFullProposal ? (
              <span
                className="request-span"
                dangerouslySetInnerHTML={createMarkup(
                  parseProposal(data.proposal).slice(0, proposalLimit) + '...',
                )}
              />
            ) : (
              <span
                className="request-span"
                dangerouslySetInnerHTML={createMarkup(parseProposal(data.proposal))}
              />
            )}
          </pre>
          {data.proposal.length > proposalLimit ? (
            <Button onClick={() => setShowFullProposal(val => !val)}>
              {showFullProposal ? 'Show less' : 'Show more'}
            </Button>
          ) : null}
        </div>
      ) : null}

      {quotationModal && (
        <QuotationModal
          type="customer"
          open={quotationModal}
          close={() => setQuotationModal(false)}
          qid={data.id}
          questionData={data}
          openPayment={() => {
            setQuotationModal(false);
            setPaymentModal(true);
            setInfoModel(true);
          }}
        />
      )}
      {paymentModal && (
        <PaymentModal
          type="customer"
          open={paymentModal}
          close={() => setPaymentModal(false)}
          quotationId={data.quotation_detail !== null ? data?.quotation_detail?.id : ''}
          quotationDetail={data}
        />
      )}

      <AssignQuestionToExpertModal
        visible={showAssignModal}
        setVisible={setShowAssignModal}
        rowData={data}
        variant="client"
        onClose={handleCloseModal}
        onClickNeedHelp={setRequestRejectModal}
      />

      <WhatsNextModal
        visible={showWhatsNextModal}
        setVisible={setShowWhatsNextModal}
        userId={data?.user?.id}
        expertName={`${data?.expert?.first_name} ${data?.expert?.last_name}`}
      />
      <RequestRejectModal
        visible={requestRejectModal}
        setShowModal={setRequestRejectModal}
        rawData={data}
        variant="client"
      />
      <Modal
        title={null}
        open={infoModel}
        footer={
          <div>
            <Button
              type="primary"
              size="middle"
              className="mt5"
              onClick={() => setInfoModel(false)}
            >
              Got it
            </Button>
          </div>
        }
        closable={false}
        zIndex={10000}
        centered
      >
        <p style={{ fontSize: '18px', textAlign: 'center' }}>
          <div>
            <img
              loading="lazy"
              width={150}
              style={{ borderRadius: '50%' }}
              src={paymentInfoIcon}
              alt="Info"
            />
          </div>
          <p className="mt10">
            All payments are securely processed through Stripe. We do not store any payment
            information on our servers.
          </p>
        </p>
      </Modal>
    </div>
  );
};
