import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
const { Header } = Layout;

const QuestionHeader = ({ type = 'question' }) => {
  const navigate = useNavigate();
  const { eid } = useParams();

  return (
    <>
      <Header className="header b0">
        <div className="d-flex-between">
          <div className="main-logo">{eid ? 'Edit Expert Profile ' : 'Expert Sign Up'}</div>
          <div className="header-menu d-flex-a-c">
            <AiOutlineClose
              className="cursor-p"
              size={20}
              onClick={() =>
                navigate(eid ? `/application-detail/${eid}` : '/dashboard/expert/detail')
              }
            />
          </div>
        </div>
      </Header>
    </>
  );
};

export default QuestionHeader;
