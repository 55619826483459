import React, { useState, useEffect } from 'react';
import { Progress, Form, Card, Divider, Button, Input, Row, Col, message, Checkbox } from 'antd';
import Helmet from '../common/helmet';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import QuestionHeader from '../../components/customer/questionsHeader';
import { PASSWORD_RULE, ROLE_NAME } from '../../helpers/constant';
import Password from '../common/password';
import VerifiedOTPModal from '../common/modal/verifiedOTPModal';
import { SignupCaution } from '../common/signupCaution';
import CustomSelect from '../common/customSelect';
import { IframPdfModal } from '../common/modal/iframPdfModal';
import client_privacy_policy from '../../assets/documents/client_privacy_policy.pdf';
import accountizer_client_terms_of_service from '../../assets/documents/accountizer_client_terms_of_service.pdf';
import { role } from '../../helpers/functions';

const Signup = props => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const param_email = query.get('email');
  const param_first_name = query.get('first_name');
  const param_last_name = query.get('last_name');

  const isParamExists = () => {
    return (param_email && param_first_name) ? true : false
  }

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { isLocationLoading, locationList } = useSelector(state => state.common);
  const { isSignupLoading, signupStatus, signupData, isLoginLoading, loginMsg } = useSelector(state => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [signupEmail, setSignupEmail] = useState('');
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  // state for modal open
  const [showClientModal, setShowClientModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  useEffect(() => {
    if (locationList.length === 0) {
      dispatch({ type: 'GET_LOCATION_REQUEST', formData: { status: 'active' } });
    }
  }, []);

  const onFinish = values => {
    let tmpObj = values;
    tmpObj.location_ids = [values.location_ids];
    tmpObj.role = 'customer';
    if (isParamExists()) {
      dispatch({ type: 'SIGNUP_REQUEST_FROM_GOOGLE', formData: tmpObj, signupType: 'customer' });

    } else {

      dispatch({ type: 'SIGNUP_REQUEST', formData: tmpObj, signupType: 'customer' });
    }
  };

  useEffect(() => {
    if (!isSignupLoading && signupStatus === 'error' && Object.keys(signupData).length > 0) {
      if (typeof signupData === 'string') {
        message.error({ content: 'Something went wrong please try again.', className: 'error-class', duration: 4 });
      } else {
        for (const property in signupData) {
          message.error({
            content: signupData[property][0] || 'Something went wrong',
            className: 'error-class',
            duration: 4,
          });
        }
      }
      dispatch({ type: 'CLEAR_LOGIN_DATA' });
    } else if (
      !isSignupLoading &&
      signupStatus === 'success' &&
      Object.keys(signupData).length > 0
    ) {
      setSignupEmail(signupData.email);
      setShowModal(true);
      dispatch({ type: 'CLEAR_LOGIN_DATA' });
    }
  }, [isSignupLoading, signupStatus, signupData]);

  useEffect(() => {
    if (!isLoginLoading && loginMsg === 'success') {
      dispatch({ type: 'CLEAR_LOGIN_DATA' });
      if (localStorage.getItem('redirectLink')) {
        let tmoURL = localStorage.getItem('redirectLink');
        localStorage.removeItem('redirectLink');
        window.location.href = tmoURL;
      } else {
        if (role() === ROLE_NAME.ADMIN) {
          window.location.href = '/admin/requests';
        } else if (role() === ROLE_NAME.EXPERT) {
          window.location.href = '/dashboard/expert/request';
        } else {
          window.location.href = '/dashboard/customer';
        }
      }
    } else if (!isLoginLoading && loginMsg === 'not-verified') {
      message.error('Please verified your email address');
    }
  }, [isLoginLoading, loginMsg]);

  const optionsList = tmpLocation => {
    let locationOptions = [];
    if (tmpLocation.length > 0) {
      tmpLocation.map(item => {
        locationOptions.push({
          value: item.id,
          label: item.name,
        });
      });
    }
    return locationOptions;
  };

  return (
    <div className="question-create">
      <Helmet title="Client Sign Up" />
      <QuestionHeader type="Client Signup" />
      <Progress
        strokeColor={{
          '0%': '#1C64F2',
          '100%': '#1C64F2',
        }}
        trailColor={{
          '0%': '#1C64F2',
          '100%': '#1C64F2',
        }}
        percent={100}
        strokeLinecap="butt"
        showInfo={false}
      />
      <div className="profile d-flex-a-c mt30 mb30">
        <div>
          <div className="site-card-border-less-wrapper card-wrapper">
            <Card bordered={false}>
              {/* <Spin /> */}

              <>
                <p className="m-title tl mb-7">Sign Up </p>
                <Form
                  layout="vertical"
                  name="basic"
                  form={form}
                  onFinish={onFinish}
                  autoComplete="off"
                  initialValues={{
                    first_name: param_first_name || '',
                    last_name: param_last_name || '',
                    email: param_email || '',
                  }}
                >
                  <Row gutter={30}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item
                        label="First Name"
                        name="first_name"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter first name',
                          },
                          {
                            max: 100,
                            message: 'Maximum 100 characters are allowed',
                          },
                        ]}
                      >
                        <Input size="large" placeholder="First Name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item
                        label="Last Name"
                        name="last_name"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter last name',
                          },
                          {
                            max: 100,
                            message: 'Maximum 100 characters are allowed',
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Last Name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        label="Business Name"
                        name="organization"
                        rules={[
                          {
                            max: 100,
                            message: 'Maximum 100 characters are allowed',
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Business Name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        label="Email address"
                        name="email"

                        rules={[
                          {
                            required: true,
                            message: 'Please enter email address',
                          },
                          {
                            type: 'email',
                            message: 'Please enter valid email address',
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Email" disabled={isParamExists()} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        label="Location"
                        name="location_ids"
                        rules={[
                          {
                            required: true,
                            message: 'Please select location',
                          },
                        ]}
                      >
                        <CustomSelect
                          placeholder="Select a option"
                          style={{
                            width: '100%',
                          }}
                          size="large"
                          showSearch
                          allowClear
                          loading={isLocationLoading}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={optionsList(locationList)}
                        />
                      </Form.Item>
                    </Col>
                    {
                      !isParamExists() &&
                      <>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Password />
                          {/* <p className="mt3 mb0 help-text">{PASSWORD_RULE}</p> */}
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Form.Item
                            label="Confirm Password"
                            name="c_password"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: 'Please confirm your password',
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error('The two passwords that you entered do not match!'),
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input.Password size="large" placeholder="••••••••••" />
                          </Form.Item>
                        </Col>
                      </>
                    }
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item>
                        <div className="d-flex mt10" style={{ gap: '10px', alignItems: 'start' }}>
                          <Checkbox onChange={e => setIsTermsChecked(e.target.checked)} />
                          <p style={{ display: 'flex' }}>
                            I have read and agree to &nbsp;
                            <span
                              style={{
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                fontWeight: 700,
                              }}
                              onClick={() => setShowClientModal(!showClientModal)}
                            >
                              Accountizer's Client Terms of Service
                            </span>
                            &nbsp;and&nbsp;
                            <span
                              style={{
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                fontWeight: 700,
                              }}
                              onClick={() => setShowPrivacyModal(!showPrivacyModal)}
                            >
                              Privacy Policy.
                            </span>
                          </p>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <SignupCaution /> */}
                  <Divider className="mt40 mb40" />
                  <Form.Item>
                    <div style={{ textAlign: 'center', width: '100%' }}>
                      <Button
                        type="secondary"
                        htmlType="button"
                        size="large"
                        className="mt5"
                        onClick={() => navigate('/')}
                      >
                        Cancel
                      </Button>
                      <Button
                        htmlType="submit"
                        size="large"
                        className="mt5"
                        loading={isSignupLoading}
                        type={isTermsChecked ? 'primary' : 'default'}
                        disabled={isSignupLoading || !isTermsChecked}
                      >
                        Sign Up
                      </Button>
                    </div>
                  </Form.Item>
                </Form>

                {/* <Form
                                layout="vertical"
                                name="basic"
                                hideRequiredMark={true}
                                autoComplete="off"
                            >
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="float-r"
                                        size="middle"
                                    >
                                        Next
                                    </Button>
                                </Form.Item>
                            </Form> */}
              </>
            </Card>
          </div>
        </div>
      </div>
      {showModal && (
        <VerifiedOTPModal
          registerEmail={signupEmail}
          visible={showModal}
          setVisible={() => {
            setShowModal(false);
          }}
        />
      )}

      {/* Modals for Privacy and Terms */}
      <IframPdfModal
        visible={showPrivacyModal}
        setShow={setShowPrivacyModal}
        title="Accountizer's Privacy Policy"
        file={client_privacy_policy}
      />
      <IframPdfModal
        visible={showClientModal}
        setShow={setShowClientModal}
        title="Accountizer's Client Terms of Service"
        file={accountizer_client_terms_of_service}
      />
    </div>
  );
};

export default Signup;
