import React, { useState } from 'react';
import { Upload, Button } from 'antd';
// import noImage from "../../../src/assets/noimage.png";
import { useDispatch, useSelector } from 'react-redux';

const FileUpload = ({ uploadImageURL, setfileName = {}, type }) => {
  const dispatch = useDispatch();
  const { isDocUploadLoading } = useSelector(state => state.common);

  const handleFileChange = info => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      setfileName(info.file.name);
      const formData = new FormData();
      formData.append('file', info.file.originFileObj);
      dispatch({ type: 'UPLOAD_DOCUMENT_REQUEST', formData: formData });
    }
  };

  return (
    <>
      <div className="project-building-image-parent">
        <Upload
          name="avatar"
          className="avatar-uploader"
          onChange={handleFileChange}
          showUploadList={true}
          maxCount={1}
          accept={type ? type : undefined}
          listType="picture"
          onRemove={() => setfileName('')}
          customRequest={({ file, onSuccess }) => setTimeout(() => onSuccess('ok'), 0)}
        >
          <Button loading={isDocUploadLoading} disabled={isDocUploadLoading}>
            Choose File
          </Button>
        </Upload>
      </div>
    </>
  );
};

export default FileUpload;
