import { put, takeLatest, call } from 'redux-saga/effects';
import { message } from 'antd';
//Import API Service.
import {
  getRequestAPI,
  postRequestAPI,
  deleteRequestAPI,
  putRequestAPI,
} from '../../helpers/service';

function* getRequests({ formData = {} }) {
  try {
    const response = yield call(postRequestAPI, { url: `all-questions`, data: formData });
    if (response.status === 200) {
      yield put({ type: 'ADMIN_REQUESTS_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'ADMIN_REQUESTS_ERROR', data: '' });
    }
  } catch (error) {
    message.error({ content: 'Email address or Password is invalid', className: 'error-class' });
    yield put({ type: 'LOGIN_ERROR', error: error?.message || 'Something went wrong' });
  }
}

function* getReviews({ param }) {
  try {
    const response = yield call(getRequestAPI, { url: `reviews/${param}`, params: {} });
    if (response.status === 200) {
      yield put({ type: 'ADMIN_REVIEWS_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'ADMIN_REVIEWS_ERROR', data: '' });
    }
  } catch (error) {
    message.error({ content: 'Email address or Password is invalid', className: 'error-class' });
    yield put({ type: 'LOGIN_ERROR', error: error?.message || 'Something went wrong' });
  }
}

function* deleteRequest({ deleteId, deleteModule }) {
  try {
    let URL = '';
    if (deleteModule !== '') {
      if (deleteModule === 'request') {
        URL = `question/delete/${deleteId}`;
      }
    }
    const response = yield call(deleteRequestAPI, { url: URL });
    if (response.status === 200 || response.status === 204) {
      yield put({ type: 'ADMIN_DELETE_SUCCESS' });
    } else {
      message.error({
        content: response.message || 'Something went wrong!',
        className: 'error-class',
      });
      yield put({ type: 'ADMIN_DELETE_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'ADMIN_DELETE_ERROR', error: error.message || '' });
  }
}

function* deleteReview({ id }) {
  try {
    let URL = '';
    if (id !== '') {
      URL = `reviews/delete/${id}`;
    }
    const response = yield call(deleteRequestAPI, { url: URL });
    if (response.status === 200 || response.status === 204) {
      yield put({ type: 'ADMIN_DELETE_REVIEW_SUCCESS' });
    } else {
      message.error({
        content: response.message || 'Something went wrong!',
        className: 'error-class',
      });
      yield put({ type: 'ADMIN_DELETE_REVIEW_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'ADMIN_DELETE_REVIEW_ERROR', error: error.message || '' });
  }
}

function* deleteUser({ id }) {
  try {
    let URL = '';
    if (id !== '') {
      URL = `user/${id}`;
    }
    const response = yield call(deleteRequestAPI, { url: URL });
    if (response.status === 200 || response.status === 204) {
      yield put({ type: 'ADMIN_DELETE_USER_SUCCESS' });
    } else {
      message.error({
        content: response.message || 'Something went wrong!',
        className: 'error-class',
      });
      yield put({ type: 'ADMIN_DELETE_USER_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'ADMIN_DELETE_USER_ERROR', error: error.message || '' });
  }
}

function* adminCommmonAddUpdate({ reqType, module, formData }) {
  try {
    let URL = '';
    let response = '';
    if (reqType === 'post') {
      if (module === 'credit') {
        URL = `credits/add`;
      }
      response = yield call(postRequestAPI, { url: URL, data: formData });
    } else if (reqType === 'put') {
      response = yield call(putRequestAPI, { url: URL, data: formData });
    }

    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'ADMIN_ADD_UPDATE_SUCCESS', data: response.data || {} });
    } else {
      yield put({ type: 'ADMIN_ADD_UPDATE_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'ADMIN_ADD_UPDATE_ERROR', error: error.message || '' });
  }
}

function* deleteCredits({ id }) {
  try {
    let URL = '';
    if (id !== '') {
      URL = `credit/delete/${id}`;
    }
    const response = yield call(deleteRequestAPI, { url: URL });
    if (response.status === 200 || response.status === 204) {
      yield put({ type: 'ADMIN_DELETE_CREDITS_SUCCESS' });
    } else {
      message.error({
        content: response.message || 'Something went wrong!',
        className: 'error-class',
      });
      yield put({ type: 'ADMIN_DELETE_CREDITS_ERROR', error: 'No credits found' });
    }
  } catch (error) {
    yield put({ type: 'ADMIN_DELETE_CREDITS_ERROR', error: error.message || '' });
  }
}

function* deleteLicenseAndCertificatesDetails({ id }) {
  try {
    let URL = '';
    if (id !== '') {
      URL = `licenses-and-certificates/delete/${id}`;
    }
    const response = yield call(deleteRequestAPI, { url: URL });
    if (response.status === 200 || response.status === 204) {
      yield put({ type: 'ADMIN_DELETE_LICENSE_AND_CERTIFICATE_SUCCESS' });
    } else {
      message.error({
        content: response.message || 'Something went wrong!',
        className: 'error-class',
      });
      yield put({
        type: 'ADMIN_DELETE_LICENSE_AND_CERTIFICATE_ERROR',
        error: 'No license/certificate found',
      });
    }
  } catch (error) {
    yield put({ type: 'ADMIN_DELETE_LICENSE_AND_CERTIFICATE_ERROR', error: error.message || '' });
  }
}

function* deleteDegreeDetails({ id }) {
  try {
    let URL = '';
    if (id !== '') {
      URL = `university-degrees/delete/${id}`;
    }
    const response = yield call(deleteRequestAPI, { url: URL });
    if (response.status === 200 || response.status === 204) {
      yield put({ type: 'ADMIN_DELETE_DEGREE_SUCCESS' });
    } else {
      message.error({
        content: response.message || 'Something went wrong!',
        className: 'error-class',
      });
      yield put({ type: 'ADMIN_DELETE_DEGREE_ERROR', error: 'No degree found' });
    }
  } catch (error) {
    yield put({ type: 'ADMIN_DELETE_DEGREE_ERROR', error: error.message || '' });
  }
}

function* deleteExperienceDetails({ id }) {
  try {
    let URL = '';
    if (id !== '') {
      URL = `experience-in-industry/delete/${id}`;
    }
    const response = yield call(deleteRequestAPI, { url: URL });
    if (response.status === 200 || response.status === 204) {
      yield put({ type: 'ADMIN_DELETE_EXPERIENCE_SUCCESS' });
    } else {
      message.error({
        content: response.message || 'Something went wrong!',
        className: 'error-class',
      });
      yield put({ type: 'ADMIN_DELETE_EXPERIENCE_ERROR', error: 'No credits found' });
    }
  } catch (error) {
    yield put({ type: 'ADMIN_DELETE_EXPERIENCE_ERROR', error: error.message || '' });
  }
}
function* deleteServiceAndRateDetail({ id }) {
  try {
    let URL = '';
    if (id !== '') {
      URL = `services-and-rate/delete/${id}`;
    }
    const response = yield call(deleteRequestAPI, { url: URL });
    if (response.status === 200 || response.status === 204) {
      yield put({ type: 'ADMIN_DELETE_SERVICES_AND_RATE_SUCCESS' });
    } else {
      message.error({
        content: response.message || 'Something went wrong!',
        className: 'error-class',
      });
      yield put({ type: 'ADMIN_DELETE_SERVICES_AND_RATE_ERROR', error: 'No credits found' });
    }
  } catch (error) {
    yield put({ type: 'ADMIN_DELETE_SERVICES_AND_RATE_ERROR', error: error.message || '' });
  }
}

function* watchAdminRequests() {
  yield takeLatest('ADMIN_REQUESTS_REQUEST', getRequests);
  yield takeLatest('ADMIN_REVIEWS_REQUEST', getReviews);
  yield takeLatest('ADMIN_DELETE_REQUEST', deleteRequest);
  yield takeLatest('ADMIN_DELETE_REVIEW', deleteReview);
  yield takeLatest('ADMIN_DELETE_USER', deleteUser);
  yield takeLatest('ADMIN_ADD_UPDATE_REQUEST', adminCommmonAddUpdate);
  yield takeLatest('ADMIN_DELETE_CREDITS', deleteCredits);
  yield takeLatest('ADMIN_DELETE_LICENSE_AND_CERTIFICATE', deleteLicenseAndCertificatesDetails);
  yield takeLatest('ADMIN_DELETE_EXPERIENCE', deleteExperienceDetails);
  yield takeLatest('ADMIN_DELETE_SERVICES_AND_RATE', deleteServiceAndRateDetail);
  yield takeLatest('ADMIN_DELETE_DEGREE', deleteDegreeDetails);
}

function* Admin() {
  yield watchAdminRequests();
}

export default Admin;
