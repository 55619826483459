import { message } from 'antd';
export const beforeUpload = file => {
  const ALLOWED_TYPES = 'jpeg,jpg,bmp,png,gif,svg';
  let isAllowed = false;
  switch (file.type) {
    case 'image/jpeg':
    case 'image/bmp':
    case 'image/png':
    case 'image/gif':
    case 'image/svg':
      isAllowed = true;
  }
  //   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isAllowed) {
    message.error(`You can only upload ${ALLOWED_TYPES} file!`);
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must be smaller than 2MB!');
  }
  return isAllowed && isLt2M;
};
