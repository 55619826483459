import React from 'react';
import Helmet from '../../common/helmet';

const AdminDisputeManagement = props => {
  return (
    <>
      <Helmet title="Expert Management" />
      <p>Disputes management</p>
    </>
  );
};

export default AdminDisputeManagement;
