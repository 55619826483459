import React, { useState } from 'react';
import { Modal, Button, Checkbox } from 'antd';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';

const WhatsNextModal = props => {
  const navigate = useNavigate();
  const { visible, setVisible, navigateTo = '', expertName, userId } = props;
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const dispatch = useDispatch();
  const { isDontShowWhatsNext } = useSelector(state => state.common);

  const onCancel = () => {
    setVisible(false);
    if (dontShowAgain) {
      dispatch({ type: 'DONT_SHOW_WHATS_NEXT_MODAL', userId: userId });
      Cookies.set(`dontShowWhatsNext_${userId}`, 'true', { expires: 365 });
    }
    if (navigateTo !== '') {
      navigate(navigateTo);
    }
  };
  return (
    <Modal
      className="custom-modal"
      open={visible}
      footer={false}
      centered
      width={620}
      maskClosable={false}
      closeIcon={false}
      closable={false}
    >
      <div className="mt5 mb5 pr5 pl5">
        <center>
          <p className="title">What’s Next</p>
          <div className="content">
            <ul style={{ textAlign: 'start' }}>
              <li>
                <p style={{ fontWeight: 'bold' }}>
                  <span style={{ color: '#4472c4' }}>Accept</span> our recommendation and{' '}
                  {expertName} will get in touch with you shortly.
                </p>
              </li>
              <li>
                <p style={{ fontWeight: 'bold' }}>
                  <span style={{ color: '#4472c4' }}>Message</span> {expertName} to delve deeper
                  into your case.
                </p>
              </li>
              <li>
                <p style={{ fontWeight: 'bold' }}>
                  <span style={{ color: '#4472c4' }}>Meet</span> with {expertName} for a more
                  comprehensive discussion.
                </p>
              </li>
              <li>
                <p style={{ fontWeight: 'bold' }}>
                  Explore <span style={{ color: '#4472c4' }}></span> to discover
                  profiles of other professionals, ranked based on your criteria. Review their
                  evaluation reports and select the expert that best suits your needs.
                </p>
              </li>
            </ul>
          </div>
          <div>
            <Checkbox
              style={{ color: '#4472c4' }}
              onChange={e => setDontShowAgain(e.target.checked)}
            >
              Don’t show this message again
            </Checkbox>
          </div>
          <div>
            {' '}
            <Button
              style={{ marginTop: '20px', borderRadius: '5px' }}
              size="large"
              type="primary"
              onClick={() => onCancel()}
            >
              Got it
            </Button>
          </div>
        </center>
      </div>
    </Modal>
  );
};

export default WhatsNextModal;
