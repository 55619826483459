import { ExportOutlined } from '@ant-design/icons';
import { FloatButton, Modal } from 'antd';

export const IframPdfModal = props => {
  const { visible, setShow, title, file, isShowToolbar = 1, html } = props;

  const data = (
    <iframe
      title="pdf-viewer-frame"
      src={`${file}#toolbar=${isShowToolbar}`}
      style={{ width: '100%', height: '75dvh' }}
      type={!html && "application/pdf"}
    ></iframe>
  );

  const openNewTab = () => {
    window.open(`${file}`);
  };

  return (
    <Modal
      className={`custom-modal`}
      open={visible}
      footer={false}
      title={title}
      centered
      width={850}
      onCancel={() => setShow(!visible)}
      style={{
        maxHeight: 'auto',
      }}
    >
      <div
        className="custom-modal-terms"
        style={{ maxHeight: 'auto', overflowY: 'scroll', padding: '10px' }}
      >
        <FloatButton onClick={openNewTab} tooltip="Open in a new tab" icon={<ExportOutlined />} />
        {/* {data} */}
        {html ? (
          <iframe
            title="html-viewer-frame"
            src={file}
            style={{ width: '100%', height: '75vh', border: 'none' }}
          ></iframe>
        ): (data)}
      
      </div>
    </Modal>
  );
};
