import { all } from 'redux-saga/effects';

import AuthSaga from './auth';
import CommonSaga from './common';
import StepsSaga from './steps';
import ExpertSaga from './expert';
import AdminSaga from './admin';
import { MessagingSaga } from './messaging';

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
function* rootSaga() {
  yield all([AuthSaga(), CommonSaga(), StepsSaga(), ExpertSaga(), AdminSaga(), MessagingSaga()]);
}

export default rootSaga;
