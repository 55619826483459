import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Row, Col, Switch, Select, Divider, Calendar, message } from 'antd';
import { ClockCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { breakName, createTimeSlots, dateFormat, getFullName, getUserData } from '../../../helpers/functions';
import { MESSAGE_TYPE } from '../../../helpers/constant';
import { timezone } from '../../../helpers/constant';
const PickMeetingDateAndTime = ({ newdata, text }) => {

  const { threadBySubjectId, timezoneRecord } = useSelector(state => state.requests);
  const [data, setNewData] = useState(timezoneRecord);

  let disabledDays = [];
  let enabledDays = [];
  const [timeArray, setTimeArray] = useState([]);
  const [showTimingList, setShowTimingList] = useState(false);
  const [openDurationModal, setOpenDurationModal] = useState(false);
  const [currentTimeZone, setCurrentTimeZone] = useState(null);
  const userData = getUserData();
  const [meetingTime, setMeetingTime] = useState(null);
  const [collectedFinalData, setCollectedFinalData] = useState({
    duration: data?.duration,
    date: null,
    time: null,
    timezone: userData?.locations?.[0]?.timezone ?? timezone[0]?.value,
  });
  const [selectedDate, setSelectedDate] = useState(null);
  const expertData = useSelector(state => state?.common?.questionDetail);
  const dispatch = useDispatch();
  function getCurrentTimeOfTimezone(timezone) {
    const date = new Date();
    const options = {
      timeZone: timezone,
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      hourCycle: 'h12',
    };

    let timeFormatter = new Intl.DateTimeFormat('en-US', options);
    setCurrentTimeZone(timeFormatter.format(date));
    if (selectedDate?.$d) {
      getAvailableTimes(selectedDate?.$d);
    }
  }
  function sendMessage() {
    if (!collectedFinalData?.date || !collectedFinalData?.time || !collectedFinalData?.timezone) {
      message.error('Please select date, time and timezone');
      return;
    }
    dispatch({
      type: 'POST_MESSAGE',
      formData: {
        message: JSON.stringify({
          duration: data?.duration,
          timezone: collectedFinalData?.timezone,
          meetingTiming: `${dateFormat(collectedFinalData?.date)} ${collectedFinalData?.time}`,
          topic: expertData?.category?.name || 'Zoom Meeting',
          agenda: `${newdata?.party_a_creator_name} and ${newdata?.party_b_name}`,
          party_b: newdata?.party_b,
          party_a_creator: newdata?.party_a_creator,
        }),
        id: threadBySubjectId?.id,
        type: MESSAGE_TYPE.SCHEDULE_MEETING,
        has_attachment: 0,
      },
    });
  }

  if (data?.currentDate) {
    let availableTimes = data.availabilityTimings;
    let startDate = new Date(data.currentDate);
    let x = 0;
    for (let key in availableTimes) {
      if (availableTimes && availableTimes[key].length > 0) {
        enabledDays.push({
          date: new Date(key),
          availableTimes: availableTimes[key],
        });
      } else {
        disabledDays.push({
          date: new Date(key),
        });
      }
      x = x + 1;
    }
  }
  function getAvailableTimes(date) {
    for (let i = 0; i < enabledDays.length; i++) {
      if (date.toString().substring(0, 14) === enabledDays[i].date.toString().substring(0, 14)) {
        makeSlots(enabledDays[i].availableTimes, enabledDays[i].date);
      }
    }
  }
  const isGreater = (time1, time2) =>
    (time => {
      let [h, m] = time?.split(/[:\s]/);
      h = (h % 12) + (time.includes('PM') ? 12 : 0);
      return h * 60 + +m;
    })(time1) >
      (time => {
        let [h, m] = time?.split(/[:\s]/);
        h = (h % 12) + (time.includes('PM') ? 12 : 0);
        return h * 60 + +m;
      })(time2)
      ? time1
      : time2;

  function makeSlots(availableTimes, date) {
    let slots = createTimeSlots(availableTimes, data.duration, date);
    // for (let i = 0; i < availableTimes.length; i++) {
    //   let from = isGreater(availableTimes[i].from.label, currentTimeZone);
    //   let to = isGreater(availableTimes[i].to.label, currentTimeZone);
    //   // slots = [...generateTimeSlots(from, availableTimes[i].to.label, data.duration, date)];
    //   console.log(generateTimeSlots(from, to, data.duration, date));

    // }
    setTimeArray(slots);
  }

  function generateTimeSlots(startTime, endTime, duration, date) {
    const timeSlots = [];
    const to24HourFormat = time => {
      const [timePart, modifier] = time?.split(' ');
      let [hours, minutes] = timePart?.split(':');
      if (hours === '12') {
        hours = '00';
      }
      if (modifier === 'PM' && hours !== '12') {
        hours = parseInt(hours, 10) + 12;
      }
      hours = hours.length === 1 ? '0' + hours : hours;
      return `${hours}:${minutes}`;
    };

    const start = new Date(`1970-01-01T${to24HourFormat(startTime)}:00`);
    const end = new Date(`1970-01-01T${to24HourFormat(endTime)}:00`);

    let current = start;

    while (current < end) {
      const next = new Date(current.getTime() + duration * 60000);
      if (next > end) break;
      const value = current.toTimeString().slice(0, 5);
      const label = `${current.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })} - ${next.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}`;
      timeSlots.push({ value, label, date });
      current = next;
    }


    return timeSlots;
  }
  const today = new Date();
  const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const sevenDaysLater = new Date(today);
  sevenDaysLater.setDate(today.getDate() < 25 ? today.getDate() + 6 : today.getDate() + 7);

  const disabledDate = current => {
    return (
      disabledDays.some(day => {
        return day.date.toString().substring(0, 14) === current.$d.toString().substring(0, 14);
      }) ||
      current < todayStart ||
      current > sevenDaysLater
    );
  };
  const handlePickDateAndTime = () => {
    getCurrentTimeOfTimezone(userData?.locations?.[0]?.timezone ?? timezone[0]?.value);
    dispatch({
      type: 'GET_DATE_TIME_FOR_TIMEZONE',
      formData: {
        data: newdata,
        toTimezone: userData?.locations?.[0]?.timezone ?? timezone[0]?.value,
      },
    });
    setOpenDurationModal(true);

  };

  const getNewAvailableTimes = res => {
    setCollectedFinalData({ ...collectedFinalData, timezone: res.value });
    dispatch({
      type: 'GET_DATE_TIME_FOR_TIMEZONE',
      formData: {
        data: newdata,
        toTimezone: res.value,
      },
    });
  };

  useEffect(() => {
    openDurationModal && setNewData(timezoneRecord);
  }, [timezoneRecord]);


  return (
    <>
      <span
        onClick={() => handlePickDateAndTime()}
        style={{ color: 'rgba(28,100,242)', fontWeight: 'bold', cursor: 'pointer' }}
      >
        {text || "Pick a date and time"}

      </span>
      {openDurationModal ? (
        <Modal
          centered
          open={openDurationModal}
          onCancel={() => {
            setShowTimingList(false);
            setOpenDurationModal(false);
          }}
          footer={[
            <Button
              key="submit"
              type="primary"
              disabled={newdata?.party_a_creator == userData?.id}
              onClick={() => {
                sendMessage();
                setShowTimingList(false);
                setOpenDurationModal(false);
              }}
            >
              Confirm
            </Button>,
          ]}
          width={800}
        >
          <h1>Schedule a meeting with {breakName(newdata?.party_a_creator_name)}</h1>
          {newdata?.party_a_creator == userData?.id && (
            <div
              style={{
                display: 'flex',
                height: '2rem',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'red',
                backgroundColor: 'rgba(255, 0,0, 0.1)',
                // padding: '2px',
              }}
            >
              <p style={{ margin: 'auto' }}>You cannot schedule a meeting with yourself</p>
            </div>
          )}

          <Row
            className="schedule-meeting-row schedule-meeting-time-row"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            <Col className="duration-col" span={12}>
              <p>Duration</p>
              <h2>{data?.duration} minutes</h2>
            </Col>
            <Col span={12}>
              <div className="shedule-time-timezone-section">
                <h3>Timezone</h3>
                {timezone.length ? (
                  <Select
                    className="timezone-select-bar"
                    showSearch
                    labelInValue
                    defaultValue={userData?.locations?.[0]?.timezone ?? timezone[0]?.value}
                    options={timezone}
                    onSelect={(data, options) => {
                      getNewAvailableTimes(data);
                      getCurrentTimeOfTimezone(data.value);
                      setTimeArray([]);
                    }}
                  />
                ) : (
                  <LoadingOutlined className="ant-loader-icon" />
                )}
              </div>
            </Col>
          </Row>
          <Row
            className="schedule-meeting-row schedule-meeting-calendar-row"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            <Col className="calendar-section-col" span={12}>
              <div className="calendar-section">
                <h3>Select a Date</h3>
                <Calendar
                  defaultValue={''}
                  disabledDate={disabledDate}
                  fullscreen={false}
                  onSelect={(date, { source }) => {
                    setSelectedDate(date);
                    setTimeArray([]);
                    if (source === 'date') {
                      getAvailableTimes(date.$d);
                      setShowTimingList(true);
                    }
                  }}
                />
              </div>
            </Col>
            <Col span={12}>
              {!showTimingList ? (
                <div className="time-section">
                  <ClockCircleTwoTone className="clock-icon" />
                  <p>Select a date to view available times</p>
                </div>
              ) : (
                <div className="select-time">
                  <h3>Select time</h3>
                  <div className="selected-times">
                    <ul>
                      {timeArray.map((time, index) => {
                        return (
                          <li
                            key={index + '_selectable_time_slots'}
                            onClick={e => {
                              setCollectedFinalData({
                                ...collectedFinalData,
                                time: time.value,
                                date: time.date,
                              });
                              setMeetingTime({ time: time.value });
                            }}
                            className={
                              meetingTime?.time === time.value
                                ? 'selectable_time_slots selected-time-active'
                                : 'selectable_time_slots'
                            }
                          >
                            {time.label}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Modal>
      ) : (
        ''
      )}
    </>
  );
};

export default PickMeetingDateAndTime;
