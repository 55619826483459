import React from 'react';

export const ProfileIconForNavigation = props => {
  const { isActive } = props;

  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5251 6.49961C13.5251 8.19699 12.8508 9.82486 11.6506 11.0251C10.4503 12.2253 8.82248 12.8996 7.1251 12.8996C5.42771 12.8996 3.79985 12.2253 2.59961 11.0251C1.39938 9.82486 0.725098 8.19699 0.725098 6.49961C0.725098 4.80222 1.39938 3.17436 2.59961 1.97413C3.79985 0.773893 5.42771 0.0996094 7.1251 0.0996094C8.82248 0.0996094 10.4503 0.773893 11.6506 1.97413C12.8508 3.17436 13.5251 4.80222 13.5251 6.49961ZM8.7251 4.09961C8.7251 4.52396 8.55653 4.93092 8.25647 5.23098C7.95641 5.53104 7.54944 5.69961 7.1251 5.69961C6.70075 5.69961 6.29379 5.53104 5.99373 5.23098C5.69367 4.93092 5.5251 4.52396 5.5251 4.09961C5.5251 3.67526 5.69367 3.2683 5.99373 2.96824C6.29379 2.66818 6.70075 2.49961 7.1251 2.49961C7.54944 2.49961 7.95641 2.66818 8.25647 2.96824C8.55653 3.2683 8.7251 3.67526 8.7251 4.09961ZM7.1251 7.29961C6.35918 7.29945 5.60932 7.5192 4.96463 7.93273C4.31994 8.34626 3.80753 8.93619 3.4883 9.63241C3.93844 10.1561 4.49651 10.5762 5.12425 10.864C5.75199 11.1518 6.43454 11.3004 7.1251 11.2996C7.81565 11.3004 8.4982 11.1518 9.12594 10.864C9.75368 10.5762 10.3118 10.1561 10.7619 9.63241C10.4427 8.93619 9.93025 8.34626 9.28556 7.93273C8.64088 7.5192 7.89102 7.29945 7.1251 7.29961Z"
        fill={isActive ? '#ffffff' : '#6B7280'}
      />
    </svg>
  );
};
