/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Modal, Form, Input, Spin, Button, message, Tooltip } from 'antd';
import ReactLinkify from 'react-linkify';
import { useDispatch, useSelector } from 'react-redux';
import { breakName, convertDateToTimezone, convertTimeToTimezone, formatDateTo, getSubCategoryName } from '../../../helpers/functions';
import { nameRecommendationConstantForToolTip } from '../../../helpers/constant';

const { TextArea } = Input;

const QuestionReplyModal = props => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { open, close, questionId, rawData, setShowRequestRejectModal } = props;
  const { isQuestionLoading } = useSelector(state => state.common);

  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  const onRejectNewQuestion = () => {
    close(true);
    setShowRequestRejectModal(true);
  };

  const onFinish = () => {
    form
      .validateFields()
      .then(values => {
        dispatch({
          type: 'COMMON_PUT_POST_REQUEST',
          reqType: 'post',
          module: 'reply-question',
          formData: values,
        });
       
      })
      .catch(errorInfo => { });
  };

  const isStatusAssigned = id => {
    if (id >= 3) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      title={'Question details'}
      className="question-modal payment-modal"
      open={open}
      footer={[
        <Button
          disabled={rawData?.assign_by_admin === 1 && rawData?.expert_approved === 0}
          type={
            rawData?.assign_by_admin === 1 && rawData?.expert_approved === 0 ? 'default' : 'primary'
          }
          onClick={() => {
            if (rawData?.expert_approved === 1) {
              onFinish();
            } else {
              message.error('Waiting for client approval.');
            }
          }}
        >
          Accept and Answer
        </Button>,
        <Button
          disabled={rawData?.assign_by_admin === 1 && rawData?.expert_approved === 0}
          danger
          onClick={() => {
            if (rawData?.expert_approved === 1) {
              onRejectNewQuestion();
            } else {
              message.error('Waiting for client approval.');
            }
          }}
        >
          Reject
        </Button>,
      ]}
      onCancel={() => close(false)}
      okText={'Accept & Answer'}
      okButtonProps={{
        disabled: false,
        loading: false,
      }}
      centered
      cancelText={'Reject'}
      width={650}
    >
      {isQuestionLoading && <Spin />}
      {!isQuestionLoading && (
        <Row>
          <Col
            style={{
              height: (height * 70) / 100,
              overflowY: 'scroll',
              border: 'none',
              padding: '1px 10px',
            }}
            className="request-details-for-question-modal"
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <h6>Question</h6>
              <div className="general">
                <ReactLinkify>{rawData?.question_disc}</ReactLinkify>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt15">
              <Form
                layout="vertical"
                name="basic"
                form={form}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  label="My answer"
                  name="my_answer"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter answer',
                    },
                    {
                      max: 5000,
                      message: 'Maximum 5000 characters are allowed',
                    },
                  ]}
                >
                  <TextArea rows={4} placeholder="Answer question" />
                </Form.Item>
                <Form.Item
                  style={{ display: 'none' }}
                  name={'question_id'}
                  initialValue={questionId}
                >
                  <Input defaultValue={questionId} />
                </Form.Item>
              </Form>
            </Col>
            <div className="request-details-wrapper-for-question-detail">
              <div className="request-group">
                <div>
                  <h6 className="p-title">Request date</h6>
                  <h3 className="title-date title">{convertDateToTimezone(props?.created_at)}</h3>
                  <h3 className="title-date title">{convertTimeToTimezone(props?.created_at)}</h3>
                </div>
                <div>
                  <h6 className="p-title">Client Name</h6>

                  <p className="title-date">

                    <Tooltip title={nameRecommendationConstantForToolTip?.title || ""} color={nameRecommendationConstantForToolTip?.color}>
                      {breakName(rawData?.user?.first_name, rawData?.user?.last_name)}
                    </Tooltip>


                  </p>
                </div>
                <div>
                  <h6>I need to contact an expert for a</h6>
                  <p>{rawData?.question_type?.name ? rawData?.question_type?.name : '-'}</p>
                </div>
                <div>
                  <h6>I am (a/an) or representing (a/an)</h6>
                  <p>{rawData?.customer_type?.name ? rawData?.customer_type?.name : '-'}</p>
                </div>
                <div>
                  <h6>Service category</h6>
                  <p>{rawData?.category?.name ? rawData?.category?.name : '-'}</p>
                </div>
                {!isStatusAssigned(rawData?.status?.question_status_id) && (
                  <div>
                    <h6>Type of service</h6>
                    <p>{getSubCategoryName(rawData)}</p>
                  </div>
                )}
                <div>
                  <h6>
                    {rawData?.category?.name ? rawData?.category?.name + ' Subcategory' : '-'}
                  </h6>
                  <p>{getSubCategoryName(rawData)}</p>
                </div>
                {rawData?.sector?.name && (
                  <div>
                    <h6>Business Sector</h6>
                    <p>{rawData?.sector?.name ? rawData?.sector?.name : '-'}</p>
                  </div>
                )}
                {!isStatusAssigned(rawData?.status?.question_status_id) && (
                  <div>
                    <h6>Estimated level of seniority required</h6>
                    <p>{rawData?.seniority?.name ? rawData?.seniority?.name : '-'}</p>
                  </div>
                )}
                {rawData?.ownership && (
                  <div>
                    <h6>Ownership type</h6>
                    <p>{rawData?.ownership?.name ? rawData?.ownership?.name : '-'}</p>
                  </div>
                )}
                <div>
                  <h6>Location</h6>
                  <p>{rawData?.location?.name ? rawData?.location?.name : '-'}</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default QuestionReplyModal;
