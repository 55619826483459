import { Button, Col, Row, Progress, Tooltip, Popover } from 'antd';
import React from 'react';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  breakName,
  convertDateToTimezone,
  convertTimeToTimezone,
  formatDateTo,
  getSubCategoryName,
} from '../../helpers/functions';
import { defaultAvatarImg, nameRecommendationConstantForToolTip } from '../../helpers/constant';

const RequestCard = props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRequestCancel = () => {
    dispatch({
      type: 'UPDATE_QUESTION_STATUS',
      formData: { question_id: props?.id, question_status_id: 10 },
    });
    if (props?.navigateBackOnCancel) {
      navigate('/dashboard/customer');
    }
  };

  const handleCompleteRequest = () => {
    navigate(`/request/${props?.id}?type=customer-type`);
  };

  const isRequestPending = () => {
    if (props?.request_submitted === 0) {
      return true;
    } else if (props?.request_submitted === 1) {
      return false;
    }
  };
  // console.log(findExpertAvatar(),props,expertList,common)
  const popoverContent = (
    <div className="status-popover-content-container">
      <div onClick={handleRequestCancel} className="status-popover-cancel-button">
        Cancel Request
      </div>
    </div>
  );
  return (
    <div
      className={`requests-history-accounting ${props?.list ? 'custom-request-card' : ''}`}
      onClick={props?.onRequestClick}
    >
      <Row className="requests-accounting-row">
        <Col
          className="requests-accounting-col"
          onClick={props.isClickable ? props.onRequestClick : undefined}
          style={{ cursor: props.isClickable && 'pointer' }}
          xs={6}
          sm={6}
          md={6}
          lg={4}
          xl={4}
          xxl={4}
        >
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <img
                src={props.expert_meta?.avatar || defaultAvatarImg }
                alt="avatar"
                height={'80px'}
                width={'80px'}
                style={{ borderRadius: '50%' }}
                loading="lazy"
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <div style={{ padding: '10px', fontWeight: 'bold' }}>
                {props?.status?.question_status?.status_order >= 7 ? (
                  props?.expert?.first_name + ' ' + props?.expert?.last_name
                ) : (
                  <Tooltip
                    title={nameRecommendationConstantForToolTip?.title || ''}
                    color={nameRecommendationConstantForToolTip?.color}
                  >
                    {breakName(props?.expert?.first_name, props?.expert?.last_name)}
                  </Tooltip>
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={18} sm={18} md={18} lg={20} xl={20} xxl={20}>
          <Row>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
              <div className="id pr-4">
                <div className="p-title">ID #{props.id}</div>
                <h3 className="title">{props?.category?.name ? props?.category?.name : '-'}</h3>
                <p className="title-i">
                  <i>{getSubCategoryName(props)}</i>
                </p>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
              <div className="date">
                <div className="p-title">Request Type</div>
                <h3 className="title-date">
                  {props?.question_type?.name ? props?.question_type?.name : '-'}
                </h3>
              </div>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={
                (!props?.isClickable && isRequestPending()) ||
                (!props?.list && props?.status?.question_status_id < 10)
                  ? 4
                  : 6
              }
              xl={
                (!props?.isClickable && isRequestPending()) ||
                (!props?.list && props?.status?.question_status_id < 10)
                  ? 4
                  : 6
              }
              xxl={
                (!props?.isClickable && isRequestPending()) ||
                (!props?.list && props?.status?.question_status_id < 10)
                  ? 4
                  : 6
              }
            >
              <div className="date">
                <div className="p-title">Request date</div>
                <h3 className="title-date title">{convertDateToTimezone(props?.created_at)}</h3>
                <h3 className="title-date title">{convertTimeToTimezone(props?.created_at)}</h3>
              </div>
            </Col>
            <Col
              className="requests-accounting-col"
              xs={12}
              sm={12}
              md={6}
              lg={
                (!props?.isClickable && isRequestPending()) ||
                (!props?.list && props?.status?.question_status_id < 10)
                  ? 4
                  : 8
              }
              xl={
                (!props?.isClickable && isRequestPending()) ||
                (!props?.list && props?.status?.question_status_id < 10)
                  ? 4
                  : 8
              }
              xxl={
                (!props?.isClickable && isRequestPending()) ||
                (!props?.list && props?.status?.question_status_id < 10)
                  ? 4
                  : 8
              }
            >
              <div className="">
                <div>
                  <div className="p-title">Request status</div>
                  <h3
                    style={{
                      color:
                        props?.status?.question_status_id === 10 || isRequestPending()
                          ? '#F05252'
                          : undefined,
                    }}
                    className="title"
                  >
                    {isRequestPending()
                      ? 'Request Pending'
                      : props?.status?.question_status?.name
                        ? props?.status?.question_status?.name
                        : 'No Status'}
                  </h3>
                  <div className="progress-request-card flex-d-a-c">
                    {/* 3 done / 3 in progress / 4 to do */}
                    <Tooltip
                      title={`${props?.status?.question_status_id ? (props?.status?.question_status_id === 12 ? '20%' : `${props?.status?.question_status_id * 10}%`) : '0 done / 10 to do'}`}
                    >
                      <Progress
                        type="line"
                        percent={100}
                        showInfo={false}
                        success={{
                          percent: isRequestPending()
                            ? 0
                            : props?.status?.question_status_id
                              ? props?.status?.question_status_id === 12
                                ? 20
                                : props?.status?.question_status_id * 10
                              : 0,
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </Col>
            {!props?.list && props?.status?.question_status_id < 10 && (
              <Col className="requests-accounting-col" xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                <div className="f-flex-a-e expert-request-button-group">
                  {!props?.list && props?.status?.question_status_id < 10 && (
                    <Popover
                      placement="bottomLeft"
                      content={popoverContent}
                      trigger="click"
                      showArrow={false}
                    >
                      <div className="status-popover-option-icon">
                        <HiOutlineDotsVertical size={32} />
                      </div>
                    </Popover>
                  )}
                </div>
              </Col>
            )}
            {!props?.isClickable && isRequestPending() && (
              <Col className="requests-accounting-col" xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                <div className="f-flex-a-e expert-request-button-group">
                  {!props.isClickable && isRequestPending() && (
                    <Button
                      style={{ width: '160px' }}
                      className="expert-request-button"
                      onClick={handleCompleteRequest}
                      type="primary"
                    >
                      Complete request
                    </Button>
                  )}
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default RequestCard;
