const initialState = {
  isDegreeLoading: false,
  degreeList: [],
  isDegreeEmpty: undefined,

  isCertificateLoading: false,
  certificatList: [],
  isEmpty: undefined,

  isExperinceLoading: false,
  ExperinceList: [],
  isExperinceEmpty: undefined,

  isExpertInfoLoading: false,
  expertDetails: {},
  isSubCatLoading: false,
  allSubCategory: [],

  isExpTypeLoading: false,
  expTypeList: [],

  isRateTypeLoading: false,
  rateTypeList: [],

  isMainSubCatLoading: false,
  mainSubList: [],
  isMainSubEmpty: undefined,

  isExpertListLoading: false,
  expertsList: [],

  isRecommendedExpertListLoading: false,
  recommendedExpertsList: [],
  
  isRecommendationTextLoading: false,
  recommendationText: '',

  isBillLoading: false,
  billPlanList: [],

  isQuoatLoading: false,
  quotationDetails: {},

  isPayDetailLoading: false,
  paymentData: [],
  isAssignQuestionLoading: false,
  assignQuestionToExpert: [],

  isCreditLoading: false,
  creditList: [],

  isCreditByIdLoading: false,
  expertCreditById: [],

  isEarningByIdLoading: false,
  expertEarningById: [],

  isPayLoading: false,
  paymentMsg: '',
  paymentError: '',
  isTaxLoading: false,
  paymentTax: '',

  isPaymetDetailLoading: false,
  paymentDetails: [],

  isExpertNewApplicationLoading: false,
  expertNewApplication: [],

  isUpdateExpertStatusLoading: false,
  updateExpertStatus: [],

  isUpdateExpertFreezingStatusLoading: false,
  updateExpertFreezingStatus: [],

  isExpertPendingUpdatesLoading: false,
  expertPendingUpdates: [],
};

const AuthReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    /*------------ */
    case 'EXPERT_DEGREE_REQUEST':
      state = {
        ...state,
        isDegreeLoading: true,
        degreeList: [],
        isDegreeEmpty: true,
      };
      break;

    case 'EXPERT_DEGREE_SUCCESS':
      state = {
        ...state,
        isDegreeLoading: false,
        degreeList: action.data,
        isDegreeEmpty: false,
      };
      break;

    case 'EXPERT_DEGREE_ERROR':
      state = {
        ...state,
        isDegreeLoading: false,
        degreeList: [],
        isDegreeEmpty: false,
      };
      break;

    /*------------ */
    case 'EXPERT_CERTIFICATION_REQUEST':
      state = {
        ...state,
        isCertificateLoading: true,
        certificatList: [],
        isEmpty: true,
      };
      break;

    case 'EXPERT_CERTIFICATION_SUCCESS':
      state = {
        ...state,
        isCertificateLoading: false,
        certificatList: action.data,
        isEmpty: false,
      };
      break;

    case 'EXPERT_CERTIFICATION_ERROR':
      state = {
        ...state,
        isCertificateLoading: true,
        certificatList: [],
        isEmpty: false,
      };
      break;

    /*------------ */
    case 'EXPERT_EXPERINCE_REQUEST':
      state = {
        ...state,
        isExperinceLoading: true,
        ExperinceList: [],
        isExperinceEmpty: true,
      };
      break;

    case 'EXPERT_EXPERINCE_SUCCESS':
      state = {
        ...state,
        isExperinceLoading: false,
        ExperinceList: action.data,
        isExperinceEmpty: false,
      };
      break;

    case 'EXPERT_EXPERINCE_ERROR':
      state = {
        ...state,
        isExperinceLoading: false,
        ExperinceList: [],
        isExperinceEmpty: false,
      };
      break;

    /*------------ */
    case 'EXPERT_DETAIL_REQUEST':
      state = {
        ...state,
        isExpertInfoLoading: true,
        expertDetails: {},
      };
      break;

    case 'EXPERT_DETAIL_SUCCESS':
      state = {
        ...state,
        isExpertInfoLoading: false,
        expertDetails: action.data,
      };
      break;

    case 'EXPERT_DETAIL_ERROR':
      state = {
        ...state,
        isExpertInfoLoading: false,
        expertDetails: {},
      };
      break;

    /*------------ */
    case 'EXPERT_ALL_SUB_CATEGORY_REQUEST':
      state = {
        ...state,
        isSubCatLoading: true,
        allSubCategory: [],
      };
      break;

    case 'EXPERT_ALL_SUB_CATEGORY_SUCCESS':
      state = {
        ...state,
        isSubCatLoading: false,
        allSubCategory: action.data,
      };
      break;

    case 'EXPERT_ALL_SUB_CATEGORY_ERROR':
      state = {
        ...state,
        isSubCatLoading: false,
        allSubCategory: [],
      };
      break;

    /*------------ */
    case 'EXPERINCE_TYPE_LIST_REQUEST':
      state = {
        ...state,
        isExpTypeLoading: true,
        expTypeList: [],
      };
      break;

    case 'EXPERINCE_TYPE_LIST_SUCCESS':
      state = {
        ...state,
        isExpTypeLoading: false,
        expTypeList: action.data,
      };
      break;

    case 'EXPERINCE_TYPE_LIST_ERROR':
      state = {
        ...state,
        isExpTypeLoading: false,
        expTypeList: [],
      };
      break;
    /*------------ */
    case 'RATE_TYPE_LIST_REQUEST':
      state = {
        ...state,
        isRateTypeLoading: true,
        rateTypeList: [],
      };
      break;

    case 'RATE_TYPE_LIST_SUCCESS':
      state = {
        ...state,
        isRateTypeLoading: false,
        rateTypeList: action.data,
      };
      break;

    case 'RATE_TYPE_LIST_ERROR':
      state = {
        ...state,
        isRateTypeLoading: false,
        rateTypeList: [],
      };
      break;

    /*------------ */
    case 'EXPERT_MAINSUB_CAT_REQUEST':
      state = {
        ...state,
        isMainSubCatLoading: true,
        mainSubList: [],
        isMainSubEmpty: true,
      };
      break;

    case 'EXPERT_MAINSUB_CAT_SUCCESS':
      state = {
        ...state,
        isMainSubCatLoading: false,
        mainSubList: action.data,
        isMainSubEmpty: false,
      };
      break;

    case 'EXPERT_MAINSUB_CAT_ERROR':
      state = {
        ...state,
        isMainSubCatLoading: false,
        mainSubList: [],
        isMainSubEmpty: false,
      };
      break;

    /*------------ */
    case 'EXPERT_LIST_REQUEST':
      state = {
        ...state,
        isExpertListLoading: true,
        expertsList: [],
      };
      break;

    case 'EXPERT_LIST_SUCCESS':
      state = {
        ...state,
        isExpertListLoading: false,
        expertsList: action.data, 
      };
      break;

    case 'EXPERT_LIST_ERROR':
      state = {
        ...state,
        isExpertListLoading: false,
        expertsList: [],
      };
      break;

    /*------------ */
    case 'RECOMMENDED_EXPERT_LIST_REQUEST':
      state = {
        ...state,
        isRecommendedExpertListLoading: true,
        recommendedExpertsList: [],
      };
      break;

    case 'RECOMMENDED_EXPERT_LIST_SUCCESS':
      state = {
        ...state,
        isRecommendedExpertListLoading: false,
        recommendedExpertsList: action.data,
        
      };
      break;

    case 'RECOMMENDED_EXPERT_LIST_ERROR':
      state = {
        ...state,
        isRecommendedExpertListLoading: false,
        recommendedExpertsList: [],
      };
      break;

    case 'RECOMMENDED_EXPERT_LIST_CLEAR':
      state = {
        ...state,
        isRecommendedExpertListLoading: false,
        recommendedExpertsList: [],
      };
      break;

    /*------------ */
    case 'RECOMMENDATION_TEXT_REQUEST':
      state = {
        ...state,
        isRecommendationTextLoading: true,
        recommendationText: '',
      };
      break;

    case 'RECOMMENDATION_TEXT_SUCCESS':
      state = {
        ...state,
        isRecommendationTextLoading: false,
        recommendationText: action.data,
      };
      break;

    case 'RECOMMENDATION_TEXT_ERROR':
      state = {
        ...state,
        isRecommendationTextLoading: false,
        recommendationText: '',
      };
      break;
    /*------------ */
    case 'BILL_PLAN_REQUEST':
      state = {
        ...state,
        isBillLoading: true,
        billPlanList: [],
      };
      break;

    case 'BILL_PLAN_SUCCESS':
      state = {
        ...state,
        isBillLoading: false,
        billPlanList: action.data,
      };
      break;

    case 'BILL_PLAN_ERROR':
      state = {
        ...state,
        isBillLoading: false,
        billPlanList: [],
      };
      break;
    /*------------ */
    case 'GET_QUOTATION_REQUEST':
      state = {
        ...state,
        isQuoatLoading: true,
        quotationDetails: {},
      };
      break;

    case 'GET_QUOTATION_SUCCESS':
      state = {
        ...state,
        isQuoatLoading: false,
        quotationDetails: action.data,
      };
      break;

    case 'GET_QUOTATION_ERROR':
      state = {
        ...state,
        isQuoatLoading: false,
        quotationDetails: {},
      };
      break;
    /*------------ */
    case 'PAYMENTS_DATA_REQUEST':
      state = {
        ...state,
        isPayDetailLoading: true,
        paymentData: [],
      };
      break;

    case 'PAYMENTS_DATA_SUCCESS':
      state = {
        ...state,
        isPayDetailLoading: false,
        paymentData: action.data,
      };
      break;

    case 'PAYMENTS_DATA_ERROR':
      state = {
        ...state,
        isPayDetailLoading: false,
        paymentData: [],
      };
      break;

    case 'ASSIGN_QUESTION_TO_EXPERT':
      state = {
        ...state,
        isAssignQuestionLoading: true,
        assignQuestionToExpert: [],
      };
      break;

    case 'ASSIGN_QUESTION_TO_EXPERT_SUCCESS':
      state = {
        ...state,
        isAssignQuestionLoading: false,
        assignQuestionToExpert: action.data,
      };
      break;

    case 'ASSIGN_QUESTION_TO_EXPERT_ERROR':
      state = {
        ...state,
        isAssignQuestionLoading: false,
        assignQuestionToExpert: [],
      };
      break;

    case 'ALL_CREDIT_REQUEST':
      state = {
        ...state,
        isCreditLoading: true,
        creditList: [],
      };
      break;

    case 'ALL_CREDIT_SUCCESS':
      state = {
        ...state,
        isCreditLoading: false,
        creditList: action.data,
      };
      break;

    case 'ALL_CREDIT_ERROR':
      state = {
        ...state,
        isCreditLoading: false,
        creditList: [],
      };
      break;

    case 'PAYMENT_REQUEST':
      state = {
        ...state,
        isPayLoading: true,
        paymentMsg: '',
        paymentError: '',
      };
      break;

    case 'PAYMENT_SUCCESS':
      state = {
        ...state,
        isPayLoading: false,
        paymentMsg: 'success',
        paymentError: action.data,
      };
      break;

    case 'PAYMENT_ERROR':
      state = {
        ...state,
        isPayLoading: false,
        paymentMsg: '',
        paymentError: '',
      };
      break;

    case 'PAYMENT_TAX_REQUEST':
      state = {
        ...state,
        isTaxLoading: true,
        paymentTax: {},
      };
      break;

    case 'PAYMENT_TAX_SUCCESS':
      state = {
        ...state,
        isTaxLoading: false,
        paymentTax: action.data,
      };
      break;

    case 'PAYMENT_TAX_ERROR':
      state = {
        ...state,
        isTaxLoading: false,
        paymentTax: '',
        paymentError: '',
      };
      break;

    case 'PAYMENT_DETAIL_REQUEST':
      state = {
        ...state,
        isPaymetDetailLoading: true,
        paymentDetails: [],
      };
      break;

    case 'PAYMENT_DETAIL_SUCCESS':
      state = {
        ...state,
        isPaymetDetailLoading: false,
        paymentDetails: action.data,
      };
      break;

    case 'PAYMENT_DETAIL_ERROR':
      state = {
        ...state,
        isPaymetDetailLoading: false,
        paymentDetails: [],
      };
      break;

    /*------------ */
    case 'GET_CREDIT_BY_ID':
      state = {
        ...state,
        isCreditByIdLoading: true,
      };
      break;

    case 'GET_CREDIT_BY_ID_SUCCESS':
      state = {
        ...state,
        isCreditByIdLoading: false,
        expertCreditById: action.data,
      };
      break;

    case 'GET_CREDIT_BY_ID_ERROR':
      state = {
        ...state,
        isCreditByIdLoading: false,
        expertCreditById: '',
      };
      break;

    /*------------ */
    case 'GET_EARNING_BY_ID':
      state = {
        ...state,
        isEarningByIdLoading: true,
      };
      break;

    case 'GET_EARNING_BY_ID_SUCCESS':
      state = {
        ...state,
        isEarningByIdLoading: false,
        expertEarningById: action.data,
      };
      break;

    case 'GET_EARNING_BY_ID_ERROR':
      state = {
        ...state,
        isEarningByIdLoading: false,
        expertEarningById: '',
      };
      break;

    /*------------ */
    case 'EXPERT_NEW_APPLICATION':
      state = {
        ...state,
        isExpertNewApplicationLoading: true,
      };
      break;

    case 'EXPERT_NEW_APPLICATION_SUCCESS':
      state = {
        ...state,
        isExpertNewApplicationLoading: false,
        expertNewApplication: action.data,
      };
      break;

    case 'EXPERT_NEW_APPLICATION_ERROR':
      state = {
        ...state,
        isExpertNewApplicationLoading: false,
        expertNewApplication: [],
      };
      break;

    /*------------ */
    case 'EXPERT_PENDING_UPDATES':
      state = {
        ...state,
        isExpertPendingUpdatesLoading: true,
      };
      break;

    case 'EXPERT_PENDING_UPDATES_SUCCESS':
      state = {
        ...state,
        isExpertPendingUpdatesLoading: false,
        expertPendingUpdates: action.data,
      };
      break;

    case 'EXPERT_PENDING_UPDATES_ERROR':
      state = {
        ...state,
        isExpertPendingUpdatesLoading: false,
        expertPendingUpdates: [],
      };
      break;

    /*------------ */
    case 'UPDATE_EXPERT_STATUS':
      state = {
        ...state,
        isUpdateExpertStatusLoading: true,
      };
      break;

    case 'UPDATE_EXPERT_STATUS_SUCCESS':
      state = {
        ...state,
        isUpdateExpertStatusLoading: false,
        updateExpertStatus: action.data,
      };
      break;

    case 'UPDATE_EXPERT_STATUS_ERROR':
      state = {
        ...state,
        isUpdateExpertStatusLoading: false,
        updateExpertStatus: [],
      };
      break;

    /*------------ */
    case 'UPDATE_EXPERT_FREEZING_STATUS':
      state = {
        ...state,
        isUpdateExpertFreezingStatusLoading: true,
      };
      break;

    case 'UPDATE_EXPERT_FREEZING_STATUS_SUCCESS':
      state = {
        ...state,
        isUpdateExpertFreezingStatusLoading: false,
        updateExpertFreezingStatus: action.data,
      };
      break;

    case 'UPDATE_EXPERT_FREEZING_STATUS_ERROR':
      state = {
        ...state,
        isUpdateExpertFreezingStatusLoading: false,
        updateExpertFreezingStatus: [],
      };
      break;

    /*------------ */
    case 'CLEAR_COMMON':
      state = {
        ...state,
        resStatus: '',
        isDocUploadLoading: false,
        docURL: '',
        isPayLoading: false,
        paymentMsg: '',
        paymentError: '',
      };
      break;

    default:
  }
  return state;
};

export default AuthReducer;
