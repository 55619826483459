import React, { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Switch, Select, Divider, message } from 'antd';
import {
  DeleteOutlined,
  LoadingOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { getUserData, NextSevenDaysNames, role } from '../../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import FutureMeetingIcon from '../../../assets/images/futuremeetingicon.png';
import { timezone } from '../../../helpers/constant';
import moment from 'moment';
const meetingTimes = [15, 30, 45, 60];
const { nextSevenDaysName, nextSevenDates } = NextSevenDaysNames();

/**
 * Generates an array of time options and their corresponding values.
 *
 * @return {Object} An object containing two arrays: `options` and `optionsvalue`.
 * `options` is an array of formatted time options in the format "HH:MM AM/PM".
 * `optionsvalue` is an array of time values in the format "HH:MM".
 */
const generateTimeOptions = (duration = 30) => {
  const options = [];
  const optionsvalue = [];
  let startTime = new Date();
  startTime.setHours(0, 0, 0, 0);

  const timeInterval = duration;

  for (let i = 0; i < 24 * (60 / timeInterval); i++) {
    let hours = startTime.getHours();
    let minutes = startTime.getMinutes().toString().padStart(2, '0');
    let period = hours >= 12 ? 'PM' : 'AM';

    // if (hours == 23 && minutes >= "45" && period == "PM") {
    //   continue;
    // }
    optionsvalue.push(`${hours}:${minutes}`);
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    hours = hours.toString().padStart(2, '0');
    options.push(`${hours}:${minutes} ${period}`);
    startTime.setMinutes(startTime.getMinutes() + duration);
  }

  return { options, optionsvalue };
};

/**
 * Renders the Add Meeting Time component with button and modal functionality.
 *
 * @return {JSX.Element} The rendered JSX element
 */
const AddMeetingTime = ({ threadBySubjectId, expertRecords }) => {
  const expertData = useSelector(state => state?.common?.questionDetail);
  const userData = getUserData();
  const dispatch = useDispatch();
  const timingArray = {};

  nextSevenDates.map((el, index) => {
    timingArray[el] = [
      {
        // from: {
        //   value: '09:00',
        //   label: '09:00 AM',
        // },
        // to: {
        //   value: '05:00',
        //   label: '05:00 PM',
        // },
      },
    ];
    if (nextSevenDaysName[index] === 'Sunday' || nextSevenDaysName[index] === 'Saturday') {
      timingArray[el] = [];
    }
  });

  const [meetingTime, setMeetingTime] = useState(30);
  const [modalScreen, setModalScreen] = useState(null);
  const [openDurationModal, setOpenDurationModal] = useState(false);
  const [availabilityTimings, setAvailabilityTimings] = useState(timingArray);
  const [userTimeZone, setUserTimeZone] = useState('');
  const [sendBtnVisible, setsendBtnVisible] = useState(false);
  const handleSendMessageBtn = () => {
    let checkAvailabilityTimingsDatares = checkAvailabilityTimingsData(availabilityTimings);
    if (!checkAvailabilityTimingsDatares.error) {
      if (checkAvailabilityTimingsDatares?.date) {
        message.error(
          'please select correct time ' +
            moment(checkAvailabilityTimingsDatares?.date).format('dddd'),
        );
      } else {
        message.error('please select atleast one time');
      }
      return;
    }
    dispatch({
      type: 'POST_MESSAGE',
      formData: {
        id: threadBySubjectId?.id,
        message: JSON.stringify({
          availabilityTimings: availabilityTimings,
          duration: meetingTime,
          userTimeZone: userTimeZone,
          currentDate: new Date(),
          party_a_creator: userData?.id,
          party_a_creator_name: userData?.first_name + ' ' + userData?.last_name,
          party_b: expertRecords?.id || expertData?.expert_id,
          party_b_name: expertRecords?.first_name
            ? expertRecords?.first_name + ' ' + expertRecords?.last_name
            : expertData.expert.first_name + ' ' + expertData.expert.last_name,
        }),
        type: 'PICK_DATE_AND_TIME',
        has_attachment: 0,
      },
    });
    handleModelClose();
  };
  const handleModelClose = () => {
    setMeetingTime(30);
    setOpenDurationModal(false);
    setModalScreen(null);
    setAvailabilityTimings(timingArray);
    setUserTimeZone('');
  };

  function checkAvailabilityTimingsData(data) {
    if (!Object.values(data).some(timings => timings.length > 0)) {
      return {
        date: null,
        error: false,
      };
    }
    for (const date in data) {
      for (const entry of data[date]) {
        if (!entry.hasOwnProperty('from') || !entry.hasOwnProperty('to')) {
          return {
            date: date,
            error: false,
          }; // Return false if "from" exists without "to"
        }
      }
    }
    return {
      date: null,
      error: true,
    }; // Return true if all entries pass the check
  }
  return (
    <>
      <img
        onClick={() => setOpenDurationModal(true)}
        style={{ width: '40px', height: '40px' }}
        className="videosend"
        src={FutureMeetingIcon}
        alt="video icon"
        loading="lazy"
      />
      <Modal
        centered
        open={openDurationModal}
        onCancel={handleModelClose}
        footer={[
          modalScreen == 'editAvailability' ? (
            <>
              <Button
                key="back"
                onClick={() => (
                  setModalScreen('firstScreen'),
                  setAvailabilityTimings(timingArray),
                  setUserTimeZone('')
                )}
              >
                Previous
              </Button>
              <Button
                key="send"
                type="primary"
                onClick={() => handleSendMessageBtn()}
                // disabled={sendBtnVisible}
              >
                Send
              </Button>
            </>
          ) : (
            <Button key="submit" type="primary" onClick={() => setModalScreen('editAvailability')}>
              Next
            </Button>
          ),
        ]}
        width={'auto'}
      >
        {modalScreen == 'firstScreen' || modalScreen == null ? (
          <FirstScreen
            setModalScreen={setModalScreen}
            meetingTime={meetingTime}
            setMeetingTime={setMeetingTime}
          />
        ) : (
          <SecondScreen
            availabilityTimings={availabilityTimings}
            setAvailabilityTimings={setAvailabilityTimings}
            setUserTimeZone={setUserTimeZone}
            meetingTime={meetingTime}
          />
        )}
      </Modal>
    </>
  );
};

/**
 * Renders the first screen of the meeting duration selection.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.setModalScreen - The function to set the modal screen.
 * @param {number} props.meetingTime - The currently selected meeting time.
 * @param {function} props.setMeetingTime - The function to set the meeting time.
 * @return {JSX.Element} The JSX element representing the first screen.
 */
export const FirstScreen = ({ setModalScreen, meetingTime, setMeetingTime }) => {
  return (
    <>
      <h1>Choose meeting duration</h1>
      <div className="button-box">
        <div className="meeting-times d-flex-between">
          <Row
            style={{ justifyContent: 'space-around' }}
            // gutter={{
            //   xs: 8,
            //   sm: 16,
            //   md: 24,
            //   lg: 32,
            // }}
          >
            {meetingTimes.map((el, index) => {
              return (
                <Col className="gutter-row" xs={12} sm={6} md={6} lg={6} style={{ marginBottom: '10px',borderRadius:"5px",padding:"5px" }}>
                  <div
                    className={`meeting-time ${meetingTime == el && 'selected-meeting-time'}`}
                    data-index={index}
                    onClick={e => setMeetingTime(el)}
                  >
                    {el} minutes
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </>
  );
};

/**
 * Renders the second screen for sharing availability.
 *
 * @param {Object} availabilityTimings - The availability timings object.
 * @param {function} setAvailabilityTimings - The function to set availability timings.
 * @return {JSX.Element} The JSX for the second screen.
 */
export const SecondScreen = ({
  availabilityTimings,
  setAvailabilityTimings,
  setUserTimeZone,
  meetingTime,
}) => {
  const userData = getUserData();
  useEffect(() => {
    setUserTimeZone(userData?.locations?.[0]?.timezone ?? timezone[0]?.value);
  }, []);
  return (
    <>
      <h1>Share your availability</h1>
      <p className="sub-title">
        Select dates and times when you are available for a call. we will send your availability{' '}
        {role() == 'expert' ? 'to client' : 'to expert'} to choose actual meeting time and notify it
        back to you.
      </p>
      <div
        className="timezone-section"
        style={{
          marginTop: '20px',
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'left',
          flexDirection: 'column',
        }}
      >
        <h3>Timezone</h3>
        {timezone.length ? (
          <Select
            showSearch
            labelInValue
            defaultValue={userData?.locations?.[0]?.timezone ?? timezone[0]?.value}
            style={{ width: '100%' }}
            onChange={e => setUserTimeZone(e.value)}
            options={timezone}
          />
        ) : (
          <LoadingOutlined style={{ fontSize: '20px', color: '#1890ff' }} />
        )}
      </div>
      <FromToTime
        availabilityTimings={availabilityTimings}
        setAvailabilityTimings={setAvailabilityTimings}
        meetingTime={meetingTime}
      />
    </>
  );
};

/**
 * Renders the components for setting availability timings.
 *
 * @param {Object} availabilityTimings - The availability timings object.
 * @param {function} setAvailabilityTimings - The function to set availability timings.
 * @return {JSX.Element} The JSX element representing the availability timings components.
 */
export const FromToTime = ({ availabilityTimings, setAvailabilityTimings, meetingTime }) => {
  const { options, optionsvalue } = generateTimeOptions(meetingTime);
  let timeOptionsObjLabel = [];
  options.map((time, index) => {
    timeOptionsObjLabel.push({ label: time, value: optionsvalue[index] });
  });
  const [toTimeObj, setToTimeObj] = useState({});

  const [dumy, setdumy] = useState(true);

  const handleAddAvailabilityTimings = (event, day, type) => {
    if (type == 'add-first') {
      if (event) {
        setAvailabilityTimings(prevTimings => ({
          ...prevTimings,
          [day]: [
            ...prevTimings[day],
            {
              // from: { value: '09:00', label: '09:00 AM' },
              // to: { value: '05:00', label: '05:00 PM' },
            },
          ],
        }));
      } else {
        setAvailabilityTimings(prevTimings => {
          return {
            ...prevTimings,
            [day]: [],
          };
        });
      }
    } else {
      setAvailabilityTimings(prevTimings => ({
        ...prevTimings,
        [day]: [
          ...prevTimings[day],
          {
            // from: { value: '09:00', label: '09:00 AM' },
            // to: { value: '05:00', label: '05:00 PM' },
          },
        ],
      }));
    }
  };
  const handleDeleteAvailabilityTimings = (day, index) => {
    const newTimings = availabilityTimings[day].filter((item, i) => {
      return i !== index;
    });
    setAvailabilityTimings(prevTimings => {
      return {
        ...prevTimings,
        [day]: newTimings,
      };
    });
  };

  const handleonChange = (day, index, type, value, label, e) => {
    let newAvailabilityTimings = availabilityTimings;
    if (type == 'from') {
      let newtoTimeObj = toTimeObj || {};

      newtoTimeObj = {
        ...newtoTimeObj,
        [getKey(day, index)]: timeOptionsObjLabel.filter(time =>
          moment(time.value, 'HH:mm').isAfter(moment(value, 'HH:mm')),
        ),
      };

      let parseTime = 60 - parseInt(meetingTime) == '0' ? '00' : 60 - parseInt(meetingTime);

      if (value == '23:' + parseTime) {
        newtoTimeObj = {
          ...newtoTimeObj,
          [getKey(day, index)]: [
            {
              value: '12:00',
              label: '12:00 AM',
            },
          ],
        };
      }

      setToTimeObj(newtoTimeObj);

      newAvailabilityTimings[day][index]['to'] = {
        value: newtoTimeObj?.[getKey(day, index)]?.[0]?.value,
        label: newtoTimeObj?.[getKey(day, index)]?.[0]?.label,
      };
    }
    // else {

    newAvailabilityTimings[day][index][type] = { value: value, label: label };

    // }

    setdumy(!dumy);
    setAvailabilityTimings(newAvailabilityTimings);
  };
  function getKey(day, index) {
    let newOne = day.replaceAll('-', '') + index;
    return newOne;
  }

  return (
    <>
      <div className="day-main-container">
        {nextSevenDates.map((el, index) => {
          return (
            <>
              <Row
                className="day-main-container-row"
                gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24,
                  lg: 32,
                }}
              >
                <Col className="gutter-row" xs={8} md={5}>
                  <div className="switch-and-day">
                    <div style={{ paddingRight: '20px' }}>
                      <Switch
                        defaultChecked
                        checked={availabilityTimings[el].length > 0}
                        onClick={event => handleAddAvailabilityTimings(event, el, 'add-first')}
                      />
                    </div>

                    <div style={{ minWidth: '5rem' }}>
                      <p>{nextSevenDaysName[index]}</p>
                    </div>
                  </div>
                </Col>

                <Col className="gutter-row" xs={8} md={16}>
                  {availabilityTimings[el].length > 0 &&
                    availabilityTimings[el].map((val, index) => {
                      return (
                        <Row
                          className="day-main-container-row"
                          gutter={{
                            xs: 8,
                            sm: 16,
                            md: 24,
                            lg: 32,
                          }}
                        >
                          <Col className="gutter-row" xs={24}>
                            <div className="from-and-to-time">
                              <Select
                                className="from-time"
                                placeholder="Select Start Time"
                                labelInValue
                                defaultValue={val?.from}
                                style={{
                                  width: '150px',
                                }}
                                onChange={e =>
                                  handleonChange(el, index, 'from', e.value, e.label, e)
                                }
                                options={timeOptionsObjLabel}
                              />
                              <p style={{ margin: '0' }}>to</p>
                              <Select
                                labelInValue
                                notFoundContent={'Please choose the start time first'}
                                // defaultValue={val?.to}
                                value={availabilityTimings?.[el]?.[index]?.to?.value || ''}
                                style={{
                                  width: '150px',
                                }}
                                onChange={e => handleonChange(el, index, 'to', e.value, e.label, e)}
                                options={toTimeObj?.[getKey(el, index)] || {}}
                              />

                              <span data-index={index}>
                                <DeleteOutlined
                                  key={index}
                                  style={{ fontSize: '1.5rem', color: '#08c' }}
                                  onClick={() => handleDeleteAvailabilityTimings(el, index)}
                                />
                              </span>
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                </Col>
                <Col className="gutter-row" xs={8} md={3}>
                  {availabilityTimings[el].length > 0 && (
                    <div
                      key={index}
                      className="add-more-time"
                      style={{ cursor: 'pointer' }}
                      onClick={event => handleAddAvailabilityTimings(event, el, 'add-more')}
                    >
                      <PlusCircleFilled style={{ fontSize: '2rem', color: '#08c' }} />
                    </div>
                  )}
                </Col>
              </Row>
              <Divider />
            </>
          );
        })}
      </div>
    </>
  );
};

export default AddMeetingTime;
