import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ExpertCard from './ExpertCard';
import { Row, Col, Input, Select, Button, Spin, Card } from 'antd';
import helpImg from '../../assets/images/expert/help.svg';
import { useNavigate } from 'react-router-dom';
import CustomSelect from '../common/customSelect';
import useResponsive from '../../hooks/useBreakpoint';

const { Search } = Input;

let filterParams = { search: '' };

const FindAnExpert = () => {
  const dispatch = useDispatch();
  const { isLocationLoading, locationList } = useSelector(state => state.common);
  const { isExpertListLoading, expertsList } = useSelector(state => state.expert);
  const navigate = useNavigate();

  const [search, setSearch] = useState({ find: '' });
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedLocationFilter, setSelectedLocationFilter] = useState();
  const [page,setPage] = useState(1);
  useEffect(() => {
    if (!locationList.length) {
      dispatch({ type: 'GET_LOCATION_REQUEST', formData: { status: 'active' } });
    }
    dispatch({ type: 'EXPERT_LIST_REQUEST', formData: {page: page} });
  }, []);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (
  //       window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 100
  //     ) {
  //       setPage((prevPage) => prevPage + 1);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);
  
  useEffect(() => {
    if (!isExpertListLoading && expertsList?.length > 0) {
      setTableData( expertsList);
    } else if (!isExpertListLoading && expertsList?.length === 0) {
      setTableData([]);
    }
  }, [isExpertListLoading]);

  useEffect(() => {
    const filteredData = tableData?.filter(el =>
      `${el?.first_name} ${el?.last_name}`.toLowerCase().includes(search?.find?.toLowerCase()),
    );
    setFilteredTableData(filteredData);
    if (!search.find.length) {
      setFilteredTableData(tableData);
    }
  }, [search, tableData, filteredTableData]);

  useEffect(() => {
    if (selectedLocationFilter) {
      const filteredData = tableData?.filter(el => {
        if (el.locations.length && el.locations.some(location => location.id === selectedLocationFilter)) {
          return el;
        } else {
          return null;
        }
      });
      if (filteredData) {
        setFilteredTableData(filteredData);
      } else {
        setFilteredTableData(tableData);
      }
    }
  }, [selectedLocationFilter, tableData, filteredTableData]);

  const onExpertCardClick = item => {
    navigate(`/find-expert-detail/${item.id}`, {
      state: item,
    });
  };

  const handleSearch = e => {
    setSearch({ ...search, find: e.target.value });
  };

  const optionsList = tmpLocation => {
    let locationOptions = [];
    if (tmpLocation.length > 0) {
      tmpLocation.map(item => {
        return locationOptions.push({
          value: item.id,
          label: item.name,
        });
      });
    }
    return locationOptions;
  };
  const breakpoint = useResponsive();
  
  return (
    <div className="padding-around find-expert">

      {/* <Row justify="center" align="middle" className="beta-warning">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <p>
            <strong>
              Please note: The expert profiles listed below are exclusively for Alpha and Beta
              testing purposes and are not intended for public use.
              <br />
              Access to these profiles should be limited to authorized users involved in the testing
              process.
            </strong>
          </p>
        </Col>
      </Row> */}
      
      <Row gutter={30} style={{ marginTop: 20, }}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Search
            disabled={selectedLocationFilter}
            defaultValue={filterParams['search']}
            size="large"
            allowClear
            placeholder="Search by expert name."
            style={{ width: '100%' }}
            enterButton
            onChange={handleSearch}
          />
        </Col>
        <Col className={`${(breakpoint == 'xs' || breakpoint == 'sm') ? 'mt20' : ''}`} xs={24} sm={24} md={12} lg={8} xl={8}>
          <CustomSelect
            placeholder="Select an option"
            style={{
              width: '100%',
            }}
            disabled={search.find.length}
            size="large"
            value={selectedLocationFilter}
            onChange={val => {
              setSelectedLocationFilter(val);
            }}
            showSearch
            allowClear
            loading={isLocationLoading}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={optionsList(locationList)}
          />
        </Col>
        {/* <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Button
                        style={{ width: "100%" }}
                        type="primary"
                        htmlType="submit"
                        size="large"
                        className=""
                    >
                        Search
                    </Button>z
                </Col> */}
      </Row>
      <div className="mt35"></div>
      <Row className="mt5" gutter={[30, 30]} >
        {isExpertListLoading && (
          <div style={{ width: '75%' }} className="d-flex-a-c">
            <Spin />
          </div>
        )}
        {!isExpertListLoading && expertsList.length > 0 && (
          <Col xs={24} sm={24} md={24} lg={24} xl={18}>
            <Row gutter={[30, 30]}>
              {filteredTableData.map(item => (
                <ExpertCard item={item} onClick={() => onExpertCardClick(item)} />
              ))}
            </Row>
          </Col>
        )}

        <Col xs={24} sm={24} md={24} lg={24} xl={6}>
          <Card className="find-card">
            <div>
              <img loading='lazy' alt="expert" className="main-img" src={helpImg} />
              <div className="card-section mt-5">
                <p className="help-title">Need Help</p>
                <p className="sub-title">
                  We can always help you find the right one for your specific task.
                </p>
                <Button
                  style={{ width: '100%' }}
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className=""
                  onClick={() => navigate('/request')}
                >
                  Get Help!
                </Button>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default FindAnExpert;
