import React from 'react';
import Helmet from '../../common/helmet';

const AdminUserManagement = props => {
  return (
    <>
      <Helmet title="User Management" />
      <p>User management</p>
    </>
  );
};

export default AdminUserManagement;
