import React, { useEffect } from 'react';
import { Card, Form, Button, Input, message } from 'antd';
import Parent from './parent';
import Helmet from '../common/helmet';
import Password from '../common/password';
import { PASSWORD_RULE } from '../../helpers/constant';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const SetPassword = props => {
  const { isChangePasswordUsingTokenLoading, changePasswordUsingTokenStatus } = useSelector(
    state => state.auth,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isChangePasswordUsingTokenLoading && changePasswordUsingTokenStatus === 'success') {
      dispatch({ type: 'CLEAR_LOGIN_DATA' });
      message.success('Password updated successfully');
      navigate('/login');
    } else if (!isChangePasswordUsingTokenLoading && changePasswordUsingTokenStatus === 'invalid') {
      message.error('Invalid token');
    }
  }, [isChangePasswordUsingTokenLoading, changePasswordUsingTokenStatus]);

  const onFinish = values => {
    dispatch({
      type: 'CHANGE_PASSWORD_USING_TOKEN',
      formData: {
        ...values,
      },
    });
  };
  const onFinishFailed = errorInfo => {};

  return (
    <Parent>
      <Helmet title="Set Password" />
      <div className="login-card">
        <div className="site-card-border-less-wrapper">
          <Card bordered={false}>
            <p className="m-title">Create new password</p>
            <Form
              layout="vertical"
              name="basic"
              hideRequiredMark={true}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item label="Verification Code" name="token">
                <Input size="large" placeholder="••••••" maxLength={6} />
              </Form.Item>
              <Password />
              <Form.Item
                label="Confirm Password"
                name="password_confirmation"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('The two passwords that you entered do not match!'),
                      );
                    },
                  }),
                ]}
              >
                <Input.Password size="large" placeholder="••••••••••" />
              </Form.Item>
              <p className="mt3 mb0 help-text">{PASSWORD_RULE}</p>
              <Form.Item className="mt25">
                <Button type="primary" htmlType="submit" className="w100" size="large">
                  Set Password
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    </Parent>
  );
};

export default SetPassword;
