import { combineReducers } from 'redux';
import auth from './auth';
import common from './common';
import steps from './steps';
import expert from './expert';
import requests from './requests';
import admin from './admin';

export default combineReducers({
  auth,
  common,
  steps,
  expert,
  requests,
  admin,
});
