import { CloseOutlined, RobotFilled, SendOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import { getAccessTokenForDialogflow } from '../../helpers/apiCall';
import axios from 'axios';

const Dialogueflow = () => {
  const messagesEndRef = useRef(null); 
  const [show, setShow] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem('dialogueflowMessages');
    return savedMessages ? JSON.parse(savedMessages) : [];
  });
  const [userInput, setUserInput] = useState('');

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getAccessTokenForDialogflow();
        setAccessToken(token);
      } catch (error) {
        console.error('Failed to get access token:', error);
      }
    };

    fetchToken();
  }, []);

  useEffect(() => {
    localStorage.setItem('dialogueflowMessages', JSON.stringify(messages));
  }, [messages]);

  const sessionId = `${Date.now()}-${Math.random().toString(36).substring(2, 15)}`;
  const dialogflowEndpoint = `https://dialogflow.googleapis.com/v2beta1/projects/accountizer-bot-436506/agent/sessions/${sessionId}:detectIntent`;

  async function sendMessage() {
    if (!userInput.trim()) return;
    const userMessage = { text: userInput, sender: 'user' };
    setMessages([...messages, userMessage]);

    try {
      const response = await axios.post(
        dialogflowEndpoint,
        {
          queryInput: {
            text: {
              text: userInput,
              languageCode: 'en',
            },
          },
          queryParams: {
            knowledgeBaseNames: [
              'projects/accountizer-bot-436506/knowledgeBases/ODM1MjAwNTQ5ODQyOTUwNTUzNw',
            ],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const botResponse = response.data.queryResult.fulfillmentText;
      setMessages(prevMessages => [...prevMessages, { text: botResponse, sender: 'bot' }]);
    } catch (error) {
      console.error('Error communicating with Dialogflow:', error);
    }

    setUserInput('');
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);
  return (
    <div style={{ position: 'fixed', zIndex: 9999, bottom: '5%', right: '3%' }}>
      {!show ? (
        <RobotFilled
          onClick={() => setShow(!show)}
          style={{ fontSize: '3rem', cursor: 'pointer', color: 'rgb(28,100,242)' }}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: '',
            alignItems: 'center',
            position: 'relative',
            width: '20rem',
            height: '30rem',
            backgroundColor: 'rgb(242,242,242)',
            padding: '0rem 0rem',
            borderRadius: '1rem',
            border: '.5px solid rgb(200,200,200)',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <div
            style={{
              width: '100%',
              height: '85%',
            }}
          >
            <div
              style={{
                height: '12%',
                backgroundColor: 'rgb(28,100,242)',
                borderTopLeftRadius: '1rem',
                borderTopRightRadius: '1rem',
                padding: '1rem',
                color: 'white',
              }}
            >
              <strong>Accountizer Assistant</strong>
              {/* <strong style={{ fontSize: '.7rem', marginLeft: '1.5rem',backgroundColor: 'white', cursor: 'pointer',color: 'rgb(28,100,242)',paddingLeft: '.3rem',paddingRight: '.3rem',paddingTop: '.1rem',paddingBottom: '.1rem', borderRadius: '.3rem' }}>Clear chat</strong> */}
              <CloseOutlined
                style={{ float: 'right', cursor: 'pointer', color: 'white' }}
                onClick={() => setShow(!show)}
              />
            </div>

            <div
              style={{
                display: 'flex',
                overflowY: 'auto',
                scrollbarWidth: 'none',
                flexDirection: 'column',
                height: '88%',
                padding: '1rem',
                width: '100%',
                backgroundColor: 'white',
              }}
           
            >
              {messages.length > 0 ? (
                messages.map((message, index) => (
                  <>
                    <div
                      key={index}
                      style={{
                        alignSelf: `flex-${message.sender === 'user' ? 'end' : 'start'}`,
                        marginBottom: '1rem',
                        padding: '.7rem',
                        maxWidth: '70%',
                        backgroundColor: `rgba(28,100,242,${message.sender === 'user' ? '1' : '.1'})`,
                        color: `${message.sender === 'user' ? 'white' : 'black'}`,
                        borderRadius: '1rem',
                      }}
                    >
                      {message.text}
                    </div>
                    {!(message.sender === 'user') && (
                      <div
                        key={index}
                        style={{
                          alignSelf: `flex-${message.sender === 'user' ? 'end' : 'start'}`,
                          marginBottom: '1rem',
                          padding: '.7rem',
                          maxWidth: '70%',
                          backgroundColor: `rgba(28,100,242,${message.sender === 'user' ? '1' : '.1'})`,
                          color: `${message.sender === 'user' ? 'white' : 'black'}`,
                          borderRadius: '1rem',
                        }}
                      >
                        <span>
                          Was this information helpful? If not, feel free to book a one-on-one
                          appointment with our representative. We are happy to help!
                        </span>
                        <p>
                          <a
                            target="_blank"
                            rel='noopener noreferrer'
                            href="https://calendly.com/justin-m-accountizer"
                            style={{ color: 'rgb(28,100,242)', cursor: 'pointer' }}
                          >
                            Book Demo
                          </a>
                        </p>
                      </div>
                    )}
                  </>
                ))
              ) : (
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {' '}
                  <RobotFilled
                    style={{ fontSize: '5rem', cursor: 'pointer', color: 'rgba(28,100,242,.1)' }}
                  />
                  <span style={{ color: 'rgba(28,100,242,.3)' }}>Start a conversation</span>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'rgb(242,242,242)',
              height: '15%',
              borderRadius: '1rem',
            }}
          >
            <div
              style={{
                width: '100%',
                outline: 'none',
                border: 'none',
                padding: '1rem',
                height: '80%',
                backgroundColor: 'white',
                borderBottomLeftRadius: '1rem',
                borderBottomRightRadius: '1rem',
              }}
            >
              <input
                type="text"
                placeholder="Write a message"
                style={{
                  width: '90%',
                  outline: 'none',
                  border: 'none',
                  padding: '1rem',
                  height: '80%',
                }}
                value={userInput}
                onChange={e => setUserInput(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <SendOutlined onClick={sendMessage} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dialogueflow;
