import React from 'react';
import { Collapse } from 'antd';
const { Panel } = Collapse;

const FAQs = props => {
  const { type, count } = props;

  return (
    <div className="faq">
      <Collapse bordered={false} expandIconPosition="right" defaultActiveKey={['1']}>
        {type === 'expert'
          ? ExpertFaqs.slice(0, count).map(item => {
              return (
                <Panel header={item.question} key={item.id}>
                  <p>{item.ans}</p>
                </Panel>
              );
            })
          : ClientFaqs.slice(0, count).map(item => {
              return (
                <Panel header={item.question} key={item.id}>
                  <p>{item.ans}</p>
                </Panel>
              );
            })}
      </Collapse>
    </div>
  );
};

export default FAQs;

const ExpertFaqs = [
  {
    id: '1',
    question: 'What is Accountizer?',
    ans: (
      <ul>
        <li>
          We are an online marketplace platform dedicated to accounting services, connecting our clients with talented accounting, tax, and business advisory professionals. We are AI-powered, utilizing AI to help everyone make informed decisions when hiring accounting professionals, just like a seasoned expert. 
        </li>
      </ul>
    ),
  },
  {
    id: '3',
    question: 'Who can join Accountizer as an expert?',
    ans: (
      <ul>
        <li>
          All professionals in the accounting field, including CPAs, accountants, tax preparers,
          business advisors, and bookkeepers.
        </li>
      </ul>
    ),
  },
  {
    id: '4',
    question: 'Can accounting firms join Accountizer?',
    ans: (
      <ul>
        <li>
          Yes. Accountizer welcomes accounting firms of all sizes, offering services such as accounting, tax, business advice, and bookkeeping. 
        </li>
        <br />
        <li>
          Firms can also create individual accounts for their staff, with the option to consolidate earnings into the firm's bank account. 
        </li>
      </ul>
    ),
  },
  {
    id: '6',
    question: 'Why should I join Accountizer?',
    ans: (
      <ul>
        <li>
          Accountizer works with the best accounting talent in Canada. Joining the Accountizer community shows that you were vetted for your qualifications, skills, and knowledge, and helps build a level of trust with clients.
        </li>
        <br />
        <li>
          So, if you are in your early years of professional practice or getting started, we save you the massive time and effort needed to build your professional reputation from scratch.
        </li>
        <br />
        <li>
          Even if you have a respectable amount of experience, you still need to sustain your market share. And you will need to sustain it in a market with fierce online competition. We're helping you to build and bolster your online presence.
        </li>
      </ul>
    ),
  },
  {
    id: '7',
    question: 'What are the benefits of joining Accountizer as an accounting expert?',
    ans: (
      <ul>
        ✓ Gain extended market exposure.
        <br />
        <br />
        ✓ Attract new clients, and increase your market share.
        <br />
        <br />
        ✓ Save on marketing like social media ads, creating content, and SEO.
        <br /> 
        <br />
        ✓ You will be joining an extensive network of experienced accountants.
        <br />
        <br />
        ✓ Saving on your overhead costs, as your work may go entirely virtual.
        <br />
        <br />
        ✓ App-secured portals so you can safely communicate with your new clients.
        <br />
        <br />
        ✓ Save the time and cost of tracking and collecting receivables.
        <br />
        <br />
      </ul>
    ),
  },
  {
    id: '9',
    question: 'What client categories am I going to work with?',
    ans: (
      <ul>
        <li>
          Individuals
          <br />
          <br />
        </li>
        <li>
          Startups
          <br />
          <br />
        </li>
        <li>
          Small-sized businesses
          <br />
          <br />
        </li>
        <li>
          Medium-sized businesses
          <br />
          <br />
        </li>
        <li>
          Non-profit organizations
          <br />
          <br />
        </li>
      </ul>
    ),
  },
  {
    id: '10',
    question: 'Where are Accountizer clients located?',
    ans: (
      <ul>
        <li>
          For the time being, all our clients are in Canada. But we will extend globally in the
          future.
        </li>
      </ul>
    ),
  },
  {
    id: '11',
    question: 'Do I need to relocate?',
    ans: (
      <ul>
        <li>
          Accountizer is a fully remote platform- you can work from anywhere, as long as you are
          connected to the internet.
        </li>
      </ul>
    ),
  },
  {
    id: '12',
    question: 'How will I communicate with my clients?',
    ans: (
      <ul>
        <li>
          All communication with the client is made within the platform to ensure security. We've made all the required communication and work organization tools available.
        </li>
      </ul>
    ),
  },
  {
    id: '13',
    question: 'How does Accountizer identify my potential clients?',
    ans: (
      <ul>
        <li>
          Accountizer is built by accountants for accountants, featuring an industry-tailored proprietary algorithm. This advanced tool accurately assesses your capabilities and strengths related to tasks required by clients. It brilliantly connects you with clients who need your specific skills and expertise. 
        </li>
        <br />
        <li>
          Additionally, it auto-generates an evaluation report that appears to the client, explaining why you are the best fit to serve their needs and providing clear justifications for your selection.
        </li>
      </ul>
    ),
  },
  {
    id: '15',
    question: 'Who sets my service fees in Accountizer?',
    ans: (
      <ul>
        <li>You determine the fees for your professional services.</li>
      </ul>
    ),
  },
  {
    id: '16',
    question: 'How will I get paid?',
    ans: (
      <ul>
        <li>
          For one-time assignments, you'll earn the service fee when you finalize the work, get the client's consent, and mark the client assignment as a Closed Assignment.
        </li>
        <br />
        <li>
          For long-term subscriptions, you'll earn monthly at the end of each month until the client cancels the subscription plan.
        </li>
        <br />
        <li>
          Any amount you earn during a calendar month will be transferred to your bank account within the first ten days of the succeeding month.
        </li>
      </ul>
    ),
  },
  {
    id: '19',
    question: "Are there any costs associated with joining Accountizer as an accounting professional?",
    ans: (
      <ul>
        <li>
          Joining our platform as a professional is free. However, we retain a 10% platform fee from your earnings upon payout.
        </li>
      </ul>
    ),
  },
  {
    id: '22',
    question: "What is Accountizer's User Feedback Initiative (UFI) Program?",
    ans: (
      <ul>
        <li>
          This program is designed to gather user insights regarding their experiences with our service; participating members are expected to provide feedback and opinions on their service usage and will receive exclusive benefits for their contributions.
        </li>
      </ul>
    ),
  },
  {
    id: '23',
    question: 'How to join?',
    ans: (
      <ul>
        <li>
          <b>Joining is easy:</b> visit our experts' page and complete the online application, detailing your qualifications and experience. After review and acceptance, you'll receive a welcome email and can manage your work via your dashboard in our web app workplace.
        </li>
      </ul>
    ),
  },
  {
    id: '24',
    question: 'Is my information secured?',
    ans: (
      <ul>
        <li>
        All the information you provide us during the joining process or later is solely to have a sound understanding of qualifications, designations, experiences, and areas of knowledge to aptly match you with the perfect clients. We guarantee your information will not be used for any other purposes.
        </li>
      </ul>
    ),
  },
  {
    id: '25',
    question: 'What information is visible on my profile page?',
    ans: (
      <ul>
        <li>
          Clients will only be able to see your profile page, which contains the following: Your profile picture with the first letters of your first and last name until you have an agreement and start the engagement, at which point the client will be able to see your full name. 
        </li>
        <br />
        <li>
          Your qualifications and/or designations, province of residence, the languages that you speak, the services that you provide and their fee ranges, industry experience. Reviews from your previous clients will also be displayed.
        </li>
      </ul>
    ),
  },
  {
    id: '26',
    question: "How can I learn to use the software and navigate the dashboard?",
    ans: (
      <ul>
        <li>
          We offer comprehensive video tutorials on our YouTube channel.
        </li>
        <br />
        <li>
          If you have further questions or need personalized assistance, you can schedule a one-on-one meeting through our Book a Meeting page.
        </li>
      </ul>
    ),
  },
  {
    id: '27',
    question: "Can I charge clients a fee for answering their questions?",
    ans: (
      <ul>
        <li>
          You can't directly bill clients for answering their questions. However, providing helpful responses is a valuable way to showcase your expertise and build rapport, potentially leading to future paid work.
        </li>
      </ul>
    ),
  },
  {
    id: '28',
    question: "Can I issue invoices directly to clients?",
    ans: (
      <ul>
        <li>
          Invoices must be issued through the Accountizer platform to ensure the interests of both parties are protected. Private invoicing is not permitted.
        </li>
      </ul>
    ),
  },
  {
    id: '29',
    question: "What support is available in case of a dispute with a client?",
    ans: (
      <ul>
        <li>
          We're here to help. If a dispute arises between you and a client, please contact us through our contact page with the details. Your account manager will be in touch to provide support and facilitate a resolution.
        </li>
      </ul>
    ),
  },
  {
    id: '30',
    question: "Can I provide feedback about a client?",
    ans: (
      <ul>
        <li>
          Currently, our platform does not support direct feedback from professionals to clients. If you have any concerns regarding a client, please contact us through our contact page, and your account manager will assist you.
        </li>
      </ul>
    ),
  },
];

const ClientFaqs = [
  {
    id: '1',
    question: 'What is Accountizer?',
    ans: (
      <ul>
        <li>
          We are an online marketplace platform dedicated to accounting services, connecting our clients with talented accounting, tax, and business advisory professionals. We are AI-powered, utilizing AI to help everyone make informed decisions when hiring accounting professionals, just like a seasoned expert.
        </li>
      </ul>
    ),
  },
  {
    id: '3',
    question: 'Why should I use Accountizer?',
    ans: (
      <ul>
        <li>
          Accountizer seamlessly connects users with top financial professionals in Canada for a wide range of services, including tax, bookkeeping, accounting, business evaluation, payroll management, and more.
        </li>
      </ul>
    ),
  },
  {
    id: '5',
    question: 'What are the benefits of using Accountizer?',
    ans: (
      <>
          Accountizer offers several key advantages:
          <br />
          <br />
        <ul>
          <li>
            <b>Cost Savings:</b> Competitive pricing from highly qualified experts, optimized by our AI-explained, accounting industry-tailored matching algorithm.
          </li>
          <br />
          <li>
            <b>Time and effort saving:</b> Accountizer streamlines the entire process, from initial request to project completion, saving you valuable time and effort.
          </li>
          <br />
          <li>
            <b>Security & Guarantee:</b> Secure communication portals and transactions with a money-back guarantee for unsatisfactory service.
          </li>
          <br />
          <li>
            <b>Borderless Access:</b> Access to professionals remotely, around the clock, regardless of your location.
          </li>
          <br />
          <li>
            <b>Intelligent Matching: </b> Our proprietary algorithm, specifically designed for the accounting industry, works in conjunction with real-time, AI-generated expert evaluation reports to ensure you're matched with the best-fit expert for your needs.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: '6',
    question: 'What business categories does Accountizer serve?',
    ans: (
      <ul>
        <li>
          Accountizer serves a wide range of clients, including individuals, startups, small and medium-sized businesses, nonprofit organizations, and financial professionals such as advisors, executives, and other financial decision-makers.
        </li>
      </ul>
    ),
  },
  {
    id: '7',
    question: 'Where are Accountizer professionals located?',
    ans: (
      <ul>
        <li>
          Accountizer professionals are currently located in Canada, with plans for global expansion in the future.
        </li>
      </ul>
    ),
  },
  {
    id: '9',
    question: 'How do I communicate with my chosen professional?',
    ans: (
      <ul>
        <li>
          All communication with your chosen professional takes place within our secure platform. We provide all the necessary communication and project management tools to facilitate seamless collaboration and ensure the confidentiality of your information.
        </li>
      </ul>
    ),
  },
  {
    id: '10',
    question: 'How does Accountizer find the right financial professional for me?',
    ans: (
      <ul>
        <li>
          Accountizer is an AI-powered platform that uses an industry-tailored algorithm to analyze your accounting needs and match you with the best-fit professionals. It assists in brainstorming and drafting requests, diagnosing issues, and generating real-time reports explaining why each expert is recommended for you.
        </li>
      </ul>
    ),
  },
  {
    id: '15',
    question: 'How will I pay the experts?',
    ans: (
      <ul>
        <li>
          As the client and user, you will pay a one-time fee to Accountizer as escrow for the
          one-time assignments, and we will be responsible for paying the professional upon
          verification of service rendered at a satisfactory level as agreed.
        </li>
        <br />
        <li>
          For long-term subscriptions, you as a client and user will pay on a monthly basis to
          receive the service during the subscription period, and you may cancel the subscription at
          any time without obligation.
        </li>
        <br />
        <li>All your invoices will be automatically issued and archived in your dashboard.</li>
      </ul>
    ),
  },
  {
    id: '17',
    question: "What is the User Feedback Initiative (UFI) Program?",
    ans: (
      <ul>
        <li>
          Accountizer's User Feedback Initiative (UFI) Program offers users the opportunity to share their experiences with our service and receive exclusive benefits and privileges in return for their valuable feedback and opinions.
        </li>
      </ul>
    ),
  },
  {
    id: '18',
    question: 'Is my information and payment information secure?',
    ans: (
      <ul>
        <li>
          Yes, your information is secure. All data you provide is kept confidential and protected according to our privacy policy and terms and conditions. For enhanced security, we do not store your payment information; all payments are securely processed through Stripe, a trusted third-party payment provider.
        </li>
      </ul>
    ),
  },
  {
    id: '21',
    question: "How do I reach customer support?",
    ans: (
      <ul>
        <li>
          We're here to help! You can easily reach our support team by visiting our Contact Us page, where you can submit your questions. For direct inquiries, you can email us at{' '}
          <a style={{ textDecoration: 'underline' }} href="mailto:admin@accountizer.ca">
            admin@accountizer.ca
          </a>
          . For a more personalized experience, you can also book a virtual meeting via the "Client Book a Demo" option.
        </li>
      </ul>
    ),
  },
  {
    id: '22',
    question: "What makes Accountizer’s service more comprehensive than other platforms?",
    ans: (
      <ul>
        <li>
          Our service doesn't end with a match; we accompany you throughout your project to ensure a complete and remarkable experience. We help you find the ideal expert, regardless of your financial background, and provide direct access to experts for quick questions.
        </li>
      </ul>
    ),
  },
  {
    id: '23',
    question: "How does Accountizer ensure fair pricing and transparency?",
    ans: (
      <ul>
        <li>
          Accountizer ensures fair pricing and transparency by displaying each expert's fee range directly on their profile page. These predetermined service fees guarantee complete transparency and the best value in the market.
        </li>
      </ul>
    ),
  },
  {
    id: '23',
    question: "How does Accountizer ensure the quality of its experts?",
    ans: (
      <ul>
        <li>
          Our expert admission process is carefully designed to guarantee access to vetted and top-end professionals. We've brought together top talent in one place, offering a range of experts to suit all needs and budgets.
        </li>
      </ul>
    ),
  },
  {
    id: '24',
    question: "What additional features does Accountizer offer to enhance the user experience?",
    ans: (
      <ul>
        <li>
          We provide a live status tracker for project updates and a secure archive of completed jobs in your dashboard for future access. As a specialized platform for accounting and taxation, we are dedicated to being the best online accounting talent solution in Canada.
        </li>
      </ul>
    ),
  },
  {
    id: '25',
    question: "How do I access Accountizer's services?",
    ans: (
      <ul>
        <li>
          Accountizer operates entirely online. All services are delivered remotely, requiring only a stable internet connection, regardless of your location.
        </li>
      </ul>
    ),
  },
  {
    id: '26',
    question: "How does Accountizer help me find an accounting professional?",
    ans: (
      <ul>
          <li>
            <b>The Challenge:</b> Finding the right accounting professional can be difficult, especially without a financial background.
          </li>
          <br />
          <li>
            <b>Accountizer's Solution:</b> Our AI-powered platform simplifies this in two steps:
            <br />
            <br />
            <ul>
              <li>
                <b>Defining Your Needs:</b> Generative AI helps you define your needs and automatically creates a professional request.
              </li>
              <br />
              <li>
                <b>Intelligent Matching:</b> Our proprietary, accounting industry-tailored algorithm analyzes your request and generates real-time expert evaluation reports, explaining why each recommendation is the best match.
              </li>
              <br />
            </ul>
          </li>
          <br />
        </ul>
    ),
  },
  {
    id: '27',
    question: "I'd prefer to browse the experts. How do I do that?",
    ans: (
      <ul>
        <li>
          Want to browse our experts yourself? Simply press "Other Options" to explore our pool of pre-vetted professionals. The experts are listed based on how well they match your needs, and you can generate AI reports to help you choose the best fit.
        </li>
      </ul>
    ),
  },
  {
    id: '28',
    question: "How do you guarantee the quality of the professionals on your platform?",
    ans: (
      <ul>
        <li>
          Your peace of mind is our priority. Accountizer guarantees the quality of services through a thorough vetting process for all experts, ensuring they are highly qualified and experienced. We also track performance through customer ratings and reviews to maintain consistently high standards.
        </li>
        <br />
      </ul>
    ),
  },
  {
    id: '30',
    question: "How do I pay for services on Accountizer?",
    ans: (
      <ul>
        <li>
          We make payments easy and secure. For one-time projects, you pay Accountizer, and we ensure the expert is paid only after you're satisfied. For subscriptions, payments are monthly, with the flexibility to cancel anytime. All your invoices are conveniently stored in your dashboard.
        </li>
        <br />
      </ul>
    ),
  },
  {
    id: '31',
    question: "Can I rate the expert I worked with?",
    ans: (
      <ul>
        <li>
          Yes, we encourage you to rate and review your expert after the job is complete. Your feedback helps us maintain high standards and helps other users make informed decisions.
        </li>
        <br />
      </ul>
    ),
  },
  {
    id: '32',
    question: "What information will Accountizer never request from me?",
    ans: (
      <ul>
        <li>
          Accountizer takes your security very seriously. Please be aware of the following:
          <ul>
          <li>
            <b>We will NEVER ask for:</b> Your username, password, or full payment card details via messages, phone calls, or online chat.
          </li>
          <li>
            <b>If you receive a suspicious request:</b> Do not respond and report it to us immediately through our official contact channels.
          </li>
          </ul>
        </li>
        
      </ul>
    ),
  },
  {
    id: '33',
    question: "Are there any age restrictions for using Accountizer?",
    ans: (
      <ul>
        <li>
        <b>To use Accountizer, you must be 18 years of age or older.</b> We don't collect information from anyone under 18, and it's against our terms of service for them to register.
        </li>
        <br />
      </ul>
    ),
  }
];
