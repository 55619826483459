/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Input, message } from 'antd';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import OtpInput from 'react-otp-input';
import { useState } from 'react';
import { useEffect } from 'react';
import { getUData } from '../../../helpers/functions';
import { useNavigate } from 'react-router-dom';
const interval = 60000;

const VerifiedOTPModal = props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { visible, setVisible, registerEmail = '', pageType = '' } = props;
  const { isOtpLoading, optStatus, isOtpVerifyLoading, otpVerifyStatus } = useSelector(
    state => state.auth,
  );
  const [otp, setOtp] = useState('');
  const [isSendEnabled, setIsSendEnabled] = useState(false);
  const [remaingTime, setRemaingTime] = useState(60);
  const [toggleSend, setToggleSend] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      //assign interval to a variable to clear it.
      var remaining = localStorage.endTime - new Date();
      if (remaining >= 0) {
        setRemaingTime(Math.floor(remaining / 1000));
      } else {
        localStorage.removeItem('endTime');
        setIsSendEnabled(true);
      }
    }, 1000);

    return () => clearInterval(intervalId); //This is important
  }, [toggleSend]);

  useEffect(() => {
    if (!isOtpVerifyLoading && otpVerifyStatus === 'success') {
      dispatch({ type: 'CLEAR_LOGIN_DATA' });
      setVisible(false);
      message.success('Account verified successfully, logged in to continue');
      if (pageType !== '' && pageType === 'expert') {
        localStorage.setItem('redirectLink', `${window.location.origin}/expert/profile`);
        localStorage.setItem('expertFirstTimeLogin', true);
        navigate('/login?type=expert');
      } else {
        navigate('/login');
      }
    } else if (!isOtpVerifyLoading && otpVerifyStatus === 'invalid') {
      dispatch({ type: 'CLEAR_OTP_MESSAGE' });
      message.error({
        content:
          'OTP is invalid or expired, please enter code again or resend send OTP' ||
          'Something went wrong',
        className: 'error-class',
        duration: 5,
      });
    }
  }, [dispatch, isOtpVerifyLoading, otpVerifyStatus]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then(values => {
        let tmpValues = {};
        tmpValues.token = otp;
        dispatch({ type: 'OTP_VERIFY_REQUEST', formData: tmpValues });
      })
      .catch(errorInfo => {});
  };

  const resendOTP = () => {
    // startTimer();
    if (!localStorage.endTime) {
      localStorage.endTime = +new Date() + interval;
    }
    setToggleSend(!toggleSend);
    setIsSendEnabled(false);
    dispatch({
      type: 'OTP_REQUEST',
      formData: { email: registerEmail === '' ? getUData().email : registerEmail },
    });
  };

  useEffect(() => {
    if (!isOtpLoading && optStatus === 'success') {
      // startTimer();
      dispatch({ type: 'CLEAR_INVITE' });
      message.success('OTP has been sent to your email address');
    }
  }, [isOtpLoading, optStatus]);

  return (
    <Modal
      className={`prize-modal org-modal otp-modal`}
      open={visible}
      onOk={() => handleSubmit()}
      onCancel={() => {
        dispatch({ type: 'CLEAR_COMMON' });
        setVisible(false);
        form.resetFields();
      }}
      centered
      width={650}
      okText={`Verify`}
      okButtonProps={{
        disabled: otp === '' || otp.length !== 6 || isOtpVerifyLoading,
        loading: isOtpVerifyLoading,
      }}
      cancelText="Cancel"
      maskClosable={false}
    >
      <div className="mt5 mb5 pr5 pl5">
        <p className="bold fs18">Email Verification</p>
        <p>{`You should have received an email message with a 6 digit code at ${registerEmail === '' ? getUData().email : registerEmail}`}</p>
        <div className="otp-section">
          <OtpInput
            onChange={otp => setOtp(otp)}
            numInputs={6}
            value={otp}
            shouldAutoFocus={true}
            renderSeparator={<span>&nbsp;-&nbsp;</span>}
            renderInput={props => <input {...props} />}
          />
        </div>
        <div className="mt15">
          {!isSendEnabled && remaingTime !== 60 && (
            <span className="">
              {remaingTime === 0 ? 'Resend OTP' : `Resend OTP in ${remaingTime} seconds`}
            </span>
          )}
          {isSendEnabled && (
            <span className="link pointer" onClick={() => resendOTP()}>
              Resend OTP
            </span>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default VerifiedOTPModal;
