import React, { useEffect, useRef, useState } from 'react';
import attachment from '../../assets/images/attachment.svg';
import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  SendOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { Button, message, Tooltip, Upload } from 'antd';
import LeftMessage from './leftMessageCard';
import RightMessage from './rightMessagingCard';
import MainLogo from '../../assets/images/Acc_logo/logo_blue.svg';

import {
  checkImageSize,
  convertDateToTimezone,
  dynamicMessagePayloadWithAttachment,
  filetype,
  getFileNameFromUrl,
  getFullName,
  getUserData,
  isDocsType,
  isExcelType,
  isImageType,
  isPdfType,
  isValidMimeType,
  placeholderBasedOnFileType,
  role,
} from '../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import moment from 'moment';
import {
  GET_FILE_URL,
  GTE_BASE64_FILE,
  pusher,
  ROLE_NAME,
  ZOOM_MEETING_SDK_KEY,
} from '../../helpers/constant';
import ZoomMeeting from './ZoomMeeting';
import AddMeetingTime from './modal/addMeetingTime';
// import ZoomMtgEmbedded from '@zoom/meetingsdk/embedded';
import { usePusher } from './PusherContext';
import DailyIframe from '@daily-co/daily-js';
import { useParams } from 'react-router-dom';
import ImagePreviewModal from './modal/ImagePreviewModal';
import { postRequest } from '../../helpers/apiCall';
import Loader from './Loader';
export const MessagingContainer = props => {
  // ---------------------//
  // create zoom meeting client
  const client = '';
  let meetingSDKElement = document.getElementById('meetingSDKElement');
  const messageInputRef = useRef();
  const callFrameRef = useRef(null);
  const [callFrame, setCallFrame] = useState(null);
  const [isCallActive, setIsCallActive] = useState(true);
  const message_area_ref = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImageFile, setSelectedImageFile] = useState('');
  const [selectedFileType, setSelectedFileType] = useState('message');
  const [showZoomMeeting, setShowZoomMeeting] = useState(false);
  const { docURL, isDocUploadLoading } = useSelector(state => state.common);
  const {
    threadBySubjectId,
    isCreateNewThreadLoading,
    isImageUploadingToDBLoading,
    isPostMessageLoading,
    imageUrlFromDB,
    zoomMeetingSignature,
    zommMeetingSignatureLoading,
    isAddNewMessageInThread,
    addedMessagesLastIndexId,
  } = useSelector(state => state.requests);
  const expertData = useSelector(state => state?.common?.questionDetail);
  const userData = getUserData();
  const dispatch = useDispatch();
  const width =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const [isUserThreadAlreadyExists, setIsUserThreadAlreadyExists] = useState(true);
  const [isUserSendingItsFirstMessage, setIsUserSendingItsFirstMessage] = useState(false);
  const messagesEndRef = useRef(null);
  const messageContainerRef = useRef(null);
  const [isLoadPrevMessage, setIsLoadPrevMessage] = useState(false);
  const [meetingBackButtonVisible, setMeetingBackButtonVisible] = useState(false);
  const [clickedMessagData, setClickedMessagData] = useState({});
  const [isModal, setIsModal] = useState(false);
  const [newMessageLoad, setMessageLoad] = useState(false);
  const { channel } = usePusher();
  const params = useParams();

  useEffect(() => {
    // Ensure container is in view when clicking the messaging button
    messageContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
    messageInputRef?.current?.focus();
    const message_end_section = document.getElementById('message_end_section');
    if (message_end_section && !isAddNewMessageInThread) {
      message_end_section.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    setMessageLoad(false);
  }, [
    threadBySubjectId?.messages?.length,
    isPostMessageLoading,
    messagesEndRef.current,
    isLoading,
    messageInputRef?.current?.value,
  ]);

  useEffect(() => {
    if (isAddNewMessageInThread) {
      setIsLoadPrevMessage(false);
      dispatch({ type: 'ADD_NEW_MESSAGE_IN_THREAD_ERROR' });
    }
    if (isLoadPrevMessage && addedMessagesLastIndexId) {
      const messageContainerRight = document.querySelector(
        '.message-container-Right[data-indexkey="' + addedMessagesLastIndexId + '"]',
      );
      const messageContainer = document.querySelector(
        '.message-container[data-indexkey="' + addedMessagesLastIndexId + '"]',
      );
      if (messageContainer) {
        messageContainer.scrollIntoView({ block: 'start' });
      } else if (messageContainerRight) {
        messageContainerRight.scrollIntoView({ block: 'start' });
      }
    }
  }, [isAddNewMessageInThread]); //use effct for while add new messaeg or fetch prev meessage fired  //

  const createNewThreadUsingQid = () => {
    if (props.subjectId && threadBySubjectId?.error === 'Permission Denied') {
      setIsUserSendingItsFirstMessage(true);
    }
  };

  useEffect(() => {
    if (props.subjectId && isUserThreadAlreadyExists) {
      dispatch({ type: 'GET_THREAD_BY_SUBJECT_ID', id: props.subjectId });
      setIsUserThreadAlreadyExists(false);
    }
    if (props.subjectId && threadBySubjectId?.messages && threadBySubjectId?.messages?.length > 0) {
      dispatch({
        type: 'MARK_ALL_MESSAGES_AS_READ',
        formData: { thread_id: threadBySubjectId?.messages?.[0]?.thread_id },
      });
    }
  }, [threadBySubjectId?.messages?.length, isUserSendingItsFirstMessage, isCreateNewThreadLoading]);

  useEffect(() => {
    if (!threadBySubjectId?.messages?.length && isUserThreadAlreadyExists) {
      createNewThreadUsingQid();
    }
  }, [threadBySubjectId, isLoading, isUserSendingItsFirstMessage, isCreateNewThreadLoading]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [
    props.subjectId,
    props.selectedTabGroup,
    isLoading,
    isCreateNewThreadLoading,
    isUserSendingItsFirstMessage,
  ]);

  useEffect(() => {
    if (zoomMeetingSignature && zommMeetingSignatureLoading) {
      startMeeting(zoomMeetingSignature);
      dispatch({ type: 'ZOOM_MEETING_SIGNATURE_EMPTY' });
    }
  }, [zoomMeetingSignature]);

  useEffect(() => {
    if (!threadBySubjectId?.id && !isUserThreadAlreadyExists) {
      createNewThread();
    }
  }, [threadBySubjectId]);

  const handleImageUpload = info => {
    const fileList = info.fileList;
    let error = false;
    for (let i = 0; i < fileList.length; i++) {
      if (error) {
        break;
      }
      if (!isValidMimeType(fileList[i])) {
        message.error(fileList[i].name + ' file type is not valid');
        error = true;
      }

      if (!checkImageSize(fileList[i])) {
        message.error(fileList[i].name + ' file size is not valid');
        error = true;
      }
    }

    if (error) {
      return;
    }
    const uniqueArr = [...new Map(fileList.map(item => [item.name, item])).values()];
    setSelectedImageFile(uniqueArr);
    setSelectedFileType(info.file.type);
    return;
  };

  useEffect(() => {
    if (!isDocUploadLoading && docURL && docURL.length > 0) {
      let messagePayload = [];
      docURL.map((url, index) => {
        const requiredData = dynamicMessagePayloadWithAttachment(
          filetype(url.substring(url.lastIndexOf('.')).replace('.', '')),
          url,
          url,
        );

        messagePayload.push({
          path: requiredData?.message || url,
          attachment_type: requiredData?.attachment_type || 'image',
          message_type: requiredData?.attachment_type || 'image',
        });
      });

      if (messagePayload.length > 0) {
        dispatch({
          type: 'POST_MESSAGE',
          formData: {
            id: threadBySubjectId?.id,
            message: messagePayload,
            has_attachment: 1,
            attachment_type: 'Files',
            type: 'Multiple_File_Upload',
          },
        });

        setMessageLoad(true);
        setSelectedImageFile('');
        setSelectedFileType('message');
        dispatch({ type: 'UPLOAD_MULTI_FILE_ERROR' });
      }
    }
  }, [docURL, isDocUploadLoading]);

  /**
   * cheackFileType function return type of file based on the file type it
   * receive. It return one of the following: 'image', 'pdf', 'excel', 'docs'
   * or 'message' if the filetype is not recognized.
   * @param {string} fileType
   * @return {string}
   */
  const cheackFileType = fileType => {
    if (isImageType(fileType)) {
      return 'image';
    } else if (isPdfType(fileType)) {
      return 'pdf';
    } else if (isExcelType(fileType)) {
      return 'excel';
    } else if (isDocsType(fileType)) {
      return 'docs';
    } else {
      return 'image';
    }
  };

  const createNewThread = () => {
    dispatch({
      type: 'POST_NEW_MESSAGE_THREAD',
      formData: {
        subject: props?.subjectId,
        message: '',
        recipients: props?.expertId,
      },
    });
    setIsUserSendingItsFirstMessage(!isUserSendingItsFirstMessage);
    setIsUserThreadAlreadyExists(!isUserThreadAlreadyExists);
  };
  const onMessageSend = e => {
    if (isUserThreadAlreadyExists || !threadBySubjectId?.error) {
      if (showZoomMeeting) {
        dispatch({
          type: 'POST_ZOOM_MEETING',
          formData1: {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            topic: expertData?.category?.name,
            agenda: `${getFullName()} and ${expertData?.expert?.first_name} ${expertData?.expert?.last_name}`,
            party_a_creator: userData?.id,
            party_a_creator_name: userData?.first_name + ' ' + userData?.last_name,
            party_b: expertData?.expert_id,
            party_b_name: expertData.expert.first_name + ' ' + expertData.expert.last_name,
            message: messageInputRef.current.value,
            id: threadBySubjectId?.id,
          },
        });
        messageInputRef.current.value = '';
        setSelectedImageFile('');
        setShowZoomMeeting(false);
      } else if (selectedFileType !== 'message' && !messageInputRef?.current?.value) {
        // upload image first in database
        const formData = new FormData();
        selectedImageFile.map(file => {
          formData.append('files[]', file.originFileObj);
        });
        formData.append('path', params?.qid);

        formData.append('modeOfFile', 'private');

        dispatch({ type: 'UPLOAD_MULTIPLE_PRIVATE_FILE', formData: formData });

        setSelectedFileType('message');

        setSelectedImageFile('');
      } else if (
        selectedFileType === 'message' &&
        messageInputRef?.current?.value !== '' &&
        messageInputRef?.current?.value !== ' '
      ) {
        dispatch({
          type: 'POST_MESSAGE',
          formData: {
            id: threadBySubjectId?.id,
            message: messageInputRef.current.value,
            has_attachment: 0,
          },
        });
        messageInputRef.current.value = '';
        setSelectedImageFile('');
      }

      setMessageLoad(true);
    }
  };

  function format(val) {
    return moment(val).format('YYYYMMDD') !== moment().format('YYYYMMDD')
      ? moment(val).format('DD MMMM')
      : 'Today';
  }

  async function startMeeting({ meetingNumber, passWord, role, signature }) {
    try {
      await client.init({
        zoomAppRoot: meetingSDKElement,
        language: 'en-US',
        patchJsMedia: true,
        customize: {
          video: {
            isResizable: true,
            viewSizes: {
              default: {
                width: 1024,
                height: 650,
              },
              ribbon: {
                width: 316,
                height: 720,
              },
            },
          },
        },
      });
      await client.join({
        sdkKey: ZOOM_MEETING_SDK_KEY,
        signature: signature,
        meetingNumber: meetingNumber,
        password: passWord,
        userName: userData?.first_name + ' ' + userData?.last_name,
      });
    } catch (err) {
      message.error('Something went wrong while joining the meeting !');
    }
  }

  const handleMeetingFrame = data => {
    const createCallFrame = async () => {
      callFrameRef.current = DailyIframe.createFrame({
        iframeStyle: {
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          border: '0',
          backgroundColor: 'black',
          zIndex: 1000,
        },
        showLeaveButton: true,
      });

      callFrameRef.current.join({
        url: data?.data?.meeting_join_url,
        userName: userData?.first_name + ' ' + userData?.last_name,
      });
      setCallFrame(callFrameRef.current);
      callFrameRef.current.on('left-meeting', () => {
        callFrameRef.current.destroy();
        setCallFrame(null);
        setMeetingBackButtonVisible(false);
      });
      callFrameRef.current.on('joined-meeting', () => {
        setMeetingBackButtonVisible(true);
      });
    };

    if (isCallActive) {
      createCallFrame();
    }
  };

  // hanlde message on click method here
  const handleMessageClick = async (type, data, m) => {
    setClickedMessagData(m);
    switch (type) {
      case 'JOIN_MEETING':
        handleMeetingFrame(data);
        break;
      case 'image':
        const url = await handleAttachmentClick(m?.id);
        setImgsrc(url);
        setIsModal(true);
        break;
      case 'docs':
        const a = document.createElement('a');
        a.href = await handleAttachmentClick(m?.id);
        a.download = m?.body.substring(m?.body.lastIndexOf('/') + 1);
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        break;
      case 'pdf':
        const a1 = document.createElement('a');
        a1.href = await handleAttachmentClick(m?.id);
        a1.download = m?.body.substring(m?.body.lastIndexOf('/') + 1);
        a1.target = '_blank';
        document.body.appendChild(a1);
        a1.click();
        document.body.removeChild(a1);

        break;
      case 'excel':
        const a2 = document.createElement('a');
        a2.href = await handleAttachmentClick(m?.id);
        a2.download = m?.body.substring(m?.body.lastIndexOf('/') + 1);
        a2.target = '_blank';
        document.body.appendChild(a2);
        a2.click();
        document.body.removeChild(a2);
        break;
      default:
        break;
    }
  };

  // pusher implemenation here
  useEffect(() => {
    if (threadBySubjectId?.id) {
      const handlePusherMessageEvent = data => {
        dispatch({ type: 'ADD_NEW_MESSAGE_IN_THREAD', data: data.message });
      };
      channel.bind(threadBySubjectId?.id, handlePusherMessageEvent);
      return () => {
        channel.unbind(threadBySubjectId?.id, handlePusherMessageEvent);
      };
    }
  }, [threadBySubjectId]);

  // scroll to top for prev message
  useEffect(() => {
    if (message_area_ref?.current) {
      function scrollToTop() {
        const scrollPosition = message_area_ref.current.scrollTop;
        if (scrollPosition === 0) {
          let lastMessageContainer = message_area_ref.current?.querySelectorAll(
            '.message-container, .message-container-Right',
          );
          if (lastMessageContainer && lastMessageContainer.length) {
            let firstMessageDisplayId = lastMessageContainer[0].getAttribute('data-indexkey');
            setIsLoadPrevMessage(true);
            dispatch({
              type: 'GET_THREAD_BY_SUBJECT_ID',
              id: props.subjectId,
              firstMessageDisplayId,
            });
          }
        } else {
          setIsLoadPrevMessage(false);
        }
      }
      message_area_ref.current.addEventListener('scroll', scrollToTop);
      return () => {
        message_area_ref.current?.removeEventListener('scroll', scrollToTop);
      };
    }
  }, [threadBySubjectId?.messages, isLoading]);

  const [imgsrc, setImgsrc] = useState('');

  async function handleAttachmentClick(id) {
    try {
      const resp = await postRequest(GET_FILE_URL, {
        messageId: id,
      });
      if (resp?.status === 200) {
        return resp.data.url;
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleDownloadImage = async id => {
    try {
      if (!id) return;
      const resp = await postRequest(GTE_BASE64_FILE, {
        messageId: id,
      });
      if (resp?.status === 200) {
        const link = document.createElement('a');
        link.href = resp.data.url;
        link.download = resp.data.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      message.error('something went wrong please try again.');
    }
  };

  return (
    <div ref={messageContainerRef} className="message-body" id="message-body">
      {isModal && (
        <ImagePreviewModal
          isModal={isModal}
          setIsModal={setIsModal}
          imgsrc={imgsrc}
          handleDownload={handleDownloadImage}
          data={clickedMessagData}
          setdata={setClickedMessagData}
        />
      )}

      {callFrame && (
        <div
          style={{
            backgroundColor: 'white',
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100vw',
            zIndex: 10000,
            height: '3.3vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '5px 30px',
          }}
        >
          <div>
            <Button
              style={{
                color: '#1c64f2',
                backgroundColor: 'white',
                paddingLeft: '30px',
                paddingRight: '30px',
                border: 'none',
              }}
              htmlType="button"
              className=""
              size="small"
              onClick={() => callFrameRef?.current?.destroy()}
              hidden={meetingBackButtonVisible}
            >
              <ArrowLeftOutlined /> Back
            </Button>
          </div>

          <img src={MainLogo} alt="main logo" loading="lazy" height={'20rem'} />
          <span></span>
        </div>
      )}

      {props?.subjectId || isLoading ? (
        <div className="request-message-wrapper" style={{ position: 'relative' }}>
          {isLoading ? (
            <div
              style={{
                width: props.type === 'newDetail' ? (width * 80) / 100 : (width * 65) / 100,
                height: (height * 55) / 100,
              }}
              className="d-flex-a-c"
            >
              <Spin />
            </div>
          ) : threadBySubjectId?.messages?.length ? (
            <div className="request-message-details " style={{ position: 'relative' }}>
              {showZoomMeeting && (
                <ZoomMeeting
                  setShowZoomMeeting={setShowZoomMeeting}
                  isFullnameDisplay={props?.statusOrder >= 7}
                />
              )}
              {callFrame && (
                <div
                  style={{
                    backgroundColor: 'white',
                    position: 'absolute',
                    top: '-47%',
                    left: '63%',

                    padding: '0rem 1rem 0rem 1rem',
                    display: 'flex',
                    zIndex: 100000,
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '3.2vh',
                  }}
                >
                  <img loading="lazy" src={MainLogo} alt="main logo" height={'20rem'} />
                </div>
              )}
              {isLoadPrevMessage && <Spin />}
              <div
                style={{
                  height: '550px',
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'auto',
                  position: 'relative',
                }}
                className="message-area"
                id="message-area"
                ref={message_area_ref}
              >
                {threadBySubjectId?.messages
                  ?.sort((a, b) => a.id - b.id)
                  ?.map((m, i) => {
                    const prevMessage = threadBySubjectId?.messages[i - 1];
                    const prevMessageTimeStamp = convertDateToTimezone(prevMessage?.created_at);
                    const currentMessageTimeStamp = convertDateToTimezone(m?.created_at);
                    if (getUserData().id === m?.user_id) {
                      if (prevMessageTimeStamp !== currentMessageTimeStamp) {
                        return (
                          <>
                            {convertDateToTimezone(m?.created_at) && (
                              <div className="date">
                                <div className="message-container-date-box">
                                  <p className="message-container-date-box-p">
                                    {convertDateToTimezone(m?.created_at)}
                                  </p>
                                </div>
                              </div>
                            )}

                            <RightMessage
                              m={m || {}}
                              handleMessageClick={handleMessageClick}
                              params={params}
                            />
                          </>
                        );
                      } else {
                        return (
                          <>
                            <RightMessage
                              m={m || {}}
                              handleMessageClick={handleMessageClick}
                              params={params}
                            />
                          </>
                        );
                      }
                    }
                    if (prevMessageTimeStamp !== currentMessageTimeStamp) {
                      return (
                        <>
                          {convertDateToTimezone(m?.created_at) && (
                            <div className="date">
                              <div className="message-container-date-box">
                                <p className="message-container-date-box-p">
                                  {convertDateToTimezone(m?.created_at)}
                                </p>
                              </div>
                            </div>
                          )}

                          <LeftMessage
                            m={m || {}}
                            type="container"
                            selectedThreadData={
                              role() === ROLE_NAME.CUSTOMER
                                ? threadBySubjectId?.participants?.filter(
                                    data => data.id === getUserData().id,
                                  )
                                : threadBySubjectId?.participants?.filter(
                                    data => data.id !== getUserData().id,
                                  )
                            }
                            handleMessageClick={handleMessageClick}
                          />
                        </>
                      );
                    } else {
                      return (
                        <>
                          <LeftMessage
                            m={m || {}}
                            type="container"
                            selectedThreadData={
                              role() === ROLE_NAME.CUSTOMER
                                ? threadBySubjectId?.participants?.filter(
                                    data => data.id !== getUserData().id,
                                  )
                                : threadBySubjectId?.participants?.filter(
                                    data => data.id !== getUserData().id,
                                  )
                            }
                            handleMessageClick={handleMessageClick}
                          />
                        </>
                      );
                    }
                  })}
                <div id="message_end_section" ref={messagesEndRef} />
              </div>
              <div className="text-box">
                <div className="icon-write">
                  {threadBySubjectId?.messages?.length ? (
                    <Tooltip
                      title="You can upload up to 10 files and each file should be less than 5MB."
                      color="rgb(24, 119, 242)"
                    >
                      <div className="icon cursor-pointer">
                        <Upload
                          name="attachment"
                          accept="image/*,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                          onChange={handleImageUpload}
                          showUploadList={false}
                          maxCount={10}
                          multiple={true}
                          fileList={selectedImageFile}
                          customRequest={({ file, onSuccess }) =>
                            setTimeout(() => onSuccess('ok'), 0)
                          }
                        >
                          <img src={attachment} alt="upload image" loading="lazy" />
                        </Upload>
                      </div>
                    </Tooltip>
                  ) : null}
                  <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                    {selectedImageFile && selectedImageFile?.length > 0 ? (
                      selectedImageFile.map((item, index) => {
                        return (
                          <div
                            style={{
                              backgroundColor: '#eef1f4',
                              gap: '15px',
                              width: 'max-content',
                              padding: '10px 5px',
                              borderRadius: '8px',
                              alignItems: 'center',
                            }}
                            className="d-flex"
                            key={index}
                          >
                            <div>
                              <img
                                height={25}
                                width={25}
                                src={placeholderBasedOnFileType(cheackFileType(item?.type || ''))}
                                alt="file"
                                loading="lazy"
                              />
                            </div>
                            <div>{getFileNameFromUrl(item?.name, 5)}</div>
                            <Button
                              style={{ border: 'none', backgroundColor: 'transparent' }}
                              icon={<CloseCircleOutlined />}
                              size="small"
                              onClick={() => {
                                setSelectedImageFile(() =>
                                  selectedImageFile?.filter(file => file?.uid !== item?.uid),
                                );
                              }}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <>
                        <input
                          autoFocus={true}
                          ref={messageInputRef}
                          required
                          className="write"
                          type="text"
                          placeholder="Write a message..."
                          onKeyDown={e => {
                            if (e.key === 'Enter') {
                              onMessageSend(e);
                            }
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="videosend"
                  onClick={() => {
              
                    setShowZoomMeeting(true);
                  }}
                >
                  <VideoCameraOutlined />
                </div>
                <AddMeetingTime threadBySubjectId={threadBySubjectId} />
                <div
                  onClick={e => onMessageSend(e)}
                  loading={isImageUploadingToDBLoading}
                  className="videosend"
                >
                  <SendOutlined />
                </div>
              </div>
            </div>
          ) : (
            <div className="request-message-details">
               {showZoomMeeting && (
                <ZoomMeeting
                  setShowZoomMeeting={setShowZoomMeeting}
                  isFullnameDisplay={props?.statusOrder >= 7}
                />
              )}
              {callFrame && (
                <div
                  style={{
                    backgroundColor: 'white',
                    position: 'absolute',
                    top: '-47%',
                    left: '63%',

                    padding: '0rem 1rem 0rem 1rem',
                    display: 'flex',
                    zIndex: 100000,
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '3.2vh',
                  }}
                >
                  <img loading="lazy" src={MainLogo} alt="main logo" height={'20rem'} />
                </div>
              )}
              <div
                style={{
                  height: '550px',
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'auto',
                }}
                className="d-flex-a-c message-area "
              >
                Please don’t hesitate to contact me if you have any questions.
              </div>
              <div className="text-box">
                <div className="icon-write">
                  <Tooltip
                    title="You can upload up to 10 files and each file should be less than 5MB."
                    color="rgb(24, 119, 242)"
                  >
                    <Upload
                      name="attachment"
                      accept="image/*,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                      onChange={handleImageUpload}
                      showUploadList={false}
                      maxCount={10}
                      multiple={true}
                      fileList={selectedImageFile}
                      customRequest={({ file, onSuccess }) => setTimeout(() => onSuccess('ok'), 0)}
                    >
                      <img src={attachment} alt="upload file" loading="lazy" />
                    </Upload>
                  </Tooltip>
                  <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                    {selectedImageFile && selectedImageFile?.length > 0 ? (
                      selectedImageFile.map((item, index) => {
                        return (
                          <div
                            style={{
                              backgroundColor: '#eef1f4',
                              gap: '15px',
                              width: 'max-content',
                              padding: '10px 5px',
                              borderRadius: '8px',
                              alignItems: 'center',
                            }}
                            className="d-flex"
                            key={index}
                          >
                            <div>
                              <img
                                height={25}
                                width={25}
                                src={placeholderBasedOnFileType(cheackFileType(item?.type || ''))}
                                alt="file"
                                loading="lazy"
                              />
                            </div>
                            <div>{getFileNameFromUrl(item?.name, 5)}</div>
                            <Button
                              style={{ border: 'none', backgroundColor: 'transparent' }}
                              icon={<CloseCircleOutlined />}
                              size="small"
                              onClick={() => {
                                setSelectedImageFile(() =>
                                  selectedImageFile?.filter(file => file?.uid !== item?.uid),
                                );
                              }}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <>
                        <input
                          autoFocus={true}
                          ref={messageInputRef}
                          required
                          className="write"
                          type="text"
                          placeholder="Write a message..."
                          onKeyDown={e => {
                            if (e.key === 'Enter') {
                              onMessageSend(e);
                            }
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="videosend"
                  onClick={() => {
                    console.log('clicked');
                    setShowZoomMeeting(true);
                  }}
                >
                  <VideoCameraOutlined />
                </div>
                <AddMeetingTime threadBySubjectId={threadBySubjectId} />
                <div
                  onClick={e => onMessageSend(e)}
                  loading={isImageUploadingToDBLoading}
                  className="videosend"
                >
                  <SendOutlined />
                </div>
              </div>
            </div>
          )}
          {newMessageLoad && (
            <Spin
              style={{
                position: 'absolute',
                top: '80%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          )}
        </div>
      ) : props?.type === 'detail' ? (
        <div
          style={{
            width: (width * 55) / 100,
            height: (height * 80) / 100,
            backgroundColor: 'white',
          }}
          className="d-flex-a-c"
        >
          <Spin />
        </div>
      ) : (
        <div
          style={{
            width: (width * 55) / 100,
            height: (height * 80) / 100,
            backgroundColor: 'white',
          }}
          className="d-flex-a-c"
        >
          Please select one of the chatroom.
        </div>
      )}
    </div>
  );
};
