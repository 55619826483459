import { notification } from "antd";
import { getUserData } from "../../helpers/functions";

const initialState = {
  isReqLoading: false,
  resStatus: '',

  isQTLoading: true,
  questionTypes: [],

  isCTLoading: false,
  customerTypes: [],

  isSCLoading: false,
  serviceCategoryList: [],

  isSTLoading: false,
  serviceTypeList: [],

  isSLLoading: false,
  seniorityLevelList: [],
  isLocationLoading: false,
  locationList: [],

  isOTLoading: false,
  ownershipTypeList: [],

  isBTLoading: false,
  businessTypeList: [],

  isBSLoading: false,
  businessTypeCategoryList: [],

  isQuestionLoading: false,
  questionDetail: {},

  isInvoiceLoading: false,
  invoiceList: [],

  isLangLoading: false,
  languageList: [],

  isSubCateLoading: false,
  subCategoryList: [],

  isIndustryLoading: false,
  industryList: [],

  isCouponValidatingLoading: false,
  validCouponResponse: [],

  isExpertRequestOrRejectRequestLoading: false,
  expertRequestOrRejectRequestResponse: [],

  timeZoneList: [],

  isDontShowWhatsNext: false,
  dontShowWhatsNextResponse: [],
  notifications: [],
  currentNotificationPage: 0,
  newNotificationCount: 0,
  readNotification: false,
  Blogs: [],
  isBlogsLoading: true,
  isBlogLoading: true,
  singleBlog: {},
  isCreateBlogLoading: true,
  isDeleteBlogLoading: true,
  isEditBlogLoading: true,
  isCommonUpdateLoading: false,
  commonMsg: '',
};

const AuthReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    /*------------ */
    case 'POST_COMMON_REQUEST':
      state = {
        ...state,
        isReqLoading: true,
        resStatus: '',
      };
      break;

    case 'POST_COMMON_SUCCESS':
      state = {
        ...state,
        isReqLoading: false,
        resStatus: 'success',
      };
      break;

    case 'POST_COMMON_ERROR':
      state = {
        ...state,
        isReqLoading: false,
        resStatus: '',
      };
      break;

    /*------------ */
    case 'GET_QUESTION_TYPE_REQUEST':
      state = {
        ...state,
        isQTLoading: true,
        questionTypes: [],
      };
      break;

    case 'GET_QUESTION_TYPE_SUCCESS':
      state = {
        ...state,
        isQTLoading: false,
        questionTypes: action.data,
      };
      break;

    case 'GET_QUESTION_TYPE_ERROR':
      state = {
        ...state,
        isQTLoading: false,
        questionTypes: [],
      };
      break;

    /*------------ */
    case 'GET_CUSTOMER_TYPE_REQUEST':
      state = {
        ...state,
        isCTLoading: true,
        customerTypes: [],
      };
      break;

    case 'GET_CUSTOMER_TYPE_SUCCESS':
      state = {
        ...state,
        isCTLoading: false,
        customerTypes: action.data,
      };
      break;

    case 'GET_CUSTOMER_TYPE_ERROR':
      state = {
        ...state,
        isCTLoading: false,
        customerTypes: [],
      };
      break;

    /*------------ */
    case 'GET_SERVICE_CATEGORY_REQUEST':
      state = {
        ...state,
        isSCLoading: true,
        serviceCategoryList: [],
      };
      break;

    case 'GET_SERVICE_CATEGORY_SUCCESS':
      state = {
        ...state,
        isSCLoading: false,
        serviceCategoryList: action.data,
      };
      break;

    case 'GET_SERVICE_CATEGORY_ERROR':
      state = {
        ...state,
        isSCLoading: false,
        serviceCategoryList: [],
      };
      break;

    /*------------ */
    case 'GET_SUB_CATEGORY_REQUEST':
      state = {
        ...state,
        isSubCateLoading: true,
        subCategoryList: [],
      };
      break;

    case 'GET_SUB_CATEGORY_SUCCESS':
      state = {
        ...state,
        isSubCateLoading: false,
        subCategoryList: action.data,
      };
      break;

    case 'GET_SUB_CATEGORY_ERROR':
      state = {
        ...state,
        isSubCateLoading: false,
        subCategoryList: [],
      };
      break;

    /*------------ */
    case 'GET_SERVICE_TYPE_REQUEST':
      state = {
        ...state,
        isSTLoading: true,
        serviceTypeList: [],
      };
      break;

    case 'GET_SERVICE_TYPE_SUCCESS':
      state = {
        ...state,
        isSTLoading: false,
        serviceTypeList: action.data,
      };
      break;

    case 'GET_SERVICE_TYPE_ERROR':
      state = {
        ...state,
        isSTLoading: false,
        serviceTypeList: [],
      };
      break;

    /*------------ */
    case 'GET_LOCATION_REQUEST':
      state = {
        ...state,
        isLocationLoading: true,
        locationList: [],
      };
      break;

    case 'GET_LOCATION_SUCCESS':
      state = {
        ...state,
        isLocationLoading: false,
        locationList: action.data,
      };
      break;

    case 'GET_LOCATION_ERROR':
      state = {
        ...state,
        isLocationLoading: false,
        locationList: [],
      };
      break;

    /*------------ */
    case 'GET_LANGUAGE_REQUEST':
      state = {
        ...state,
        isLangLoading: true,
        languageList: [],
      };
      break;

    case 'GET_LANGUAGE_SUCCESS':
      state = {
        ...state,
        isLangLoading: false,
        languageList: action.data,
      };
      break;

    case 'GET_LANGUAGE_ERROR':
      state = {
        ...state,
        isLangLoading: false,
        languageList: [],
      };
      break;

    /*------------ */
    case 'GET_SENIORITY_LEVEL_REQUEST':
      state = {
        ...state,
        isSLLoading: true,
        seniorityLevelList: [],
      };
      break;

    case 'GET_SENIORITY_LEVEL_SUCCESS':
      state = {
        ...state,
        isSLLoading: false,
        seniorityLevelList: action.data,
      };
      break;

    case 'GET_SENIORITY_LEVEL_ERROR':
      state = {
        ...state,
        isSLLoading: false,
        seniorityLevelList: [],
      };
      break;

    /*------------ */
    case 'GET_BUSINESS_SECTOR_REQUEST':
      state = {
        ...state,
        isBTLoading: true,
        businessTypeList: [],
      };
      break;

    case 'GET_BUSINESS_SECTOR_SUCCESS':
      state = {
        ...state,
        isBTLoading: false,
        businessTypeList: action.data,
      };
      break;

    case 'GET_BUSINESS_SECTOR_ERROR':
      state = {
        ...state,
        isBTLoading: false,
        businessTypeList: [],
      };
      break;

    /*------------ */
    case 'GET_BUSINESS_SECTOR_CATEGORY_REQUEST':
      state = {
        ...state,
        isBSLoading: true,
        businessTypeCategoryList: [],
      };
      break;

    case 'GET_BUSINESS_SECTOR_CATEGORY_SUCCESS':
      state = {
        ...state,
        isBSLoading: false,
        businessTypeCategoryList: action.data,
      };
      break;

    case 'GET_BUSINESS_SECTOR_CATEGORY_ERROR':
      state = {
        ...state,
        isBSLoading: false,
        businessTypeCategoryList: [],
      };
      break;

    /*------------ */
    case 'GET_OWNERSHIP_TYPE_REQUEST':
      state = {
        ...state,
        isOTLoading: true,
        ownershipTypeList: [],
      };
      break;

    case 'GET_OWNERSHIP_TYPE_SUCCESS':
      state = {
        ...state,
        isOTLoading: false,
        ownershipTypeList: action.data,
      };
      break;

    case 'GET_OWNERSHIP_TYPE_ERROR':
      state = {
        ...state,
        isOTLoading: false,
        ownershipTypeList: [],
      };
      break;
    /*------------ */
    case 'GET_INVOICE_REQUEST':
      state = {
        ...state,
        isInvoiceLoading: true,
        invoiceList: [],
      };
      break;

    case 'GET_INVOICE_SUCCESS':
      state = {
        ...state,
        isInvoiceLoading: false,
        invoiceList: action.data,
      };
      break;

    case 'GET_INVOICE_ERROR':
      state = {
        ...state,
        isInvoiceLoading: false,
        invoiceList: [],
      };
      break;
    /*------------ */
    case 'GET_QUESTION_DETAIL':
      state = {
        ...state,
        isQuestionLoading: true,
        questionDetail: {},
      };
      break;
    /*------------ */
    case 'GET_QUESTION_DETAIL_REQUEST':
      state = {
        ...state,
        isQuestionLoading: true,
        questionDetail: {},
      };
      break;

    case 'GET_QUESTION_DETAIL_SUCCESS':
      state = {
        ...state,
        isQuestionLoading: false,
        questionDetail: action.data,
      };
      break;

    case 'GET_QUESTION_DETAIL_ERROR':
      state = {
        ...state,
        isQuestionLoading: false,
        questionDetail: {},
      };
      break;

    // --- Update image stepi
    case 'UPLOAD_DOCUMENT_REQUEST':
      state = {
        ...state,
        isDocUploadLoading: true,
        docURL: '',
      };
      break;

    case 'UPLOAD_DOCUMENT_SUCCESS':
      state = {
        ...state,
        isDocUploadLoading: false,
        docURL: action.data,
      };
      break;

    case 'UPLOAD_DOCUMENT_ERROR':
      state = {
        ...state,
        isDocUploadLoading: false,
        docURL: '',
      };
      break;

    /*------------ */

    // --- Update PRIVATE MULTIPLE FILE 
    case 'UPLOAD_MULTIPLE_PRIVATE_FILE':
      state = {
        ...state,
        isDocUploadLoading: true,
        docURL: '',
      };
      break;

    case 'UPLOAD_MULTI_FILE_SUCCESS':
      state = {
        ...state,
        isDocUploadLoading: false,
        docURL: action.data,
      };
      break;

    case 'UPLOAD_MULTI_FILE_ERROR':
      state = {
        ...state,
        isDocUploadLoading: false,
        docURL: '',
      };
      break;

    /*------------ */
    case 'GET_INDUSTRY_REQUEST':
      state = {
        ...state,
        isIndustryLoading: true,
        industryList: [],
      };
      break;

    case 'GET_INDUSTRY_SUCCESS':
      state = {
        ...state,
        isIndustryLoading: false,
        industryList: action.data,
      };
      break;

    case 'GET_INDUSTRY_ERROR':
      state = {
        ...state,
        isIndustryLoading: false,
        industryList: [],
      };
      break;

    /*------------ */
    case 'COMMON_PUT_POST_REQUEST':
      state = {
        ...state,
        isCommonUpdateLoading: true,
      };
      break;

    case 'COMMON_PUT_POST_SUCCESS':
      state = {
        ...state,
        isCommonUpdateLoading: false,
        commonMsg: 'success',
      };
      break;

    case 'COMMON_PUT_POST_ERROR':
      state = {
        ...state,
        isCommonUpdateLoading: false,
        commonMsg: '',
      };
      break;

    /*------------ */
    case 'GET_VALIDATE_COUPON_RESPONSE':
      state = {
        ...state,
        isCouponValidatingLoading: true,
      };
      break;

    case 'GET_VALIDATE_COUPON_RESPONSE_SUCCESS':
      state = {
        ...state,
        isCouponValidatingLoading: false,
        validCouponResponse: 'success',
      };
      break;

    case 'GET_VALIDATE_COUPON_RESPONSE_ERROR':
      state = {
        ...state,
        isCouponValidatingLoading: false,
        validCouponResponse: [],
      };
      break;

    /*------------ */
    case 'POST_CLIENT_ACCEPT_OR_REJECT_EXPERT_REQUEST':
      state = {
        ...state,
        isExpertRequestOrRejectRequestLoading: true,
      };
      break;

    case 'POST_CLIENT_ACCEPT_OR_REJECT_EXPERT_REQUEST_SUCCESS':
      state = {
        ...state,
        isExpertRequestOrRejectRequestLoading: false,
        expertRequestOrRejectRequestResponse: 'success',
      };
      break;

    case 'POST_CLIENT_ACCEPT_OR_REJECT_EXPERT_REQUEST_ERROR':
      state = {
        ...state,
        isExpertRequestOrRejectRequestLoading: false,
        expertRequestOrRejectRequestResponse: [],
      };
      break;

    /*------------ */
    case 'GET_TIMEZONE_LIST_SUCCESS':
      state = {
        ...state,
        timeZoneList: action.data,
      };
      break;
    case 'GET_DONT_SHOW_WHATS_NEXT_MODAL_RESPONSE_SUCCESS':
      state = {
        ...state,
        isDontShowWhatsNext: action.data.whatnext_dialog_display,
        // dontShowWhatsNextResponse: action.data.whatnext_dialog_display,
      };
      break;
    case 'GET_DONT_SHOW_WHATS_NEXT_MODAL_RESPONSE_ERROR':
      state = {
        ...state,
        dontShowWhatsNextResponse: false,
      };
      break;
    case 'GET_NOTIFICATION_SUCCESS':
      state = {
        ...state,
        notifications: state.notifications.concat(action.data.data),
        newNotificationCount: state.newNotificationCount + action.data.unreadCount
      };
      break;
    case 'ADD_NOTIFICATION_SUCCESS':
      state = {
        ...state,
        notifications: [action.data, ...state.notifications],
        newNotificationCount: state.newNotificationCount + 1,
      }
      break;
    case 'REMOVE_NOTIFICATION_COUNT_SUCCESS':
      state = {
        ...state,
        newNotificationCount: state.newNotificationCount - 1,
      }
      break;
    case 'GET_NOTIFICATION_ERROR':
      state = {
        ...state,
        notifications: [],
      };
      break;
    case 'READ_NOTIFICATION_SUCCESS':
      state = {
        ...state,
        readNotification: true
      };
      break;
    case 'READ_NOTIFICATION_ERROR':
      state = {
        ...state,
        readNotification: false
      };
      break;
    case 'GET_BLOGS_SUCCESS':
      let blogs = [...state?.Blogs, ...action.data]
      const removeDuplicates = (arr) => {
        const map = new Map();
        arr.forEach(item => {
          map.set(item.id, item); // Updates the value if the id already exists  
        });
        return Array.from(map.values()); // Returns an array of unique objects
      };
      state = {
        ...state,
        isBlogsLoading: false,
        Blogs: removeDuplicates(blogs),
      };
      break;
    case 'GET_BLOGS_SEARCH_SUCCESS':
      state = {
        ...state,
        isBlogsLoading: false,
        Blogs: action.data,
      }
      break;
    case 'GET_BLOGS_ERROR':
      state = {
        ...state,
        isBlogsLoading: true,
        blogs: [],
      };
      break;
    case 'GET_BLOG_SUCCESS':
      state = {
        ...state,
        isBlogLoading: false,
        singleBlog: action.data,
      };
      break
    case 'GET_BLOG_ERROR':
      state = {
        ...state,
        isBlogLoading: true,
        singleBlog: {},
      };
      break;
    case 'CREATE_BLOG_SUCCESS':
      state = {
        ...state,
        isCreateBlogLoading: false,
      };
      break;
    case 'CREATE_BLOG_ERROR':
      state = {
        ...state,
        isCreateBlogLoading: true,
      };
      break;
    case 'EDIT_BLOG_ERROR':
      state = {
        ...state,
        isEditBlogLoading: true,
      };
      break;
    case 'EDIT_BLOG_SUCCESS':
      state = {
        ...state,
        isEditBlogLoading: false,
      };
      break;
    case 'DELETE_BLOG_SUCCESS':
      let updatedBlogs = state?.Blogs.filter(blog => blog.id !== action.data);
      state = {
        ...state,
        isDeleteBlogLoading: false,
        Blogs: updatedBlogs

      };
      break;
    case 'DELETE_BLOG_ERROR':
      state = {
        ...state,
        isDeleteBlogLoading: true,
      };
      break;

    case 'SET_COMMON_STATE_VALUE':
      state = {
        ...state,
        [action.key]: action.value
      }
    default:
  }

  return state;
};
export default AuthReducer;
