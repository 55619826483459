/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, Form, Button, Spin, Radio, Space, Input, Row, Col, Divider } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getUserData, isLogin } from '../../helpers/functions';
import { BackwardOutlined, LeftCircleFilled } from '@ant-design/icons';
import { IoMdArrowRoundBack } from 'react-icons/io';

const { TextArea } = Input;

const Step1 = ({ handleNext, expertId }) => {
  const dispatch = useDispatch();
  const { qid } = useParams();
  const [form] = Form.useForm();
  const { isQTLoading, questionTypes, isQuestionLoading, questionDetail } = useSelector(
    state => state.common,
  );
  const { isStep1Loading, step1Data, step1Success } = useSelector(state => state.steps);
  const [selectedType, setSelectedType] = useState('general-inquiry');
  const { state } = useLocation();
  const navigate = useNavigate();

  const onChange = e => {
    setSelectedType(e.target.dataAttr);
  };

  useEffect(() => {
    if (!isLogin()) {
      localStorage.setItem('redirectLink', `${window.location.origin}/request`);
      navigate('/login?type=customer');
    } else {
      dispatch({ type: 'GET_QUESTION_TYPE_REQUEST', formData: { status: 'active' } });
      if (qid) {
        dispatch({ type: 'GET_QUESTION_DETAIL', id: qid });
      }
    }
  }, []);

  useEffect(() => {
    if (
      !isQTLoading &&
      questionTypes.length > 0 &&
      qid &&
      !isQuestionLoading &&
      Object.keys(questionDetail).length > 0
    ) {
      const categoryId =
        questionDetail.question_type !== null ? questionDetail.question_type?.id : '';
      form.setFieldsValue({ question_type_id: categoryId });
      form.setFieldsValue({ question_disc: questionDetail?.question_disc || '' });
      if (categoryId === 1) {
        
        setSelectedType('general-inquiry');
      } else if (categoryId === 2) {
        setSelectedType('technical-consultation');
      } else if (categoryId === 3) {
        setSelectedType('service-quote');
      }
    }
  }, [isQuestionLoading, questionDetail, isQTLoading, questionTypes]);

  const onFinish = values => {
    let tmpValues = values;

    if (expertId !== undefined && expertId !== null) {
      tmpValues.expert_id = parseInt(expertId);
    }

    if (qid) {
      tmpValues.question_id = parseInt(qid);
      dispatch({
        type: 'QUESTION_STEP_1_REQUEST',
        formData: {
          ...tmpValues,
          user_id: getUserData().id,
          assign_by_admin: expertId ? 0 : 1,
          expert_approved: expertId ? 1 : 0,
        },
        requestType: 'put',
      });
    } else {
      dispatch({
        type: 'QUESTION_STEP_1_REQUEST',
        formData: {
          ...tmpValues,
          user_id: getUserData().id,
          assign_by_admin: expertId ? 0 : 1,
          expert_approved: expertId ? 1 : 0,
        },
        requestType: 'post',
      });
    }
  };

  useEffect(() => {
    if (!isStep1Loading && Object.keys(step1Data).length > 0 && step1Success === 'success') {
      handleNext(step1Data.id, 'question-type');
      dispatch({ type: 'CLEAR_STEPS' });
    }
  }, [isStep1Loading, step1Data, step1Success]);
  
  return (
    <>
  
   
  

    <div>
      <div onClick={() => navigate(-1)} style={{display:"flex", alignItems:"center", padding:"10px", cursor:"pointer"}}>
      <IoMdArrowRoundBack size={20}/>{"Back"}
      </div>
 
      <div className="site-card-border-less-wrapper card-wrapper">
        <Card bordered={false}>
          {isQTLoading && <Spin />}
          {!isQTLoading && (
            <>
              <p className="m-title tl mb-7">I need to contact an expert for a</p>
              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                form={form}
                onFinish={onFinish}
              >
                <Form.Item
                  name="question_type_id"
                  rules={[{ required: true, message: 'Please select at least one option' }]}
                  initialValue={1}
                >
                  <Radio.Group
                    className={'mt3'}
                    style={{ width: '100%' }}
                    onChange={onChange}
                    defaultValue={1}
                  >
                    <Space direction="vertical" size={'middle'} style={{ width: '100%' }}>
                      {questionTypes.map(item => {
                        return (
                          <>
                            {item.slug !== 'service-quote' && (
                              <>
                                <Radio
                                  style={{ fontSize: '18px' }}
                                  key={item.id}
                                  value={item.id}
                                  dataAttr={item.slug}
                                >
                                  {' '}
                                  {item.name}
                                </Radio>
                                {item.slug === selectedType && (
                                  <Form.Item
                                    className="desc-text"
                                    label="Describe your issue"
                                    name={'question_disc'}
                                    initialValue={state?.question}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'please enter your issue description',
                                      },
                                      {
                                        max: 10000,
                                        message: 'Maximum 10000 characters are allowed',
                                      },
                                    ]}
                                  >
                                    <TextArea
                                      style={{ resize: 'none', fontSize: '16px' }}
                                      rows={4}
                                    />
                                  </Form.Item>
                                )}
                              </>
                            )}
                          </>
                        );
                      })}
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Divider className="mt40 mb40" />
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    className="mt5"
                    loading={isStep1Loading}
                    disabled={isStep1Loading}
                  >
                    Next
                  </Button>
                </Form.Item>
              </Form>

              {/* <Form
                                layout="vertical"
                                name="basic"
                                hideRequiredMark={true}
                                autoComplete="off"
                            >
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="float-r"
                                        size="middle"
                                    >
                                        Next
                                    </Button>
                                </Form.Item>
                            </Form> */}
            </>
          )}
        </Card>
      </div>
    </div>
    </>
  );
};
export default Step1;
