import { put, takeLatest, call, delay } from 'redux-saga/effects';
import { message } from 'antd';
//Import API Service.
import {
  deleteRequestAPI,
  getRequestAPI,
  postRequestAPI,
  postRequestForMessagingAPI,
  putRequestAPI,
} from '../../helpers/service';
import axios from 'axios';
import { ROLE_NAME, TIME_ZONE_LIST_API_URL } from '../../helpers/constant';
import { getUserData, role } from '../../helpers/functions';
import EditBlog from '../../components/admin/blogs/EditBlog';

function* postRequest({ formData = {}, requestType = 'post', moduleType = '' }) {
  try {
    let response = '';
    if (moduleType === 'contact') {
      if (requestType === 'post') {
        response = yield call(postRequestAPI, { url: `contact-us`, data: formData });
      }
    }

    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'POST_COMMON_SUCCESS', data: response.data.data || '' });
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'POST_COMMON_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({ type: 'POST_COMMON_ERROR', error: error?.message || 'Something went wrong' });
  }
}

function* getQuestionType({ id }) {
  try {
    const response = yield call(getRequestAPI, { url: `questions-type`, params: {} });
    if (response.status === 200) {
      yield put({ type: 'GET_QUESTION_TYPE_SUCCESS', data: response.data.data || [] });
    } else {
      yield put({ type: 'GET_QUESTION_TYPE_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_QUESTION_TYPE_ERROR', error: error.message || '' });
  }
}

function* getCustomerType({ id }) {
  try {
    const response = yield call(getRequestAPI, { url: `customers-type`, params: {} });
    if (response.status === 200) {
      yield put({ type: 'GET_CUSTOMER_TYPE_SUCCESS', data: response.data.data || [] });
    } else {
      yield put({ type: 'GET_CUSTOMER_TYPE_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_CUSTOMER_TYPE_ERROR', error: error.message || '' });
  }
}

function* getServiceCategoryList({ id }) {
  try {
    const response = yield call(getRequestAPI, { url: `categories`, params: {} });
    if (response.status === 200) {
      yield put({ type: 'GET_SERVICE_CATEGORY_SUCCESS', data: response.data.data || [] });
    } else {
      yield put({ type: 'GET_SERVICE_CATEGORY_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_SERVICE_CATEGORY_ERROR', error: error.message || '' });
  }
}

function* getSubCategoryList({ formData }) {
  try {
    const response = yield call(postRequestAPI, { url: `sub-categories`, data: formData });
    if (response.status === 200) {
      yield put({ type: 'GET_SUB_CATEGORY_SUCCESS', data: response.data.data || [] });
    } else {
      yield put({ type: 'GET_SUB_CATEGORY_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_SUB_CATEGORY_ERROR', error: error.message || '' });
  }
}

function* getServiceTypeList({ id }) {
  try {
    const response = yield call(getRequestAPI, { url: `type-of-services`, params: {} });
    if (response.status === 200) {
      yield put({ type: 'GET_SERVICE_TYPE_SUCCESS', data: response.data.data || [] });
    } else {
      yield put({ type: 'GET_SERVICE_TYPE_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_SERVICE_TYPE_ERROR', error: error.message || '' });
  }
}

function* getSeniorityLevel({ id }) {
  try {
    const response = yield call(getRequestAPI, { url: `seniority-levels`, params: {} });
    if (response.status === 200) {
      yield put({ type: 'GET_SENIORITY_LEVEL_SUCCESS', data: response.data.data || [] });
    } else {
      yield put({ type: 'GET_SENIORITY_LEVEL_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_SENIORITY_LEVEL_ERROR', error: error.message || '' });
  }
}

function* getLocationList({ id }) {
  try {
    const response = yield call(getRequestAPI, { url: `locations`, params: {} });

    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'GET_LOCATION_SUCCESS', data: response.data.data || [] });
    } else {
      yield put({ type: 'GET_LOCATION_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_LOCATION_ERROR', error: error.message || '' });
  }
}
function* getLanguageList({ id }) {
  try {
    const response = yield call(getRequestAPI, { url: `languages`, params: {} });

    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'GET_LANGUAGE_SUCCESS', data: response.data.data || [] });
    } else {
      yield put({ type: 'GET_LANGUAGE_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_LANGUAGE_ERROR', error: error.message || '' });
  }
}
function* getBusinessSector({ id }) {
  try {
    const response = yield call(getRequestAPI, { url: `business-sectors`, params: {} });
    if (response.status === 200) {
      yield put({ type: 'GET_BUSINESS_SECTOR_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'GET_BUSINESS_SECTOR_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_BUSINESS_SECTOR_ERROR', error: error.message || '' });
  }
}
function* getBusinessSectorSubCategory({ id }) {
  try {
    const response = yield call(getRequestAPI, {
      url: `business-sectors-categories`,
      params: { parent_id: id },
    });

    if (response.status === 200) {
      yield put({ type: 'GET_BUSINESS_SECTOR_CATEGORY_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'GET_BUSINESS_SECTOR_CATEGORY_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_BUSINESS_SECTOR_CATEGORY_ERROR', error: error.message || '' });
  }
}

function* getOwnershipType({ id }) {
  try {
    const response = yield call(getRequestAPI, { url: `ownership-types`, params: {} });
    if (response.status === 200) {
      yield put({ type: 'GET_OWNERSHIP_TYPE_SUCCESS', data: response.data.data || [] });
    } else {
      yield put({ type: 'GET_OWNERSHIP_TYPE_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_OWNERSHIP_TYPE_ERROR', error: error.message || '' });
  }
}

function* getInvoiceData({ id }) {
  try {
    const response = yield call(getRequestAPI, { url: `invoices-per-month`, params: {} });
    if (response.status === 200) {
      yield put({ type: 'GET_INVOICE_SUCCESS', data: response.data.data || [] });
    } else {
      yield put({ type: 'GET_INVOICE_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_INVOICE_ERROR', error: error.message || '' });
  }
}
function* getQuestionDetail({ id }) {
  try {
    const response = yield call(getRequestAPI, { url: `question/${id}`, params: {} });
    if (response.status === 200) {
      yield put({ type: 'GET_QUESTION_DETAIL_SUCCESS', data: response.data || {} });
    } else {
      yield put({ type: 'GET_QUESTION_DETAIL_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_QUESTION_DETAIL_ERROR', error: error.message || '' });
  }
}
function* getQuestionDetail2({ id }) {
  try {
    const response = yield call(getRequestAPI, { url: `question/${id}`, params: {} });
    if (response.status === 200) {
      yield put({ type: 'GET_QUESTION_DETAIL_SUCCESS', data: response.data || {} });
    } else {
      yield put({ type: 'GET_QUESTION_DETAIL_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_QUESTION_DETAIL_ERROR', error: error.message || '' });
  }
}

function* uploadDocument({ formData }) {
  try {
    const response = yield call(postRequestAPI, { url: `uploading-file`, data: formData });
    if (response.status === 200) {
      if (response?.data?.success) {
        yield put({ type: 'UPLOAD_DOCUMENT_SUCCESS', data: response.data?.url || '' });
      } else {
        yield put({ type: 'UPLOAD_DOCUMENT_ERROR', error: 'No record found' });
      }
    } else {
      yield put({ type: 'UPLOAD_DOCUMENT_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'UPLOAD_DOCUMENT_ERROR', error: error.message || '' });
  }
}

function* getIndustryList({ id }) {
  try {
    const response = yield call(getRequestAPI, { url: `business-sectors`, params: {} });

    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'GET_INDUSTRY_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'GET_INDUSTRY_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_INDUSTRY_ERROR', error: error.message || '' });
  }
}

function* getRequests({ formData = {}, reqFrom = '' }) {
  try {
    let response = '';
    if (reqFrom === 'expert') {
      response = yield call(getRequestAPI, { url: `my-active_questions_list`, formData: {} });
    } else {
      response = yield call(postRequestAPI, { url: `my-question`, data: formData });
    }
    if (response.status === 200) {
      yield put({ type: 'GET_REQUESTS_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'GET_REQUESTS_ERROR' });
    }
  } catch (error) {
    yield put({ type: 'GET_REQUESTS_ERROR' });
  }
}

function* getRequestsHistory() {
  try {
    const response = yield call(getRequestAPI, { url: `customer-question-history`, params: {} });
    if (response.status === 200) {
      yield put({ type: 'GET_REQUESTS_HISTORY_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'GET_REQUESTS_HISTORY_ERROR' });
    }
  } catch (error) {
    yield put({ type: 'GET_REQUESTS_HISTORY_ERROR' });
  }
}

function* getRequestStatus({ formData = {} }) {
  try {
    const response = yield call(postRequestAPI, { url: `get-question-status`, data: formData });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'GET_QUESTION_REQUEST_STATUS_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'GET_QUESTION_REQUEST_STATUS_ERROR' });
    }
  } catch (error) {
    yield put({ type: 'GET_QUESTION_REQUEST_STATUS_ERROR' });
  }
}

function* getExpertActiveRequestStatus() {
  try {
    const response = yield call(getRequestAPI, { url: `my-active_requests_list`, params: {} });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'GET_EXPERT_ACTIVE_REQUEST_STATUS_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'GET_EXPERT_ACTIVE_REQUEST_STATUS_ERROR' });
    }
  } catch (error) {
    yield put({ type: 'GET_EXPERT_ACTIVE_REQUEST_STATUS_ERROR' });
  }
}

function* getExpertNewRequestStatus() {
  try {
    const response = yield call(getRequestAPI, { url: `new_requests_list`, params: {} });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'GET_EXPERT_NEW_REQUEST_STATUS_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'GET_EXPERT_NEW_REQUEST_STATUS_ERROR' });
    }
  } catch (error) {
    yield put({ type: 'GET_EXPERT_NEW_REQUEST_STATUS_ERROR' });
  }
}

function* postAcceptOrRejectExpertRequest(formData = {}) {
  try {
    const response = yield call(putRequestAPI, {
      url: `accept-or-reject-question`,
      data: formData.formData,
    });
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: 'POST_ACCEPT_OR_REJECT_EXPERT_REQUEST_STATUS_SUCCESS',
        data: response.data || [],
      });
    } else {
      yield put({ type: 'POST_ACCEPT_OR_REJECT_EXPERT_REQUEST_STATUS_ERROR' });
    }
  } catch (error) {
    yield put({ type: 'POST_ACCEPT_OR_REJECT_EXPERT_REQUEST_STATUS_ERROR' });
  }
}

function* getExpertDetailById({ id }) {
  try {
    const response = yield call(getRequestAPI, { url: `expert/${id}`, params: {} });
    if (response.status === 200) {
      yield put({ type: 'GET_EXPERT_DETAIL_BY_ID_SUCCESS', data: response.data[0] || {} });
    } else {
      yield put({ type: 'GET_EXPERT_DETAIL_BY_ID_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_EXPERT_DETAIL_BY_ID_ERROR', error: error.message || '' });
  }
}

function* getExpertExperienceDetailById({ formData = {} }) {
  try {
    const response = yield call(putRequestAPI, { url: `experience-in-industry`, data: formData });
    if (response.status === 200) {
      yield put({ type: 'GET_EXPERT_EXPERIENCE_DETAIL_BY_ID_SUCCESS', data: response.data || {} });
    } else {
      yield put({ type: 'GET_EXPERT_EXPERIENCE_DETAIL_BY_ID_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_EXPERT_EXPERIENCE_DETAIL_BY_ID_ERROR', error: error.message || '' });
  }
}

function* getExpertLicensesDetailById({ formData = {} }) {
  try {
    const response = yield call(putRequestAPI, {
      url: `licenses-and-certificates`,
      data: formData,
    });
    if (response.status === 200) {
      yield put({ type: 'GET_EXPERT_LICENSES_DETAIL_BY_ID_SUCCESS', data: response.data || {} });
    } else {
      yield put({ type: 'GET_EXPERT_LICENSES_DETAIL_BY_ID_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_EXPERT_LICENSES_DETAIL_BY_ID_ERROR', error: error.message || '' });
  }
}

function* getExpertDegreesDetailById({ formData }) {
  try {
    const response = yield call(putRequestAPI, { url: 'university-degrees', data: formData });

    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'GET_EXPERT_DEGREES_DETAIL_BY_ID_SUCCESS', data: response?.data || {} });
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'GET_EXPERT_DEGREES_DETAIL_BY_ID_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({
      type: 'GET_EXPERT_DEGREES_DETAIL_BY_ID_ERROR',
      error: error?.message || 'Something went wrong',
    });
  }
}

function* getExpertServicesAndRateDetailById({ formData = {} }) {
  try {
    const response = yield call(putRequestAPI, { url: `services-and-rate`, data: formData });
    if (response.status === 200) {
      yield put({
        type: 'GET_EXPERT_SERVICE_AND_RATE_DETAIL_BY_ID_SUCCESS',
        data: response.data || {},
      });
    } else {
      yield put({
        type: 'GET_EXPERT_SERVICE_AND_RATE_DETAIL_BY_ID_ERROR',
        error: 'No record found',
      });
    }
  } catch (error) {
    yield put({
      type: 'GET_EXPERT_SERVICE_AND_RATE_DETAIL_BY_ID_ERROR',
      error: error.message || '',
    });
  }
}

function* getReviewsById({ id }) {
  try {
    const response = yield call(postRequestAPI, { url: `reviews/${id}`, params: {} });
    if (response.status === 200) {
      yield put({ type: 'GET_REVIEWS_BY_ID_SUCCESS', data: response.data || {} });
    } else {
      yield put({ type: 'GET_REVIEWS_BY_ID_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_REVIEWS_BY_ID_ERROR', error: error.message || '' });
  }
}

function* postExpertReview({ formData = {} }) {
  try {
    const response = yield call(postRequestAPI, { url: `review/add`, data: formData });
    if (response.status === 200) {
      yield put({ type: 'ADD_EXPERT_REVIEW_SUCCESS', data: response.data || {} });
    } else {
      yield put({ type: 'ADD_EXPERT_REVIEW_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'ADD_EXPERT_REVIEW_ERROR', error: error.message || '' });
  }
}

function* updateReviewStatus({ formData = {} }) {
  try {
    const response = yield call(putRequestAPI, {
      url: `reviews/edit/${formData.id}`,
      data: formData.data,
    });
    if (response.status === 200) {
      yield put({ type: 'UPDATE_REVIEW_STATUS_SUCCESS', data: response.data || {} });
    } else {
      yield put({ type: 'UPDATE_REVIEW_STATUS_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'UPDATE_REVIEW_STATUS_ERROR', error: error.message || '' });
  }
}

function* commonPutPostRequest({ reqType, module, formData }) {
  try {
    let URL = '';
    let response = '';
    if (reqType === 'post') {
      if (module === 'reply-question') {
        URL = `question-detail`;
      } else if (module === 'reply-quotation') {
        URL = `add-edit-quotation`;
      } else if (module === 'accept-quotation') {
        URL = `accept-or-reject-question`;
      } else if (module === 'business-sector') {
        URL = `business-sectors/add`;
      } else if (module === 'subCategory') {
        URL = `sub-category/add?name=${formData.name}&parent_id=${formData.parent_id}`;
      }
      response = yield call(postRequestAPI, { url: URL, data: formData });
    } else if (reqType === 'put') {
      if (module === 'accept-quotation') {
        URL = `accept-or-reject-question`;
      }
      response = yield call(putRequestAPI, { url: URL, data: formData });
    }
    // let response = {"data":{"message":"Thanks for providing your answer.","questionDetail":{"my_answer":"as as asd as dasd ad","question_id":20,"updated_at":"2023-03-11T18:42:46.000000Z","created_at":"2023-03-11T18:42:46.000000Z","id":2},"questionMeta":{"question_status_id":10,"question_id":20,"updated_at":"2023-03-11T18:42:46.000000Z","created_at":"2023-03-11T18:42:46.000000Z","id":43}},"status":201,"statusText":"","headers":{"cache-control":"no-cache, private","content-type":"application/json"},"config":{"transitional":{"silentJSONParsing":true,"forcedJSONParsing":true,"clarifyTimeoutError":false},"adapter":["xhr","http"],"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1,"maxBodyLength":-1,"env":{},"headers":{"Accept":"application/json","Content-Type":"application/json","Authorization":"Bearer 13|n11e22AvEHVWAbyM4yahQzeihQzujychBU7uqhXI"},"method":"post","url":"https://accountizer.247demo.ca/api/question-detail","data":"{\"my_answer\":\"as as asd as dasd ad\",\"question_id\":20}"},"request":{}};
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'COMMON_PUT_POST_SUCCESS', data: response.data || {} });
    } else {
      yield put({ type: 'COMMON_PUT_POST_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'COMMON_PUT_POST_ERROR', error: error.message || '' });
  }
}

function* getExpertRequestHistoryList({ id, requestType }) {
  try {
    const response = yield call(getRequestAPI, { url: `requests_history_view`, params: { requestType } });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'GET_EXPERT_REQUEST_HISTORY_VIEW_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'GET_EXPERT_REQUEST_HISTORY_VIEW_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_EXPERT_REQUEST_HISTORY_VIEW_ERROR', error: error.message || '' });
  }
}

function* updateQuestionStatus({ formData = {} }) {
  try {
    const response = yield call(postRequestAPI, { url: `change-status`, data: formData });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'UPDATE_QUESTION_STATUS_SUCCESS', data: response.data || {} });
    } else {
      yield put({ type: 'UPDATE_QUESTION_STATUS_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'UPDATE_QUESTION_STATUS_ERROR', error: error.message || '' });
  }
}

function* getQuestionCounts({ formData = {} }) {
  try {
    const response = yield call(postRequestAPI, { url: `questions/counter`, data: formData });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'GET_QUESTION_COUNT_SUCCESS', data: response.data || 0 });
    } else {
      yield put({ type: 'GET_QUESTION_COUNT_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_QUESTION_COUNT_ERROR', error: error.message || '' });
  }
}

function* getNewQuestionCounts() {
  try {
    const response = yield call(postRequestAPI, {
      url: `questions/counter`,
      data: {
        type: 'question',
        status: 'new',
      },
    });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'GET_NEW_QUESTION_COUNT_SUCCESS', data: response.data || 0 });
    } else {
      yield put({ type: 'GET_NEW_QUESTION_COUNT_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_NEW_QUESTION_COUNT_ERROR', error: error.message || '' });
  }
}

function* getAllQuestionCounts() {
  try {
    const response = yield call(postRequestAPI, {
      url: `questions/counter`,
      data: {
        type: 'question',
      },
    });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'GET_ALL_QUESTION_COUNT_SUCCESS', data: response.data || 0 });
    } else {
      yield put({ type: 'GET_ALL_QUESTION_COUNT_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_ALL_QUESTION_COUNT_ERROR', error: error.message || '' });
  }
}

function* getUserCounts() {
  try {
    const response = yield call(postRequestAPI, { url: 'users/counter' });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'GET_USER_COUNT_SUCCESS', data: response.data || 0 });
    } else {
      yield put({ type: 'GET_USER_COUNT_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_USER_COUNT_ERROR', error: error.message || '' });
  }
}

function* getExpertRequestCounts({ formData }) {
  try {
    const response = yield call(postRequestAPI, { url: 'expert/counter', data: formData });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'GET_EXPERT_REQUEST_COUNT_SUCCESS', data: response.data || 0 });
    } else {
      yield put({ type: 'GET_EXPERT_REQUEST_COUNT_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_EXPERT_REQUEST_COUNT_ERROR', error: error.message || '' });
  }
}

function* validateCustomerCouponCode(coupon) {
  try {
    const response = yield call(getRequestAPI, { url: `coupon/${coupon.coupon}` });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'GET_VALIDATE_COUPON_RESPONSE_SUCCESS', data: response.data || 0 });
      message.success('Discount code applied successfully.');
    } else {
      yield put({ type: 'GET_VALIDATE_COUPON_RESPONSE_ERROR', error: 'No record found' });
      message.error('Invalid discount code.');
    }
  } catch (error) {
    yield put({ type: 'GET_VALIDATE_COUPON_RESPONSE_ERROR', error: error.message || '' });
  }
}

function* clientAcceptOrRejectExpertRequest({ formData }) {
  try {
    const response = yield call(postRequestAPI, { url: 'question/expert/action', data: formData });
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: 'POST_CLIENT_ACCEPT_OR_REJECT_EXPERT_REQUEST_SUCCESS',
        data: response.data || 0,
      });
    } else {
      yield put({
        type: 'POST_CLIENT_ACCEPT_OR_REJECT_EXPERT_REQUEST_ERROR',
        error: 'No record found',
      });
    }
  } catch (error) {
    yield put({
      type: 'POST_CLIENT_ACCEPT_OR_REJECT_EXPERT_REQUEST_ERROR',
      error: error.message || '',
    });
  }
}

// get call to fetch timezone 
function* getTimeZoneList() {
  try {
    const response = yield axios.get(TIME_ZONE_LIST_API_URL, '', {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      yield put({ type: 'GET_TIMEZONE_LIST_SUCCESS', data: response.data || [] });
    } else {
      yield put({
        type: 'GET_DONT_SHOW_WHATS_NEXT_MODAL_RESPONSE_ERROR',
        error: 'No record found',
      });
    }
  } catch (error) {
    yield put({
      type: 'GET_DONT_SHOW_WHATS_NEXT_MODAL_RESPONSE_ERROR',
      error: error.message || '',
    });
  }
}
function* dontShowWhatsNextModal({ userId = '', requestType = 'post' }) {
  try {
    let response = '';
    if (requestType === 'post') {
      response = yield call(postRequestAPI, { url: `dontshowhatsnextmodal`, data: { id: userId } });
    }

    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'DONT_SHOW_WHATS_NEXT_MODAL_RESPONSE_SUCCESS', data: response.data || '' });
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'DONT_SHOW_WHATS_NEXT_MODAL_RESPONSE_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({
      type: 'DONT_SHOW_WHATS_NEXT_MODAL_RESPONSE_ERROR',
      error: error?.message || 'Something went wrong',
    });
  }
}

function* getDontShowWhatsNextModal({ userId = '' }) {
  try {
    const response = yield call(getRequestAPI, {
      url: `getdontshowhatsnextmodal`,
      params: { id: userId },
    });
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: 'GET_DONT_SHOW_WHATS_NEXT_MODAL_RESPONSE_SUCCESS',
        data: response.data || 0,
      });
    } else {
      yield put({
        type: 'GET_DONT_SHOW_WHATS_NEXT_MODAL_RESPONSE_ERROR',
        error: 'No record found',
      });
    }
  } catch (error) {
    yield put({
      type: 'GET_DONT_SHOW_WHATS_NEXT_MODAL_RESPONSE_ERROR',
      error: error.message || '',
    });
  }
}
function* getNotifications({ created_at }) {
  try {
    const response = yield call(getRequestAPI, {
      url: `getusernotification`,
      params: { id: getUserData()?.id, created_at: created_at },
    });
    if (response.status === 200) {
      yield put({ type: 'GET_NOTIFICATION_SUCCESS', data: { "data": response.data?.res, "unreadCount": response.data?.unreadCount } || {} });
    } else {
      yield put({ type: 'GET_NOTIFICATION_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_NOTIFICATION_ERROR', error: error.message || '' });
  }
}
function* addNotifications(data) {
  if (data) {
    yield put({ type: 'ADD_NOTIFICATION_SUCCESS', data: data || {} });
  }
  else {
    yield put({ type: 'GET_NOTIFICATION_ERROR', error: 'No record found' });
  }

}
function* readNotifications() {
  try {
    const response = yield call(postRequestAPI, { url: `read-user-all-notification`, });
    if (response.status === 200) {
      yield put({ type: 'READ_NOTIFICATION_SUCCESS', });
    } else {
      yield put({ type: 'READ_NOTIFICATION_ERROR', });
    }
  } catch (error) {
    yield put({ type: 'READ_NOTIFICATION_ERROR', });
  }
}
function* getBlogs(data) {
  try {
    let search = data.search ? data.search : '';
    let url = 'posts';
    if (data?.search) {
      url = (role() == ROLE_NAME.ADMIN) ? `post-for-admin/search/` : `posts/search/`;
    } else if (role() == ROLE_NAME.ADMIN) {
      url = 'post-for-admin';
    }
    const response = yield call(getRequestAPI, { url: url, params: { "title": data.search, "page": data.page } });
    if (response.status == 200) {
      if (search.length > 0) {
        yield put({ type: 'GET_BLOGS_SEARCH_SUCCESS', data: response?.data || [] });
      } else {
        yield put({ type: 'GET_BLOGS_SUCCESS', data: response?.data?.data || [] });
      }
    } else {
      yield put({ type: 'GET_BLOGS_ERROR', });
    }
  } catch (error) {
    yield put({ type: 'GET_BLOGS_ERROR', });
  }
}
function* createBlog(data) {
  try {
    const response = yield call(postRequestAPI, { url: `create-post`, data: data.formData });
    if (response.data.code === 200) {
      yield put({ type: 'CREATE_BLOG_SUCCESS', data: response.data || [] });
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    message.error('Something went wrong');
    yield put({ type: 'CREATE_BLOG_ERROR', });
  }
}

function* editBlog(data) {
  try {
    const response = yield call(postRequestAPI, { url: `edit-post/${data.id}`, data: data.formData });
    if (response.status === 200) {
      yield put({ type: 'EDIT_BLOG_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'EDIT_BLOG_ERROR' });
    }
  } catch (error) {
    yield put({ type: 'EDIT_BLOG_ERROR', });
  }
}
function* getSingleBlog(data) {
  try {
    const response = yield call(getRequestAPI, { url: `post/${data.id}` });
    if (response.status === 200) {
      yield put({ type: 'GET_BLOG_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'GET_BLOG_ERROR', });
    }
  } catch (error) {
    yield put({ type: 'GET_BLOG_ERROR', });
  }
}
function* deleteBlog(data) {
  try {
    const response = yield call(deleteRequestAPI, { url: `delete-post/${data.id}` });
    if (response.data.code === 200) {
      yield put({ type: 'DELETE_BLOG_SUCCESS', data: data?.id || [] });
    } else {
      yield put({ type: 'DELETE_BLOG_ERROR', });
    }
  } catch (error) {
    yield put({ type: 'DELETE_BLOG_ERROR', });
  }
}

function* uploadMultiplePrivateFiles({ formData }) {
  try {
    const response = yield call(postRequestAPI, { url: `uploading-multiple-private-file`, data: formData });
    if (response.status === 200) {
      if (response?.data?.success) {
        yield put({ type: 'UPLOAD_MULTI_FILE_SUCCESS', data: response.data?.url || '' });
      } else {
        yield put({ type: 'UPLOAD_MULTI_FILE_ERROR', error: 'No record found' });
      }
    } else {
      yield put({ type: 'UPLOAD_MULTI_FILE_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'UPLOAD_MULTI_FILE_ERROR', error: error.message || '' });
  }
}

function* setStateValues(data) {

  yield put({ type: data?.stateType || '', key: data?.key, value: data?.value });

}

function* watchCommonSagaRequests() {
  yield takeLatest('POST_COMMON_REQUEST', postRequest);
  yield takeLatest('POST_ACCEPT_OR_REJECT_EXPERT_REQUEST', postAcceptOrRejectExpertRequest);
  yield takeLatest('GET_QUESTION_REQUEST_STATUS', getRequestStatus);

  yield takeLatest('GET_QUESTION_TYPE_REQUEST', getQuestionType);
  yield takeLatest('GET_CUSTOMER_TYPE_REQUEST', getCustomerType);
  yield takeLatest('GET_SERVICE_CATEGORY_REQUEST', getServiceCategoryList);
  yield takeLatest('GET_SUB_CATEGORY_REQUEST', getSubCategoryList);
  yield takeLatest('GET_SERVICE_TYPE_REQUEST', getServiceTypeList);
  yield takeLatest('GET_SENIORITY_LEVEL_REQUEST', getSeniorityLevel);
  yield takeLatest('GET_LOCATION_REQUEST', getLocationList);
  yield takeLatest('GET_LANGUAGE_REQUEST', getLanguageList);
  yield takeLatest('GET_BUSINESS_SECTOR_REQUEST', getBusinessSector);
  yield takeLatest('GET_BUSINESS_SECTOR_CATEGORY_REQUEST', getBusinessSectorSubCategory);
  yield takeLatest('GET_OWNERSHIP_TYPE_REQUEST', getOwnershipType);
  yield takeLatest('GET_INVOICE_REQUEST', getInvoiceData);
  yield takeLatest('GET_QUESTION_DETAIL', getQuestionDetail);
  yield takeLatest('GET_QUESTION_DETAIL_REQUEST', getQuestionDetail2);
  yield takeLatest('GET_INDUSTRY_REQUEST', getIndustryList);

  yield takeLatest('UPLOAD_DOCUMENT_REQUEST', uploadDocument);
  yield takeLatest('GET_REQUESTS', getRequests);
  yield takeLatest('GET_REQUESTS_HISTORY', getRequestsHistory);
  yield takeLatest('GET_EXPERT_ACTIVE_REQUEST', getExpertActiveRequestStatus);
  yield takeLatest('GET_EXPERT_NEW_REQUEST', getExpertNewRequestStatus);
  yield takeLatest('GET_EXPERT_DETAIL_BY_ID', getExpertDetailById);
  yield takeLatest('GET_EXPERT_EXPERIENCE_DETAIL_BY_ID', getExpertExperienceDetailById);
  yield takeLatest('GET_EXPERT_LICENSES_DETAIL_BY_ID', getExpertLicensesDetailById);
  yield takeLatest('GET_EXPERT_DEGREES_DETAIL_BY_ID', getExpertDegreesDetailById);
  yield takeLatest('GET_EXPERT_SERVICE_AND_RATE_DETAIL_BY_ID', getExpertServicesAndRateDetailById);
  yield takeLatest('GET_REVIEWS_BY_ID', getReviewsById);
  yield takeLatest('ADD_EXPERT_REVIEW', postExpertReview);
  yield takeLatest('GET_EXPERT_REQUEST_HISTORY_VIEW', getExpertRequestHistoryList);
  yield takeLatest('UPDATE_QUESTION_STATUS', updateQuestionStatus);
  yield takeLatest('COMMON_PUT_POST_REQUEST', commonPutPostRequest);
  yield takeLatest('UPDATE_REVIEW_STATUS', updateReviewStatus);
  yield takeLatest('GET_USER_COUNT', getUserCounts);
  yield takeLatest('GET_QUESTION_COUNT', getQuestionCounts);
  yield takeLatest('GET_NEW_QUESTION_COUNT', getNewQuestionCounts);
  yield takeLatest('GET_EXPERT_REQUEST_COUNT', getExpertRequestCounts);
  yield takeLatest('GET_VALIDATE_COUPON_RESPONSE', validateCustomerCouponCode);
  yield takeLatest(
    'POST_CLIENT_ACCEPT_OR_REJECT_EXPERT_REQUEST',
    clientAcceptOrRejectExpertRequest,
  );


  yield takeLatest('GET_TIMEZONE_LIST', getTimeZoneList);
  yield takeLatest('DONT_SHOW_WHATS_NEXT_MODAL', dontShowWhatsNextModal);
  yield takeLatest('GET_DONT_SHOW_WHATS_NEXT_MODAL', getDontShowWhatsNextModal);
  yield takeLatest('GET_NOTIFICATION', getNotifications);
  yield takeLatest('ADD_NEW_NOTIFICATION', addNotifications);
  yield takeLatest('READ_NOTIFICATION', readNotifications);

  yield takeLatest('GET_BLOGS', getBlogs);
  yield takeLatest('GET_SINGLE_BLOG', getSingleBlog);
  yield takeLatest('CREATE_BLOG', createBlog);
  yield takeLatest('EDIT_BLOG', editBlog);
  yield takeLatest('DELETE_BLOG', deleteBlog);

  yield takeLatest('SET_STATE_VALUE', setStateValues);

  yield takeLatest('UPLOAD_MULTIPLE_PRIVATE_FILE', uploadMultiplePrivateFiles);

}

function* CommonSaga() {

  yield watchCommonSagaRequests();
}

export default CommonSaga;
