import React, { useEffect } from 'react';
import { Card, Form, Button, Divider, Row, Col, Input, Select, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import Helmet from '../common/helmet';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../helpers/functions';
import CustomSelect from '../common/customSelect';

const Profile = props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { isUserLoading, userData, isUpdating, updatingMsg } = useSelector(state => state.auth);
  const { locationList, isLocationLoading } = useSelector(state => state.common);

  useEffect(() => {
    refershDetail();
  }, []);

  const refershDetail = () => {
    dispatch({ type: 'GET_CURRENT_USER_REQUEST', formData: {} });
  };

  useEffect(() => {
    if (locationList.length === 0) {
      dispatch({ type: 'GET_LOCATION_REQUEST', formData: { status: 'active' } });
    }
  }, []);

  const optionsList = tmpLocation => {
    let locationOptions = [];
    if (tmpLocation.length > 0) {
      tmpLocation.map(item => {
        locationOptions.push({
          value: item.id,
          label: item.name,
        });
      });
    }
    return locationOptions;
  };

  useEffect(() => {
    if (!isUserLoading && Object.keys(userData).length > 0) {
      form.setFieldsValue({
        first_name: userData?.first_name,
        last_name: userData?.last_name,
        email: userData?.email,
        organization: userData?.organization,
      });
      if (userData?.locations !== null && userData?.locations?.length > 0) {
        form.setFieldsValue({
          location_ids: [userData?.locations[0].id],
        });
      }
    }
  }, [isUserLoading, userData]);

  const onFinish = values => {
    let tmpObj = values;
    tmpObj.location_ids = [values.location_ids];
    dispatch({
      type: 'UPDATE_PROFILE_REQUEST',
      formData: {
        ...tmpObj,
        id: getUserData().id,
      },
    });
  };

  useEffect(() => {
    if (!isUpdating && updatingMsg === 'success') {
      dispatch({ type: 'CLEAR_LOGIN_DATA' });
      refershDetail();
    }
  }, [isUpdating, updatingMsg]);

  return (
    <div className="profile d-flex-a-c">
      <Helmet title="Profile" />
      <div className="site-card-border-less-wrapper card-wrapper">
        <Card bordered={true}>
          <p className="m-title f20 tl">Account Details</p>
          {isUserLoading && <Spin />}
          {!isUserLoading && (
            <Form layout="vertical" name="basic" form={form} onFinish={onFinish} autoComplete="off">
              <Row gutter={30}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter first name',
                      },
                      {
                        max: 100,
                        message: 'Maximum 100 characters are allowed',
                      },
                    ]}
                  >
                    <Input size="large" placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="First Name"
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter last name',
                      },
                      {
                        max: 100,
                        message: 'Maximum 100 characters are allowed',
                      },
                    ]}
                  >
                    <Input size="large" placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label="Business Name"
                    name="organization"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your business name',
                      },
                      {
                        max: 100,
                        message: 'Maximum 100 characters are allowed',
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Business Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label="Email address"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter email address',
                      },
                      {
                        type: 'email',
                        message: 'Please enter valid email address',
                      },
                    ]}
                  >
                    <Input size="large" readOnly disabled placeholder="Email" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label="Location"
                    name="location_ids"
                    rules={[
                      {
                        required: true,
                        message: 'Please select location',
                      },
                    ]}
                  >
                    <CustomSelect
                      placeholder="Select an option"
                      style={{
                        width: '100%',
                      }}
                      size="large"
                      showSearch
                      allowClear
                      loading={isLocationLoading}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={optionsList(locationList)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider className="mt40 mb40" />
              <Form.Item>
                {
                  getUserData()?.mode_of_login === "email" &&
                  <Button type="link" size={'large'} onClick={() => navigate('/change-password')}>
                    Need to change password?
                  </Button>
                }
                <Button
                  type="primary"
                  htmlType="submit"
                  className="float-r"
                  size="large"
                  loading={isUpdating}
                  disabled={isUpdating}
                >
                  Update Detail
                </Button>
              </Form.Item>
            </Form>
          )}
        </Card>
      </div>
    </div>
  );
};

export default Profile;
