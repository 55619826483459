import React from 'react';
import { Layout } from 'antd';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
const { Header } = Layout;

const QuestionHeader = ({ type = 'Ask a Question' }) => {
  const navigate = useNavigate();
  return (
    <>
      <Header className="header b0">
        <div className="d-flex-between">
          <div className="main-logo">{type !== '' ? type : 'Ask a Question'}</div>
          <div className="header-menu d-flex-a-c">
            <AiOutlineClose
              className="cursor-p"
              size={20}
              onClick={() => navigate('/dashboard/customer')}
            />
          </div>
        </div>
      </Header>
    </>
  );
};

export default QuestionHeader;
