import React, { useState } from 'react';
import Helmet from '../../common/helmet';
import { Tabs } from 'antd';
import AllBlogs from './AllBlogs';
import CreateBlog from './CreateBlog';
import Blogs from '../../public/blogs';
const AdminBlogManagement = props => {
  const [key, setKey] = useState(null);
  return (
    <>
      <Helmet title="Blog Management" />
      <p>Blog management</p>
      <Tabs
        defaultActiveKey="1"
        onChange={e => setKey(e)}
        items={[
          {
            label: 'All Blogs',
            key: '1',
            children: <Blogs keys={key} admin={true}/>,
             
          },
          {
            label: 'Create Blog',
            key: '2',
            children: <CreateBlog />,
          },
        ]}
      />
    </>
  );
};

export default AdminBlogManagement;
