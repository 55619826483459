import React, { useEffect, useState } from 'react';
import useResponsive from '../../hooks/useBreakpoint';

export default function RadioButtonImage({ setCurrentValue, buttonList = [],loginType }) {
  const [checked, setChecked] = useState(loginType);
  console.log("hi hello", loginType);
  const handleCheck = e => {
    setChecked(e.target.value);
    setCurrentValue(e.target.value);
  };
  useEffect(() => {
    setChecked(loginType);
  }, [loginType]);
  const breakPoint = useResponsive();
  return (
    <ul style={{display:"flex",flexDirection:breakPoint == "xl" || breakPoint == "xxl"  ? "row" : "column",justifyContent:"center",alignItems:"center"}}  className="radio-button-image-list">
      {buttonList.map((buttonItem, index) => {
        return (
          <li   key={index}>
            <input
              type="radio"
              name={`Radio Button - ${buttonItem.value}`}
              id={`${index}-${buttonItem.value}`}
              value={buttonItem.value}
              checked={checked === buttonItem.value}
              onChange={e => handleCheck(e)}
            />
            <label htmlFor={`${index}-${buttonItem.value}`}>
              <img style={{width:"100%",height:(breakPoint == "xs" || breakPoint == "sm") && "70px",objectFit:"cover"}} alt={`Radio Button - ${buttonItem.value}`} src={buttonItem.img} loading='lazy' />
            </label>
            <span>{buttonItem.label}</span>
          </li>
        );
      })}
    </ul>
  );
}
