import React, { useState } from 'react';
import Helmet from '../../components/common/helmet';
import AboutImg from '../../assets/images/about.png';
import LinkedInImg from '../../assets/images/linkedIn.svg';
import TwitterImg from '../../assets/images/twitter.svg';
import MailImg from '../../assets/images/mailIcon.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SOCIAL } from '../../helpers/constant';
import { Col, Row } from 'antd';
import Typewriter from 'typewriter-effect';
import { useInView, animated } from '@react-spring/web';

// image import here
import firstSectionImg from '../../assets/images/AboutPage/acc_about3.jpg';
import secondSectionImg from '../../assets/images/AboutPage/acc_about.jpg';
import thirdSectionImg from '../../assets/images/AboutPage/acc_about2.jpg';
import fourthSectionImg from '../../assets/images/AboutPage/acc_about4.jpg';
import fifthSectionImg from '../../assets/images/AboutPage/acc_about5.jpg';
import { animatedMehtod, cuurentBrowser } from '../../helpers/functions';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const AboutUs = props => {



    const [ourExpert] = useState([
        {
            id: 1,
            name: 'Moataz Shokry, CPA',
            img: 'ceo-picture.png',
            position: 'Co-Founder & Chief Executive Officer',
            // linkedInURL: "https://www.linkedin.com/in/moataz-shokry",
            linkedInURL: SOCIAL.linkedIn.moataz,
            twitterURL: SOCIAL.twitter.moataz,
            email: SOCIAL.email.moataz,
            about: "With nearly two decades of experience in finance roles from auditor to CFO, a Bachelor's in Accounting, and a U.S. CPA license, he co-founded an AI-powered platform dedicated to accounting services. He envisions transforming how Canadians receive accounting services over the next decade."
        },
        {
            id: 2,
            name: 'Ahmed Ibrahim, MBA',
            img: 'cfo-picture.png',
            position: 'Co-Founder & Chief Financial Officer',
            // linkedInURL: "https://www.linkedin.com/in/ahmed-ibrahim-aa-41657163/",
            linkedInURL: SOCIAL.linkedIn.ahemad,
            twitterURL: SOCIAL.twitter.ahemad,
            email: SOCIAL.email.ahemad,
            about: "With an MBA and over 16 years in global finance, including roles at top firms and as a Regional Financial Controller, he co-founded an AI-powered platform. His vision is to transform how Canadians find accounting professionals, leveraging his extensive experience and financial acumen."
        },
        {
            id: 3,
            name: 'Abdelmaged Sayed',
            img: 'cto-picture.png',
            position: 'Chief Technology Officer',
            // linkedInURL: "https://www.linkedin.com/in/abdelmageed-sayed-364342106",
            linkedInURL: SOCIAL.linkedIn.abdul,
            twitterURL: SOCIAL.twitter.abdul,
            email: SOCIAL.email.abdul,
            about: "With over seven years of full-stack web development experience, including mastering Angular and .NET, he transformed a web viewer into a top Single Page Application. He bridges technology and business, ensuring alignment between teams, while guiding the company's technological journey toward innovation and excellence."
        },
        {
            id: 4,
            name: 'Sunny Sahijwani',
            img: 'dpd.jpg',
            position: 'Director of Product Development',
            // linkedInURL: "https://www.linkedin.com/in/abdelmageed-sayed-364342106",
            linkedInURL: SOCIAL.linkedIn.abdul,
            twitterURL: SOCIAL.twitter.abdul,
            email: SOCIAL.email.abdul,
            about: "With over seven years of full-stack web development experience, including mastering Angular and .NET, he transformed a web viewer into a top Single Page Application. He bridges technology and business, ensuring alignment between teams, while guiding the company's technological journey toward innovation and excellence."
        },
        {
            id: 5,
            name: 'Daelyn Hardy',
            img: 'BusinessDevelopmentManager.jpg',
            position: 'Business Development Manager',
            // linkedInURL: "https://www.linkedin.com/in/abdelmageed-sayed-364342106",
            linkedInURL: SOCIAL.linkedIn.abdul,
            twitterURL: SOCIAL.twitter.abdul,
            email: SOCIAL.email.abdul,
            about: "Daelyn is skilled in driving business growth, he excels in sales, client relationship management, and strategic planning. Demonstrated expertise in prospecting, B2B coordination, and delivering actionable insights across Canadian and US markets, significantly enhancing client satisfaction, operational efficiency, and securing high-value accounts."
        },
        {
            id: 6,
            name: 'Justin Ma',
            img: 'ocmanager.jpg',
            position: 'Operations and Client Relations Manager',
            // linkedInURL: "https://www.linkedin.com/in/abdelmageed-sayed-364342106",
            linkedInURL: SOCIAL.linkedIn.abdul,
            twitterURL: SOCIAL.twitter.abdul,
            email: SOCIAL.email.abdul,
            about: "In the role of Operations and Client Relations Manager, he focuses on enhancing user experiences through strategic operations planning and market research. His diverse background showcases strong organizational and customer service skills, contributing to the platform's operational efficiency and optimizing client satisfaction to drive growth and success."
        },
        {
            id: 7,
            name: 'Nicole Kolodziej',
            img: 'marketingManager.jpeg',
            position: 'Marketing Manager',
            // linkedInURL: "https://www.linkedin.com/in/abdelmageed-sayed-364342106",
            linkedInURL: SOCIAL.linkedIn.abdul,
            twitterURL: SOCIAL.twitter.abdul,
            email: SOCIAL.email.abdul,
            about: "A marketing professional with experience in digital marketing, social media management, content creation, and event planning. Skilled in photography and videography, she excels at creating engaging visual content and compelling narratives. Passionate about innovation and optimizing campaigns, she is dedicated to delivering impactful results."
        },
        {
            id: 9,
            name: 'Dawinder Singh',
            img: 'backendDev.jpg',
            position: 'Backend Developer',
            // linkedInURL: "https://www.linkedin.com/in/abdelmageed-sayed-364342106",
            linkedInURL: SOCIAL.linkedIn.abdul,
            twitterURL: SOCIAL.twitter.abdul,
            email: SOCIAL.email.abdul,
            about: "As a Senior Backend Developer with extensive experience in  Laravel, API development, and scalable backend systems.Skilled in database architecture, performance optimization, and delivering clean, efficient code. Passionate about creating innovative solutions, driving technical excellence, and collaborating with teams to build impactful products that enhance user experiences and achieve business goals."
        },
        {
            id: 8,
            name: 'Omar Sayed',
            img: 'frontendDeveloper.jpg',
            position: 'Frontend Developer',
            // linkedInURL: "https://www.linkedin.com/in/abdelmageed-sayed-364342106",
            linkedInURL: SOCIAL.linkedIn.abdul,
            twitterURL: SOCIAL.twitter.abdul,
            email: SOCIAL.email.abdul,
            about: "A software developer with a Bachelor's in Computer Science, he specializes in frontend frameworks like Angular and React.js and backend technologies such as Spring Boot and Express. With practical experience from an internship and a focus on web development projects, he is committed to delivering high-quality results in an agile environment."
        },

        {
            id: 10,
            name: 'Prince Kumar',
            img: 'prince_img.png',
            position: 'Frontend Developer',
            // linkedInURL: "https://www.linkedin.com/in/abdelmageed-sayed-364342106",
            linkedInURL: SOCIAL.linkedIn.abdul,
            twitterURL: SOCIAL.twitter.abdul,
            email: SOCIAL.email.abdul,
            about: "Skilled React and MERN Stack Developer specializing in dynamic, user-focused web applications. Proficient in MongoDB, Express.js, React, and Node.js, I craft scalable, high-performance solutions. With expertise in full-stack development, I create interactive front-end components and robust back-end systems, delivering innovative applications that meet business goals and ensure seamless user experiences."
        }
    ]);

  const [sectionOneRef, sectionOneSprings] = useInView(animatedMehtod, {
    rootMargin: cuurentBrowser() === 'Firefox' ? '-40% 0%' : '-75px 0px',
    once: true,
    // amount: 20,
  });
  const [sectionTwoRef, sectionTwoSprings] = useInView(animatedMehtod, {
    rootMargin: cuurentBrowser() === 'Firefox' ? '-40% 0%' : '-75px 0px',
    once: true,
    // amount: 20,
  });
  const [sectionThreeRef, sectionThreeSprings] = useInView(animatedMehtod, {
    rootMargin: cuurentBrowser() === 'Firefox' ? '-40% 0%' : '-75px 0px',
    once: true,
    // amount: 20,
  });
  const [sectionFourRef, sectionFourSprings] = useInView(animatedMehtod, {
    rootMargin: cuurentBrowser() === 'Firefox' ? '-40% 0%' : '-75px 0px',
    once: true,
    // amount: 20,
  });
  const [sectionFiveRef, sectionFiveSprings] = useInView(animatedMehtod, {
    rootMargin: cuurentBrowser() === 'Firefox' ? '-40% 0%' : '-75px 0px',
    once: true,
    // amount: 20,
  });

  return (
    <div className="padding-around about">
      <Helmet title="About Us" />

      <section className="about-section" >
        <Row
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          className="mt50 benefits-row-container"
          gutter={[75]}
        >
          <Col xs={24} sm={24} md={24} lg={11} xl={11}>
            <p className="title">
              Democratizing Accounting Services for <span style={{ color: 'red' }}>Canadians</span>
            </p>
            <p>
              <Typewriter
                options={{
                  autoStart: true,
                  loop: false,
                  cursor: '',
                  strings:
                    'We revolutionize Canada’s accounting industry, making services accessible for all Canadians to thrive.',
                  delay: 15,
                  wrapperClassName: 'typewriterWrapperClass',
                }}
              />
            </p>
          </Col>

          <Col className="benefits-image-wrapper" xs={24} sm={24} md={24} lg={13} xl={13}>
            <div>
              <img
                style={{ width: '100%', cursor: 'pointer' }}
                src={firstSectionImg}
                alt="Accountizer Homepage"
                loading='lazy'
              />
            </div>
          </Col>
        </Row>
      </section>

      <animated.div style={sectionOneSprings} ref={sectionOneRef}>
        <section className="about-section" style={{ position: 'relative', marginTop: '150px' }}>
          {/* <p className="title">We offer solutions for</p>
                <div class="blue-line"></div> */}
          <Row className="mt50 benefits-row-container" gutter={[75]}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={11}
              xl={11}
              className="d-flex-center benefits-description-wrapper"
            >
              <div className="benefits-sub-text">
                <p className="title">
                  <span style={{ color: 'rgb(0, 112, 192)' }}>Revolutionizing</span> the Accounting
                  Industry with an AI-Powered Platform
                </p>
                <p className="sub-text">
                  As seasoned accountants, we've observed how outdated methods like word-of-mouth
                  and unguided directories often fail to connect businesses and individuals with
                  accounting professionals efficiently. While these methods were suitable before the
                  computer age, relying on them in today’s AI-driven era is inconceivable.
                  <br />
                  <br />
                  Our AI-powered platform revolutionizes this process by offering instant matches
                  and tailored real-time insights about the ideal accountant, bookkeeper, or tax
                  preparer, empowering users to make informed decisions and effectively achieve
                  their financial goals.
                </p>
              </div>
            </Col>
            <Col className="benefits-image-wrapper" xs={24} sm={24} md={24} lg={13} xl={13}>
              <div style={{ height: '100%' }}>
                <img loading='lazy' src={secondSectionImg} alt="Accountizer Homepage" className="sectionImg" />
              </div>
            </Col>
          </Row>
        </section>
      </animated.div>

      <animated.div style={sectionTwoSprings} ref={sectionTwoRef}>
        <section className="about-section" style={{ position: 'relative', marginTop: '150px' }}>
          <Row className="mt50 benefits-row-container" gutter={[75]}>
            <Col className="benefits-image-wrapper" xs={24} sm={24} md={24} lg={11} xl={11}>
              <div style={{ height: '100%' }}>
                <img loading='lazy' className="sectionImg" src={thirdSectionImg} alt="Accountizer Homepage" />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={13}
              xl={13}
              className="d-flex-center benefits-description-wrapper"
            >
              <div className="benefits-sub-text">
                <p className="title">
                  <span style={{ color: 'rgb(0, 112, 192)' }}>Democratizing</span>
                  <br />
                  Accounting Services for All
                </p>
                <p className="sub-text">
                  We envision a world where accounting services are accessible, transparent, and
                  user-friendly for everyone, regardless of education or budget. Our platform
                  simplifies tax preparation, bookkeeping, and financial planning, making them easy
                  to navigate.
                  <br />
                  <br />
                  We’re committed to democratizing accounting by removing barriers to professional
                  support and creating an environment where all users feel informed and supported,
                  ensuring greater access to essential accounting assistance.
                </p>
              </div>
            </Col>
          </Row>
        </section>
      </animated.div>

      <animated.div style={sectionThreeSprings} ref={sectionThreeRef}>
        <section className="about-section" style={{ position: 'relative', marginTop: '150px' }}>
          <Row className="mt50 benefits-row-container" gutter={[75]}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={11}
              xl={11}
              className="d-flex-center benefits-description-wrapper"
            >
              <div className="benefits-sub-text">
                <p className="title">
                  <span style={{ color: '#3E80E1' }}>Liberating and Empowering</span> Accounting
                  Professionals and Clients
                </p>
                <p className="sub-text">
                  We are dedicated to liberating accounting professionals and clients from the
                  dominance of big accounting firms by enhancing accessibility and transparency. Our
                  AI-powered platform connects clients with accountants, bookkeepers, and tax
                  preparers tailored to their needs.
                  <br />
                  <br />
                  By fostering collaboration, we encourage competition and innovation, enabling
                  smaller firms and independent professionals to thrive while empowering clients
                  with personalized service and choice.
                </p>
              </div>
            </Col>
            <Col className="benefits-image-wrapper" xs={24} sm={24} md={24} lg={13} xl={13}>
              <div style={{ height: '100%' }}>
                <img loading='lazy' className="sectionImg" src={fourthSectionImg} alt="Accountizer Homepage" />
              </div>
            </Col>
          </Row>
        </section>
      </animated.div>

      <animated.div style={sectionFourSprings} ref={sectionFourRef}>
        <section className="about-section" style={{ marginTop: '150px' }}>
          <p className="title" style={{ textAlign: 'center' }}>
            OUR TEAM
          </p>
          <div class="blue-line" style={{ margin: 'auto' }}></div>
          <div className="team-container mt50" style={{  }}>
            <Row className="scene" style={{justifyContent: 'center' }}>
              {ourExpert.map(item => (
                <Col className="card-container"  xs={24} sm={12} md={12} lg={8} xl={6}>
                  <div class="card">
                    <div class="card__face card__face--front ">
                      <div className="meet-cards">
                        <img
                          loading='lazy'
                          width={150}
                          height={150}
                          src={require(`../../assets/images/${item.img}`)}
                          alt="Accountizer Experts"
                        ></img>
                        <p className="mt20 title">{item.name}</p>
                        <p className="sub-text">{item.position}</p>
                      </div>
                    </div>
                    <div class="card__face card__face--back">
                      <div className="meet-cards_about">
                        <p className="title mt20" style={{ lineHeight: '20px' }}>
                          About {item?.name?.split(' ')?.[0]}
                        </p>
                        <p className="sub-text" style={{ fontSize: '13px' }}>
                          {item.about}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </section>
      </animated.div>

      <animated.div style={sectionFiveSprings} ref={sectionFiveRef}>
        <section className="about-section" style={{ position: 'relative', marginTop: '150px' }}>
          <Row className="mt50 benefits-row-container" gutter={[75]}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={11}
              xl={11}
              className="d-flex-center benefits-description-wrapper"
            >
              <div className="benefits-sub-text">
                <p className="title">
                  <span style={{ color: '#3E80E1' }}>Supported by</span> <br /> Brilliant Catalyst
                  at Ontario Tech University
                </p>
                <p className="sub-text">
                  Accountizer is proudly supported by Brilliant Catalyst, a university- based
                  incubator at Ontario Tech University. Their mission is to inspire and empower
                  change makers by building a community to fuel innovation and elevate Canadian
                  ventures.
                  <br />
                  <br />
                  Believing in our potential to benefit Canadians and boost the economy, Brilliant
                  Catalyst provides us with invaluable resources, mentorship, and networking
                  opportunities. This collaboration accelerates our growth and underscores our
                  commitment to excellence and making a positive impact in Canada.
                </p>
              </div>
            </Col>
            <Col className="benefits-image-wrapper" xs={24} sm={24} md={24} lg={13} xl={13}>
              <div style={{ height: '100%' }}>
                <img
                  loading='lazy'
                  className="sectionImg"
                  style={{ objectFit: 'contain' }}
                  src={fifthSectionImg}
                  alt="Accountizer Homepage"
                />
              </div>
            </Col>
          </Row>
        </section>
      </animated.div>
    </div>
  );
};

export default AboutUs;
