const initialState = {
  isStep1Loading: false,
  step1Data: {},
  step1Success: '',
  isStep2Loading: false,
  step2Success: '',
  isExpertUpdating: false,
  stepExpertMsg: '',
  isCertificateUpdating: false,
  certiMsg: '',
  questionAssigned: false,
  isQuestionUpdating: false,
  questionUpdateMsg: '',
  isStepUpdating: false,
  stepUpdateMsg: '',
  certiErrorMsg: '',
  subCatRecords: [],
  subCatGetRecordsMessage: '',
  fullQuestionCreateMsg: '',
  genratedQuestionId: '',
  questionGenerated: '',
};

const StepsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    /*------------ */
    case 'QUESTION_STEP_1_REQUEST':
      state = {
        ...state,
        isStep1Loading: true,
        step1Data: {},
        step1Success: '',
      };
      break;

    case 'QUESTION_STEP_1_SUCCESS':
      state = {
        ...state,
        isStep1Loading: false,
        step1Data: action.data,
        step1Success: 'success',
      };
      break;

    case 'QUESTION_STEP_1_ERROR':
      state = {
        ...state,
        isStep1Loading: false,
        step1Data: {},
        step1Success: '',
      };
      break;
    /*------------ */
    case 'QUESTION_STEP_2_REQUEST':
      state = {
        ...state,
        isStep2Loading: true,
        step2Success: '',
      };
      break;

    case 'QUESTION_STEP_2_SUCCESS':
      state = {
        ...state,
        isStep2Loading: false,
        step2Success: 'success',
      };
      break;

    case 'QUESTION_STEP_2_ERROR':
      state = {
        ...state,
        isStep2Loading: false,
        step2Success: '',
      };
      break;

    /*------------ */
    case 'QUESTION_STEP_UPDATE_REQUEST':
      state = {
        ...state,
        isStepUpdating: true,
        stepUpdateMsg: '',
        questionAssigned: false,
      };
      break;

    case 'QUESTION_STEP_UPDATE_SUCCESS':
      state = {
        ...state,
        isStepUpdating: false,
        stepUpdateMsg: 'success',
        questionAssigned: !!action.data.expert_id,
      };
      break;

    case 'QUESTION_STEP_UPDATE_ERROR':
      state = {
        ...state,
        isStepUpdating: false,
        stepUpdateMsg: '',
        questionAssigned: false,
      };
      break;

    /*------------ */
    case 'QUESTION_STATUS_UPDATE_REQUEST':
      state = {
        ...state,
        isQuestionUpdating: true,
        questionUpdateMsg: '',
      };
      break;

    case 'QUESTION_STATUS_UPDATE_SUCCESS':
      state = {
        ...state,
        isQuestionUpdating: false,
        questionUpdateMsg: 'success',
      };
      break;

    case 'QUESTION_STATUS_UPDATE_ERROR':
      state = {
        ...state,
        isQuestionUpdating: false,
        questionUpdateMsg: '',
      };
      break;
    /*------------ */
    case 'EXPERT_STEP_UPDATE_REQUEST':
      state = {
        ...state,
        isExpertUpdating: true,
        stepExpertMsg: '',
      };
      break;

    case 'EXPERT_STEP_UPDATE_SUCCESS':
      state = {
        ...state,
        isExpertUpdating: false,
        stepExpertMsg: 'success',
      };
      break;

    case 'EXPERT_STEP_UPDATE_ERROR':
      state = {
        ...state,
        isExpertUpdating: false,
        stepExpertMsg: '',
      };
      break;
    /*------------ */
    case 'EXPERT_CERTIFICATE_UPDATE_REQUEST':
      state = {
        ...state,
        isCertificateUpdating: true,
        certiMsg: '',
      };
      break;

    case 'EXPERT_CERTIFICATE_UPDATE_SUCCESS':
      state = {
        ...state,
        isCertificateUpdating: false,
        certiMsg: 'success',
      };
      break;

    case 'EXPERT_CERTIFICATE_UPDATE_ERROR':
      state = {
        ...state,
        isCertificateUpdating: false,
        certiMsg: 'error',
        certiErrorMsg: action?.data,
      };
      break;

    case 'GET_SUB_CATEGORY_SUCCESS':
      state = {
        ...state,
        subCatRecords: action.data,
        subCatGetRecordsMessage: 'success',
      };
      break;

    case 'Empty_SUB_CATEGORY':
      state = {
        ...state,
        subCatRecords: [],
        subCatGetRecordsMessage: 'success',
      };
      break;

    case 'GET_SUB_CATEGORY_ERROR':
      state = {
        ...state,
        subCatRecords: action.error,
        subCatGetRecordsMessage: 'error',
      };
      break;

    /*------------ */
    case 'GENRATE_QUESTION_WITH_AI_SUCCESS':
      state = {
        ...state,
        questionGenerated: action?.data || '',
      };
      break;
    case 'GENRATE_QUESTION_WITH_AI_ERROR':
      state = {
        ...state,
        questionGenerated: '',
      };
      break;
    /*------------ */
    case 'FULL_QUESTION_CREATE_SUCCESS':
      state = {
        ...state,
        fullQuestionCreateMsg: 'success',
        genratedQuestionId: action?.data?.question_id || '',
      };
      break;

    case 'FULL_QUESTION_CREATE_ERROR':
      state = {
        ...state,
        fullQuestionCreateMsg: 'error',
        genratedQuestionId: '',
      };
      break;

    /*------------ */
    case 'CLEAR_STEPS':
      state = {
        ...state,
        step1Success: '',
        step2Success: '',
        stepUpdateMsg: '',
        stepExpertMsg: '',
        isCertificateUpdating: false,
        certiMsg: '',
        isQuestionUpdating: false,
        questionUpdateMsg: '',
        certiErrorMsg: '',
        subCatGetRecordsMessage: '',
        fullQuestionCreateMsg: '',
        questionGenerated: '',
        genratedQuestionId: '',
      };
      break;

    default:
  }
  return state;
};

export default StepsReducer;
