/* eslint-disable react-hooks/exhaustive-deps */
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Spin,
  Select,
  DatePicker,
  Button,
  InputNumber,
  message,
  Tooltip,
  Space,
  Tabs,
} from 'antd';
import CustomSelect from '../customSelect';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import TabPane from 'antd/es/tabs/TabPane';

const { Option } = Select;
const { TextArea } = Input;

const QuotationModal = props => {
  const dispatch = useDispatch();
  const { qid } = useParams();
  const [form] = Form.useForm();
  const { open, close, questionId, type, questionData, openPayment, setWarningModalVisible } =
    props;
  const { isQuestionLoading, isCommonUpdateLoading, commonMsg } = useSelector(
    state => state.common,
  );
  const { isBillLoading, billPlanList } = useSelector(state => state.expert);
  useEffect(() => {
    dispatch({ type: 'BILL_PLAN_REQUEST' });
  }, [questionId]);

  useEffect(() => {
    if (
      questionData.quotation_detail !== undefined &&
      questionData.quotation_detail !== null &&
      !isBillLoading &&
      billPlanList.length > 0
    ) {
      if (Object.keys(questionData.quotation_detail).length > 0) {
        form.setFieldsValue({
          title: questionData.quotation_detail?.assignment_title,
          disc: questionData.quotation_detail?.details,
          price: questionData.quotation_detail?.price,
          billing_plan_id: parseInt(questionData.quotation_detail?.billing_plan_id),
          cpa: questionData.quotation_detail?.Current_payable_amount,
          percent_fee_due: questionData.quotation_detail?.percentage_of_fee_due,
          // billing_plan_id: 2,
          delivery_date:
            questionData.quotation_detail.delivery_date !== null
              ? moment(questionData.quotation_detail?.delivery_date)
              : '',
        });
      }
    }
  }, [questionData, isBillLoading, billPlanList]);

  useEffect(() => {
    if (!isCommonUpdateLoading && commonMsg === 'success') {
      dispatch({ type: 'CLEAR_COMMON' });
      close(false);
    }
  }, [isCommonUpdateLoading, commonMsg]);

  const onFinish = values => {
    if (type === 'customer') {
      form
        .validateFields()
        .then(values => {
          openPayment(true);
          // // values.delivery_date = moment(values.delivery_date).format("YYYY-MM-DD");
          // let tmpFormData = {
          //     accepted: 1,
          //     question_id: qid
          // };

          // dispatch({
          //     type: 'COMMON_PUT_POST_REQUEST',
          //     reqType: "put",
          //     module: "accept-quotation",
          //     formData: tmpFormData
          // });
        })
        .catch(errorInfo => {});
    } else if (type === 'expert') {
      form
        .validateFields()
        .then(values => {
          values.delivery_date = moment(new Date(values.delivery_date)).format('YYYY-MM-DD');
          // values.delivery_date = moment(values.delivery_date).format("YYYY-MM-DD");
          values.price = parseInt(values.price);
          values['percentage_of_fee_due'] = values.percent_fee_due;
          values['Current_payable_amount'] = values.cpa;
          dispatch({
            type: 'COMMON_PUT_POST_REQUEST',
            reqType: 'post',
            module: 'reply-quotation',
            formData: values,
          });
        })
        .catch(errorInfo => {
          console.log(errorInfo);
        });
    }
  };

  const onCancel = () => {
    let tmpFormData = {
      accepted: 0,
      question_id: qid,
    };
    message.error('Please send message to expert in order to revise the quotation');
    close(false);
    // dispatch({
    //     type: 'COMMON_PUT_POST_REQUEST',
    //     reqType: "put",
    //     module: "accept-quotation",
    //     formData: tmpFormData
    // });
  };

  const optionsList = tmpLocation => {
    let locationOptions = [];
    if (tmpLocation.length > 0) {
      tmpLocation.map(item => {
        locationOptions.push({
          value: item.id,
          label: item.name,
        });
      });
    }
    return locationOptions;
  };

  const selectAfter = (
    <CustomSelect
      placeholder="Select an option"
      size="large"
      defaultValue="CAD"
      style={{ width: 80 }}
    >
      <Option value="CAD">CAD</Option>
    </CustomSelect>
  );
  function setPrice(type) {
    let percent = form.getFieldValue().percent_fee_due;
    let price = form.getFieldValue().price;
    let cpa = form.getFieldValue().cpa;
    if (typeof price !== 'number' || isNaN(price)) return;
    if (type == 'percent') {
      form.setFieldsValue({
        cpa: Math.floor((percent / 100) * price),
      });
    } else if (type == 'cpa') {
      form.setFieldsValue({
        percent_fee_due: Math.floor((cpa / price) * 100),
      });
    } else if (type == 'price') {
      form.setFieldsValue({
        cpa: Math.floor((percent / 100) * price),
      });
    }

    // let e = form.getFieldValue().percent_fee_due;

    // if (typeof e !== 'number' || isNaN(e)) return; // Ensure e is a valid number

    // let price = form.getFieldValue().price; // Ensure correct key is used
    // if (typeof price !== 'number' || isNaN(price)) return; // Ensure price is a valid number

    // let cpa = Math.floor((e / 100) * price); // Correct percentage calculation

    // form.setFieldsValue({ cpa });
  }
  const [disabledAgreedTotalFee, setDisabledAgreedTotalFee] = useState(false);
  const [disabledPercent, setDisabledPercent] = useState(false);
  const [disabledCpa, setDisabledCpa] = useState(false);
  return (
    <Modal
      title={'Quotation Details'}
      className="payment-modal quotation-modal"
      open={open}
      // onOk={onFinish}
      // header={false}
      footer={false}
      onCancel={() => close(false)}
      okText={'Accept & send quotation'}
      okButtonProps={{
        disabled: false,
        loading: false,
      }}
      width={600}
    >
      {isQuestionLoading ? (
        <Spin />
      ) : (
        <>
          <Tabs>
            <TabPane tab="Quotation" key="1">
              <Form
                layout="vertical"
                name="basic"
                form={form}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Row gutter={30}>
                  <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                    <Form.Item
                      style={{ display: 'none' }}
                      name={'question_id'}
                      initialValue={questionId}
                    >
                      <Input defaultValue={questionId} />
                    </Form.Item>
                    <Form.Item
                      label="Assignment Title"
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: 'Please assigment title',
                        },
                        {
                          max: '100',
                          message: 'Maximum 100 characters are allowed',
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        disabled={type !== 'expert' ? true : false}
                        placeholder="Assigment title"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      label="Details of the assignment"
                      name="disc"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter details',
                        },
                        {
                          max: 5000,
                          message: 'Maximum 5000 characters are allowed',
                        },
                      ]}
                    >
                      <TextArea
                        rows={5}
                        disabled={type !== 'expert' ? true : false}
                        placeholder="Assignment details"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    {type !== 'expert' && (
                      <Form.Item label={'Price'} name="price">
                        <Input
                          size="large"
                          disabled={qid ? true : false}
                          placeholder={'$CA'}
                          autoComplete="new-state"
                          addonAfter={'$CA'}
                        />
                      </Form.Item>
                    )}
                    {type === 'expert' && (
                      <Form.Item
                        label={'Agreed total fee'}
                        name="price"
                        rules={[
                          { required: true, message: 'Please enter price' },
                          {
                            pattern: new RegExp(/^[0-9]{1,10}$/),
                            message: 'Please enter valid price',
                          },
                        ]}
                      >
                        <Input.Group style={{ display: 'flex' }} compact>
                          <InputNumber
                            addonAfter={'$CAD'}
                            style={{ width: '80%' }}
                            size="large"
                            disabled={type !== 'expert' ? true : disabledAgreedTotalFee}
                            placeholder={'$CAD'}
                            autoComplete="new-state"
                            onChange={() => {
                              setPrice('price');
                            }}
                            
                          />
                          <div
                            style={{
                              fontSize: 16,
                              color: '#1890ff',
                              width: '10%',
                              backgroundColor: 'gray',
                              height: '2.5rem',
                              cursor: 'pointer',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            onClick={() => {
                              setDisabledAgreedTotalFee(!disabledAgreedTotalFee);
                            }}
                          >
                            <EditOutlined style={{ color: 'white' }} />
                          </div>
                        </Input.Group>
                      </Form.Item>
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      label="Billing plan"
                      name="billing_plan_id"
                      rules={[{ required: true, message: 'Please select location' }]}
                    >
                      <CustomSelect
                        placeholder="Select an option"
                        style={{
                          width: '100%',
                        }}
                        size="large"
                        showSearch
                        loading={isBillLoading}
                        allowClear
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        disabled={type !== 'expert' ? true : false}
                        options={optionsList(billPlanList)}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      rules={[{ message: 'Please enter valid percent', max: 100, min: 0 }]}
                      label="Percentage of fee due"
                      name={'percent_fee_due'}
                    >
                      <Input.Group style={{ display: 'flex' }} compact>
                        <InputNumber
                          addonAfter={'%'}
                          style={{ width: '80%' }}
                          size="large"
                          disabled={type !== 'expert' ? true : disabledPercent}
                          placeholder={'Percentage of fee due'}
                          autoComplete="new-state"
                          onChange={() => {
                            setPrice('percent');
                          }}
                        />
                        <div
                          style={{
                            fontSize: 16,
                            color: '#1890ff',
                            width: '10%',
                            backgroundColor: 'gray',
                            height: '2.5rem',
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          onClick={() => {
                            setDisabledPercent(!disabledPercent);
                          }}
                        >
                          <EditOutlined style={{ color: 'white' }} />
                        </div>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      // rules={[{ required: true, message: 'Please enter valid price', max: 100, min: 0 }]}
                      label="Current payable amount"
                      name={'cpa'}
                    >
                      <Input.Group style={{ display: 'flex' }} compact>
                        <InputNumber
                          addonAfter={'$CA'}
                          style={{ width: '90%' }}
                          size="large"
                          disabled={type !== 'expert' ? true : disabledCpa}
                          placeholder={'Current payable amount'}
                          autoComplete="new-state"
                          onChange={() => {
                            setPrice('cpa');
                          }}
                        />
                        <div
                          style={{
                            fontSize: 16,
                            color: '#1890ff',
                            width: '10%',
                            backgroundColor: 'gray',
                            height: '2.5rem',
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          onClick={() => {
                            setDisabledCpa(!disabledCpa);
                          }}
                        >
                          <EditOutlined style={{ color: 'white' }} />
                        </div>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      label="Expected delivery date"
                      name={'delivery_date'}
                      rules={[
                        {
                          type: 'object',
                          required: true,
                          message: 'Please select date!',
                        },
                      ]}
                    >
                      <DatePicker
                        disabledDate={current => {
                          let customDate = moment().format('YYYY-MM-DD');
                          return current && current < moment(customDate, 'YYYY-MM-DD');
                        }}
                        disabled={type !== 'expert' ? true : false}
                        style={{ width: '100%' }}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {/* <div style={{ marginBottom: '10px' }}>
            Please review the Quotation Details carefully. By pressing Accept Quotation, you are
            entering into a Service Contract. The terms are governed by the Client Agreement (for
            clients) or the Service Provider Agreement (for service providers). Each party is
            responsible for reviewing their respective agreement.
          </div> */}

                {type === 'customer' && (
                  <div className="flex-x center">
                    <Button key="nocalcel" className="mr15" danger onClick={() => onCancel()}>
                      Decline and cancel request
                    </Button>
                    <Button key="accept" type="primary" onClick={() => onFinish()}>
                      Accept quotation
                    </Button>
                  </div>
                )}
                {type === 'expert' && (
                  <div className="flex-x center">
                    <Button key="nocalcel" className="mr15" danger onClick={() => close(false)}>
                      Cancel
                    </Button>
                    <Tooltip
                      title="Please review the Quotation Details carefully. By pressing Accept Quotation, you are
            entering into a Service Contract. The terms are governed by the Client Agreement (for
            clients) or the Service Provider Agreement (for service providers). Each party is
            responsible for reviewing their respective agreement."
                    >
                      <Button
                        key="accept"
                        type="primary"
                        loading={isCommonUpdateLoading}
                        disabled={isCommonUpdateLoading}
                        onClick={() => onFinish()}
                      >
                        Accept & send quotation
                      </Button>
                    </Tooltip>
                  </div>
                )}
              </Form>
            </TabPane>
            <TabPane tab="History" key="2"></TabPane>
          </Tabs>
        </>
      )}
    </Modal>
  );
};

export default QuotationModal;
