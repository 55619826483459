import React, { useEffect, useState } from 'react';
import Helmet from '../../common/helmet';
import { Row, Col, Input, Tabs, Table, Space, Popover, Rate, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { formatDateTo } from '../../../helpers/functions';
import DeleteModal from '../../common/modal/deleteModal';

const { Search } = Input;
const { Column } = Table;

let filterParams = { search: '' };

const tabItems = [
  {
    key: '1',
    name: 'new',
    label: `New`,
  },
  {
    key: '2',
    name: 'approve',
    label: `Approve`,
  },
  {
    key: '3',
    name: 'rejected',
    label: `Rejected`,
  },
];

const AdminReviewsManagement = props => {
  const dispatch = useDispatch();
  const { reviewsList, isReviewLoading, isDeleteReviewLoading } = useSelector(state => state.admin);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const { isUpdateReviewsLoading } = useSelector(state => state.requests);

  const [search, setSearch] = useState({ find: '' });
  const [tableData, setTableData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [selectedTab, setSelectedTab] = useState('new');

  useEffect(() => {
    getAllRequests(selectedTab);
  }, [isDeleteReviewLoading, isUpdateReviewsLoading, selectedTab]);

  const getAllRequests = param => {
    dispatch({ type: 'ADMIN_REVIEWS_REQUEST', param: param });
  };

  const doRefresh = () => {
    getAllRequests(selectedTab);
  };

  useEffect(() => {
    if (!isReviewLoading && reviewsList?.data?.length > 0) {
      let tmpArray = [];
      reviewsList?.data?.map(item => {
        let tmpObj = { ...item };
        tmpObj.clientName = `${item?.customer?.first_name} ${item?.customer?.last_name}`;
        tmpObj.expertName = `${item?.expert?.first_name} ${item?.expert?.last_name}`;
        tmpObj.categoryName = item?.category ? item?.category?.name : '';
        tmpObj.seniorityType = item?.seniority ? item?.seniority?.name : '';
        tmpObj.date = formatDateTo(item?.created_at);

        return tmpArray.push(tmpObj);
      });

      setTableData(tmpArray);
    } else if (!isReviewLoading && reviewsList.length === 0) {
      setTableData([]);
    }
  }, [isDeleteReviewLoading, isUpdateReviewsLoading, isReviewLoading, selectedTab]);

  useEffect(() => {
    const filteredData = tableData?.filter(el =>
      el.review.toLowerCase().includes(search?.find?.toLowerCase()),
    );
    setFilteredTableData(filteredData);
    if (!search.find.length) {
      setFilteredTableData(tableData);
    }
  }, [search, tableData, filteredTableData]);

  const handleSearch = e => {
    setSearch({ ...search, find: e.target.value });
  };

  const onTabChange = e => {
    if (e === '1') {
      setSelectedTab('new');
    } else if (e === '2') {
      setSelectedTab('active');
    } else if (e === '3') {
      setSelectedTab('deactive');
    }
  };

  const handleReviewAccept = data => {
    dispatch({
      type: 'UPDATE_REVIEW_STATUS',
      formData: {
        id: data?.id,
        data: {
          review: data?.review,
          rating: data?.rating,
          status: 'active',
        },
      },
    });
  };

  const handleReviewReject = data => {
    dispatch({
      type: 'UPDATE_REVIEW_STATUS',
      formData: {
        id: data?.id,
        data: {
          review: data?.review,
          rating: data?.rating,
          status: 'deactive',
        },
      },
    });
  };

  const content = data => {
    setCurrentRecord(data);
    return (
      <div style={{ minWidth: '150px' }}>
        {selectedTab === 'new' && (
          <>
            <div
              style={{ color: 'black' }}
              className="action-popover"
              onClick={() => handleReviewAccept(data)}
            >
              Accept
            </div>
            <div
              style={{ color: 'black' }}
              className="action-popover"
              onClick={() => handleReviewReject(data)}
            >
              Reject
            </div>
          </>
        )}
        <div className="action-popover" onClick={() => setShowDeleteModal(true)}>
          Delete
        </div>
      </div>
    );
  };

  return (
    <div className="admin-container">
      <Helmet title="Requests" />
      <p className="title">Reviews</p>
      <div>
        <Row className="nav-sub-header mt-10">
          <Col xs={24} sm={24} md={18} lg={14} xl={12}>
            <Search
              defaultValue={filterParams['search']}
              size="large"
              allowClear
              placeholder="Search by review description."
              enterButton
              onChange={handleSearch}
            />
          </Col>
        </Row>
      </div>
      <div className="mt15">
        <Tabs defaultActiveKey="1" items={tabItems} onChange={onTabChange} />
        <Table
          pagination={{
            pageSize: 4,
            showSizeChanger: false,
          }}
          loading={isReviewLoading}
          dataSource={filteredTableData}
        >
          <Column
            title="Review"
            colSpan={1}
            dataIndex="rating"
            key="rating"
            render={(val, rec, idx) => <Rate value={val} disabled />}
          />
          <Column colSpan={1} title="Reviewed By" dataIndex="clientName" key="clientName" />
          <Column colSpan={1} title="Submitted to" dataIndex="expertName" key="submittedTo" />
          <Column
            colSpan={1}
            title="Description"
            dataIndex="review"
            key="review"
            render={data => (
              <Tooltip title={data} placement="left">
                <div>{data.length > 30 ? data.substring(0, 30) + '...' : data}</div>
              </Tooltip>
            )}
          />
          <Column
            colSpan={1}
            title="Action"
            key="action"
            render={data => (
              <Space className="option-logo-wrapper" size="middle">
                <Popover content={() => content(data)} trigger="hover">
                  <BiDotsVerticalRounded
                    style={{ rotate: '90deg' }}
                    size={18}
                    className="cursor-p"
                  />
                </Popover>
              </Space>
            )}
          />
        </Table>
      </div>
      {showDeleteModal && (
        <DeleteModal
          visible={showDeleteModal}
          setVisible={setShowDeleteModal}
          subTitle="You want to delete this review"
          deleteModule="review"
          rowData={currentRecord}
          doRefresh={() => doRefresh()}
        />
      )}
    </div>
  );
};

export default AdminReviewsManagement;
