import React from 'react';
import { Row } from 'antd';
import { convertTimeToTimezone, dynamicMessagingCard, formatDateTo } from '../../helpers/functions';
import { MESSAGE_TYPE } from '../../helpers/constant';


const RightMessage = ({ m, handleMessageClick, params }) => {
  const { body, created_at, attachment_type, message_type } = m;
  let msg = body;
  let attachment = attachment_type;
  if (
    message_type == MESSAGE_TYPE.JOIN_MEETING ||
    message_type == MESSAGE_TYPE.PICK_DATE_AND_TIME ||
    message_type == MESSAGE_TYPE.SCHEDULE_MEETING
  ) {
    msg = JSON.parse(body);
    attachment =
      (message_type == MESSAGE_TYPE.JOIN_MEETING && 'zoom') ||
      (message_type == MESSAGE_TYPE.PICK_DATE_AND_TIME && 'calendar') ||
      (message_type == MESSAGE_TYPE.SCHEDULE_MEETING && 'schedule');
  }
  return (
    <div className="message-container-Right" data-indexKey={m?.id}>
      <Row style={{ display: 'flex', justifyContent: 'right' }}>
        <div style={{ display: 'flex', justifyContent: 'right', maxWidth: '70%' }}>
          <div>
            <div
              onClick={() => handleMessageClick(message_type, msg,m)}
              style={{ cursor: 'pointer' }}
            >
              {dynamicMessagingCard(attachment, msg, '', m, params )}
            </div>
            <div className="message-time">
              {/* <p>{formatDateTo(created_at, 'hh:mm A')}</p> */}
              <p>{convertTimeToTimezone(created_at)}</p>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default RightMessage;
