import { Button, Modal, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const RequestRejectModal = props => {
  const { visible, setShowModal, rawData, variant } = props;
  const [reviewText, setReviewText] = useState('');
  const { acceptOrRejectExpertRequestStatus, isAcceptOrRejectExpertRequestLoading } = useSelector(
    state => state.requests,
  );
  const currentLocation = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      acceptOrRejectExpertRequestStatus === 'success' &&
      currentLocation.pathname !== '/dashboard/expert/request'
    ) {
      setTimeout(() => {
        window.location.href =
          variant === 'expert' ? '/dashboard/expert/request' : '/dashboard/customer';
      }, 3000);
      dispatch({ type: 'RESET_REQUEST_REJECT_STATUS' });
    }
  }, [isAcceptOrRejectExpertRequestLoading]);

  const rejectRequest = () => {
    if (reviewText && rawData?.id) {
      dispatch({
        type: 'POST_ACCEPT_OR_REJECT_EXPERT_REQUEST',
        formData: {
          question_id: rawData?.id,
          accepted: 0,
          reasons_for_rejection: reviewText,
        },
      });
      setShowModal(false);
    } else {
      message.error('Reason for rejection cannot be empty');
    }
  };

  return (
    <Modal closable={false} centered footer={null} open={visible} width="600px">
      <div className="review-modal-container">
        {variant === 'expert' ? (
          <div className="modal-title-wrapper">
            <div className="modal-title-text">
              Reject {rawData?.user?.first_name} {rawData?.user?.last_name} to be your client?
            </div>
          </div>
        ) : (
          <div className="modal-title-wrapper">
            <div className="modal-title-text">
              Please explain why none of the options meet your needs, so we can help you better.
            </div>
          </div>
        )}

        <div className="modal-text-area-wrapper">
          {variant === 'expert' ? <div>Describe your reason for rejection.</div> : ''}
          <TextArea
            value={reviewText}
            onChange={e => setReviewText(e.target.value)}
            autoSize={{ minRows: 7, maxRows: 7 }}
          />
        </div>
        <div className="modal-footer-button-area">
          <Button size="large" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button size="large" danger onClick={() => rejectRequest()}>
            {variant === 'expert' ? 'Reject Request' : 'Submit'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
