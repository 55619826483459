const initialState = {
  isRequestLoading: true,
  requests: [],

  isRequestsHistoryLoading: true,
  requestsHistory: [],

  isQuestionRequestStatusLoading: true,
  requestQuestionStatus: [],

  isExpertActiveRequestStatusLoading: true,
  expertActiveRequests: [],

  isExpertNewRequestStatusLoading: true,
  expertNewRequests: [],

  isAcceptOrRejectExpertRequestLoading: true,
  acceptOrRejectExpertRequest: [],
  acceptOrRejectExpertRequestStatus: '',

  isMessageThreadLoading: true,
  messageThreadData: [],

  isPostMessageLoading: true,
  postMessageData: [],

  isExpertDetailLoading: true,
  expertDetailById: [],

  isExpertExperienceDetailLoading: true,
  expertExperienceDetailById: [],

  isExpertLicensesDetailLoading: true,
  expertLicensesDetailById: [],

  isExpertDegreesDetailLoading: true,
  expertDegreesDetailById: [],

  isExpertServiceAndRateDetailLoading: true,
  expertServiceAndRateDetailById: [],

  isThreadBySubjectIdLoading: true,
  threadBySubjectId: [],

  isUnreadMessagesLoading: true,
  unreadMessages: [],

  isMarkAsReadMessagesLoading: true,
  markAsReadMessages: [],

  isSelectedTabGroupLoading: true,
  selectedTabGroup: '',

  isCreateNewThreadLoading: false,
  createNewThread: [],

  isImageUploadingToDBLoading: false,
  isImageUploadFailed: false,
  imageUrlFromDB: '',

  isReviewsLoading: false,
  reviewsByUserId: [],

  isPostReviewsLoading: false,
  postedReviewsByUser: [],

  isUpdateReviewsLoading: false,
  updateReview: [],

  isGetExpertRequestHistoryLoading: false,
  expertRequestHistory: [],

  isUpdateQuestionStatusLoading: false,
  updateQuestionStatus: [],

  isGetQuestionCountLoading: false,
  getQuestionCountFromApi: 0,

  isGetNewQuestionCountLoading: false,
  getNewQuestionCountFromApi: 0,

  isGetAllQuestionCountLoading: false,
  getAllQuestionCountFromApi: 0,

  isGetUserCountLoading: false,
  getUserCountFromApi: [],

  isGetExpertRequestCountLoading: false,
  getExpertRequestCountFromApi: [],

  isAttachmentByThreadIdLoading: false,
  attachmentByThreadId: [],

  zoomMeetingSignature: [],
  zommMeetingSignatureLoading: false,
  isAddNewMessageInThread: false,
  addedMessagesLastIndexId: 0,
  timezoneRecord: [],
  unReadMessagesRec: []
};

const RequestReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    /*------------ */
    case 'GET_REQUESTS':
      state = {
        ...state,
        isRequestLoading: true,
      };
      break;

    case 'GET_REQUESTS_SUCCESS':
      state = {
        ...state,
        isRequestLoading: false,
        requests: action.data,
      };
      break;

    case 'GET_REQUESTS_ERROR':
      state = {
        ...state,
        isRequestLoading: false,
        requests: [],
      };
      break;

    /*------------ */
    case 'GET_QUESTION_REQUEST_STATUS':
      state = {
        ...state,
        isQuestionRequestStatusLoading: true,
      };
      break;

    case 'GET_QUESTION_REQUEST_STATUS_SUCCESS':
      state = {
        ...state,
        isQuestionRequestStatusLoading: false,
        requestQuestionStatus: action.data,
      };
      break;

    case 'GET_QUESTION_REQUEST_STATUS_ERROR':
      state = {
        ...state,
        isQuestionRequestStatusLoading: false,
        requestQuestionStatus: [],
      };
      break;

    /*------------ */
    case 'GET_EXPERT_ACTIVE_REQUEST':
      state = {
        ...state,
        isExpertActiveRequestStatusLoading: true,
      };
      break;

    case 'GET_EXPERT_ACTIVE_REQUEST_STATUS_SUCCESS':
      state = {
        ...state,
        isExpertActiveRequestStatusLoading: false,
        expertActiveRequests: action.data,
      };
      break;

    case 'GET_EXPERT_ACTIVE_REQUEST_STATUS_ERROR':
      state = {
        ...state,
        isExpertActiveRequestStatusLoading: false,
        expertActiveRequests: [],
      };
      break;

    /*------------ */
    case 'GET_EXPERT_NEW_REQUEST':
      state = {
        ...state,
        isExpertNewRequestStatusLoading: true,
      };
      break;

    case 'GET_EXPERT_NEW_REQUEST_STATUS_SUCCESS':
      state = {
        ...state,
        isExpertNewRequestStatusLoading: false,
        expertNewRequests: action.data,
      };
      break;

    case 'GET_EXPERT_NEW_REQUEST_REQUEST_STATUS_ERROR':
      state = {
        ...state,
        isExpertNewRequestStatusLoading: false,
        expertNewRequests: [],
      };
      break;

    /*------------ */
    case 'POST_ACCEPT_OR_REJECT_EXPERT_REQUEST':
      state = {
        ...state,
        isAcceptOrRejectExpertRequestLoading: true,
        acceptOrRejectExpertRequestStatus: 'loading',
      };
      break;

    case 'POST_ACCEPT_OR_REJECT_EXPERT_REQUEST_STATUS_SUCCESS':
      state = {
        ...state,
        isAcceptOrRejectExpertRequestLoading: false,
        acceptOrRejectExpertRequest: action.data,
        acceptOrRejectExpertRequestStatus: 'success',
      };
      break;

    case 'POST_ACCEPT_OR_REJECT_EXPERT_REQUEST_STATUS_ERROR':
      state = {
        ...state,
        isAcceptOrRejectExpertRequestLoading: false,
        acceptOrRejectExpertRequest: [],
        acceptOrRejectExpertRequestStatus: 'failed',
      };
      break;

    /*------------ */
    case 'GET_REQUESTS_HISTORY':
      state = {
        ...state,
        isRequestsHistoryLoading: true,
      };
      break;

    case 'GET_REQUESTS_HISTORY_SUCCESS':
      state = {
        ...state,
        isRequestsHistoryLoading: false,
        requestsHistory: action.data,
      };
      break;

    case 'GET_REQUESTS_HISTORY_ERROR':
      state = {
        ...state,
        isRequestsHistoryLoading: false,
        requestsHistory: [],
      };
      break;

    /*------------ */
    case 'GET_MESSAGE_THREAD':
      state = {
        ...state,
        isMessageThreadLoading: true,
      };
      break;

    case 'GET_MESSAGE_THREAD_SUCCESS':
      state = {
        ...state,
        isMessageThreadLoading: false,
        messageThreadData: action.data,
      };
      break;

    case 'GET_MESSAGE_THREAD_ERROR':
      state = {
        ...state,
        isMessageThreadLoading: false,
        messageThreadData: [],
      };
      break;

    /*------------ */
    case 'POST_MESSAGE':
      state = {
        ...state,
        isPostMessageLoading: true,
      };
      break;

    case 'POST_MESSAGE_STATUS_SUCCESS':
      state = {
        ...state,
        isPostMessageLoading: false,
        postMessageData: action.data,
        threadBySubjectId: {
          ...state.threadBySubjectId,
          messages: action.data?.myMessage?.concat(state.threadBySubjectId.messages),
        },
      };
      break;

    case 'POST_MESSAGE_STATUS_ERROR':
      state = {
        ...state,
        isPostMessageLoading: false,
        postMessageData: [],
      };
      break;

    /*------------ */
    case 'GET_EXPERT_DETAIL_BY_ID':
      state = {
        ...state,
        isExpertDetailLoading: true,
      };
      break;

    case 'GET_EXPERT_DETAIL_BY_ID_SUCCESS':
      state = {
        ...state,
        isExpertDetailLoading: false,
        expertDetailById: action.data,
      };
      break;

    case 'GET_EXPERT_DETAIL_BY_ID_ERROR':
      state = {
        ...state,
        isExpertDetailLoading: false,
        expertDetailById: [],
      };
      break;

    /*------------ */
    case 'GET_EXPERT_EXPERIENCE_DETAIL_BY_ID':
      state = {
        ...state,
        isExpertExperienceDetailLoading: true,
      };
      break;

    case 'GET_EXPERT_EXPERIENCE_DETAIL_BY_ID_SUCCESS':
      state = {
        ...state,
        isExpertExperienceDetailLoading: false,
        expertExperienceDetailById: action.data,
      };
      break;

    case 'GET_EXPERT_EXPERIENCE_DETAIL_BY_ID_ERROR':
      state = {
        ...state,
        isExpertExperienceDetailLoading: false,
        expertExperienceDetailById: [],
      };
      break;

    /*------------ */
    case 'GET_EXPERT_LICENSES_DETAIL_BY_ID':
      state = {
        ...state,
        isExpertLicensesDetailLoading: true,
      };
      break;

    case 'GET_EXPERT_LICENSES_DETAIL_BY_ID_SUCCESS':
      state = {
        ...state,
        isExpertLicensesDetailLoading: false,
        expertLicensesDetailById: action.data,
      };
      break;

    case 'GET_EXPERT_LICENSES_DETAIL_BY_ID_ERROR':
      state = {
        ...state,
        isExpertLicensesDetailLoading: false,
        expertLicensesDetailById: [],
      };
      break;

    /*------------ */
    case 'GET_EXPERT_DEGREES_DETAIL_BY_ID':
      state = {
        ...state,
        isExpertDegreesDetailLoading: true,
      };
      break;

    case 'GET_EXPERT_DEGREES_DETAIL_BY_ID_SUCCESS':
      state = {
        ...state,
        isExpertDegreesDetailLoading: false,
        expertDegreesDetailById: action.data,
      };
      break;

    case 'GET_EXPERT_DEGREES_DETAIL_BY_ID_ERROR':
      state = {
        ...state,
        isExpertDegreesDetailLoading: false,
        expertDegreesDetailById: [],
      };
      break;

    /*------------ */
    case 'GET_EXPERT_SERVICE_AND_RATE_DETAIL_BY_ID':
      state = {
        ...state,
        isExpertServiceAndRateDetailLoading: true,
      };
      break;

    case 'GET_EXPERT_SERVICE_AND_RATE_DETAIL_BY_ID_SUCCESS':
      state = {
        ...state,
        isExpertServiceAndRateDetailLoading: false,
        expertServiceAndRateDetailById: action.data,
      };
      break;

    case 'GET_EXPERT_SERVICE_AND_RATE_DETAIL_BY_ID_ERROR':
      state = {
        ...state,
        isExpertServiceAndRateDetailLoading: false,
        expertServiceAndRateDetailById: [],
      };
      break;

    /*------------ */
    case 'GET_THREAD_BY_SUBJECT_ID':
      state = {
        ...state,
        isThreadBySubjectIdLoading: true,
      };
      break;

    case 'GET_THREAD_BY_SUBJECT_ID_SUCCESS':
      state = {
        ...state,
        isThreadBySubjectIdLoading: false,
        threadBySubjectId: action.data,
      };
      break;

    case 'GET_THREAD_BY_SUBJECT_ID_ERROR':
      state = {
        ...state,
        isThreadBySubjectIdLoading: false,
        threadBySubjectId: action,
      };
      break;

    case 'ADD_NEW_MESSAGE_IN_THREAD':
      state = {
        ...state,
        isAddNewMessageInThread: true,
        threadBySubjectId: {
          ...state.threadBySubjectId,
          messages: action.data.concat(state.threadBySubjectId.messages),
        },
        addedMessagesLastIndexId: (action.data?.length) ? action.data[action.data.length - 1]?.id : 0,
      };
      break;
    case 'ADD_NEW_MESSAGE_IN_THREAD_ERROR':
      state = {
        ...state,
        isAddNewMessageInThread: false,
        // addedMessagesLastIndexId: 0,
      };
      break;
    case 'ADD_NEW_UNREAD_MESSAGE':
      state = {
        ...state,
        unReadMessagesRec: action.data,
        // addedMessagesLastIndexId: 0,
      };
      break;

    /*------------ */
    case 'GET_ALL_UNREAD_MESSAGES':
      state = {
        ...state,
        isUnreadMessagesLoading: true,
      };
      break;

    case 'GET_ALL_UNREAD_MESSAGES_SUCCESS':
      state = {
        ...state,
        isUnreadMessagesLoading: false,
        unreadMessages: action.data,
        unReadMessagesRec: action.data,

      };
      break;

    case 'GET_ALL_UNREAD_MESSAGES_ERROR':
      state = {
        ...state,
        isUnreadMessagesLoading: false,
        unreadMessages: [],
        unReadMessagesRec: [],

      };
      break;

    /*------------ */
    case 'MARK_ALL_MESSAGES_AS_READ':
      state = {
        ...state,
        isMarkAsReadMessagesLoading: true,
      };
      break;

    case 'MARK_ALL_MESSAGES_AS_READ_SUCCESS':
      state = {
        ...state,
        isMarkAsReadMessagesLoading: false,
        markAsReadMessages: action.data,
      };
      break;

    case 'MARK_ALL_MESSAGES_AS_READ_ERROR':
      state = {
        ...state,
        isMarkAsReadMessagesLoading: false,
        markAsReadMessages: [],
      };
      break;

    /*------------ */
    case 'SET_SELECTED_TAB_GROUP':
      state = {
        ...state,
      };
      break;

    case 'SET_SELECTED_TAB_GROUP_SUCCESS':
      state = {
        ...state,
        selectedTabGroup: action.data,
      };
      break;

    /*------------ */
    case 'POST_NEW_MESSAGE_THREAD':
      state = {
        ...state,
        isCreateNewThreadLoading: false,
      };
      break;

    case 'POST_NEW_MESSAGE_THREAD_SUCCESS':
      state = {
        ...state,
        isCreateNewThreadLoading: true,
        createNewThread: action.data,
      };
      break;

    case 'POST_NEW_MESSAGE_THREAD_ERROR':
      state = {
        ...state,
        isCreateNewThreadLoading: false,
        createNewThread: [],
      };
      break;

    /*------------ */
    case 'UPLOAD_IMAGE_TO_DB':
      state = {
        ...state,
        isImageUploadingToDBLoading: true,
        isImageUploadFailed: false,
      };
      break;

    case 'UPLOAD_IMAGE_TO_DB_SUCCESS':
      state = {
        ...state,
        isImageUploadingToDBLoading: false,
        imageUrlFromDB: action.data,
        isImageUploadFailed: false,
      };
      break;

    case 'UPLOAD_IMAGE_TO_DB_ERROR':
      state = {
        ...state,
        isImageUploadingToDBLoading: false,
        imageUrlFromDB: '',
        isImageUploadFailed: true,
      };
      break;

    /*------------ */
    case 'GET_REVIEWS_BY_ID':
      state = {
        ...state,
        isReviewsLoading: true,
      };
      break;

    case 'GET_REVIEWS_BY_ID_SUCCESS':
      state = {
        ...state,
        isReviewsLoading: false,
        reviewsByUserId: action.data,
      };
      break;

    case 'GET_REVIEWS_BY_ID_ERROR':
      state = {
        ...state,
        isReviewsLoading: false,
        reviewsByUserId: [],
      };
      break;

    /*------------ */
    case 'ADD_EXPERT_REVIEW':
      state = {
        ...state,
        isPostReviewsLoading: true,
      };
      break;

    case 'ADD_EXPERT_REVIEW_SUCCESS':
      state = {
        ...state,
        isPostReviewsLoading: false,
        postedReviewsByUser: action.data,
      };
      break;

    case 'ADD_EXPERT_REVIEW_ERROR':
      state = {
        ...state,
        isPostReviewsLoading: false,
        postedReviewsByUser: [],
      };
      break;

    /*------------ */
    case 'UPDATE_REVIEW_STATUS':
      state = {
        ...state,
        isUpdateReviewsLoading: true,
      };
      break;

    case 'UPDATE_REVIEW_STATUS_SUCCESS':
      state = {
        ...state,
        isUpdateReviewsLoading: false,
        updateReview: action.data,
      };
      break;

    case 'UPDATE_REVIEW_STATUS_ERROR':
      state = {
        ...state,
        isUpdateReviewsLoading: false,
        updateReview: [],
      };
      break;
    /*------------ */
    case 'GET_EXPERT_REQUEST_HISTORY_VIEW':
      state = {
        ...state,
        isGetExpertRequestHistoryLoading: true,
      };
      break;

    case 'GET_EXPERT_REQUEST_HISTORY_VIEW_SUCCESS':
      state = {
        ...state,
        isGetExpertRequestHistoryLoading: false,
        expertRequestHistory: action.data,
      };
      break;

    case 'GET_EXPERT_REQUEST_HISTORY_VIEW_ERROR':
      state = {
        ...state,
        isGetExpertRequestHistoryLoading: false,
        expertRequestHistory: [],
      };
      break;

    /*------------ */
    case 'UPDATE_QUESTION_STATUS':
      state = {
        ...state,
        isUpdateQuestionStatusLoading: true,
      };
      break;

    case 'UPDATE_QUESTION_STATUS_SUCCESS':
      state = {
        ...state,
        isUpdateQuestionStatusLoading: false,
        updateQuestionStatus: action.data,
      };
      break;

    case 'UPDATE_QUESTION_STATUS_ERROR':
      state = {
        ...state,
        isUpdateQuestionStatusLoading: false,
        updateQuestionStatus: [],
      };
      break;

    /*------------ */
    case 'GET_USER_COUNT':
      state = {
        ...state,
        isGetUserCountLoading: true,
      };
      break;

    case 'GET_USER_COUNT_SUCCESS':
      state = {
        ...state,
        isGetUserCountLoading: false,
        getUserCountFromApi: action.data,
      };
      break;

    case 'GET_USER_COUNT_ERROR':
      state = {
        ...state,
        isGetUserCountLoading: false,
        getUserCountFromApi: [],
      };
      break;

    /*------------ */
    case 'GET_QUESTION_COUNT':
      state = {
        ...state,
        isGetQuestionCountLoading: true,
      };
      break;

    case 'GET_QUESTION_COUNT_SUCCESS':
      state = {
        ...state,
        isGetQuestionCountLoading: false,
        getQuestionCountFromApi: action.data,
      };
      break;

    case 'GET_QUESTION_COUNT_ERROR':
      state = {
        ...state,
        isGetQuestionCountLoading: false,
        getQuestionCountFromApi: [],
      };
      break;

    /*------------ */
    case 'GET_NEW_QUESTION_COUNT':
      state = {
        ...state,
        isGetNewQuestionCountLoading: true,
      };
      break;

    case 'GET_NEW_QUESTION_COUNT_SUCCESS':
      state = {
        ...state,
        isGetNewQuestionCountLoading: false,
        getNewQuestionCountFromApi: action.data,
      };
      break;

    case 'GET_NEW_QUESTION_COUNT_ERROR':
      state = {
        ...state,
        isGetNewQuestionCountLoading: false,
        getNewQuestionCountFromApi: 0,
      };
      break;

    /*------------ */
    case 'GET_EXPERT_REQUEST_COUNT':
      state = {
        ...state,
        isGetExpertRequestCountLoading: true,
      };
      break;

    case 'GET_EXPERT_REQUEST_COUNT_SUCCESS':
      state = {
        ...state,
        isGetExpertRequestCountLoading: false,
        getExpertRequestCountFromApi: action.data,
      };
      break;

    case 'GET_EXPERT_REQUEST_COUNT_ERROR':
      state = {
        ...state,
        isGetExpertRequestCountLoading: false,
        getExpertRequestCountFromApi: 0,
      };
      break;

    /*------------ */
    case 'GET_ALL_QUESTION_COUNT':
      state = {
        ...state,
        isGetAllQuestionCountLoading: true,
      };
      break;

    case 'GET_ALL_QUESTION_COUNT_SUCCESS':
      state = {
        ...state,
        isGetAllQuestionCountLoading: false,
        getAllQuestionCountFromApi: action.data,
      };
      break;

    case 'GET_ALL_QUESTION_COUNT_ERROR':
      state = {
        ...state,
        isGetAllQuestionCountLoading: false,
        getAllQuestionCountFromApi: 0,
      };
      break;

    /*------------ */
    case 'GET_ATTACHMENTS_BY_THREAD_ID':
      state = {
        ...state,
        isAttachmentByThreadIdLoading: true,
      };
      break;

    case 'GET_ATTACHMENTS_BY_THREAD_ID_SUCCESS':
      state = {
        ...state,
        isAttachmentByThreadIdLoading: false,
        attachmentByThreadId: action.data,
      };
      break;

    case 'GET_ATTACHMENTS_BY_THREAD_ID_ERROR':
      state = {
        ...state,
        isAttachmentByThreadIdLoading: false,
        attachmentByThreadId: [],
      };
      break;
    case 'RESET_REQUEST_REJECT_STATUS':
      state = {
        acceptOrRejectExpertRequestStatus: '',
      };
      break;

    // -------------------//
    case 'ZOOM_MEETING_SIGNATURE_SUCCESS':
      state = {
        ...state,
        zoomMeetingSignature: action.data,
        zommMeetingSignatureLoading: true,
      };
      break;
    case 'ZOOM_MEETING_SIGNATURE_EMPTY':
      state = {
        ...state,
        zoomMeetingSignature: [],
        zommMeetingSignatureLoading: false,
      };
      break;
    //---------------------//
    case 'TIMEZONE_RECORD':
      state = {
        ...state,
        timezoneRecord: action.data,
      };
      break;
    case 'TIMEZONE_RECORD_EMPTY':
      state = {
        ...state,
        timezoneRecord: [],
      };
      break;
    case 'SET_REQUEST_STATE_VALUE':
      state = {
        ...state,
        [action.key]: action.value
      }
      break;
    default:
  }
  return state;
};

export default RequestReducer;
