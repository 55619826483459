import React from 'react';
export const useUnload = (fn, isValueChanged) => {
  const cb = React.useRef(fn);

  React.useEffect(() => {
    const onUnload = cb.current;
    window.addEventListener('beforeunload', onUnload);
    if (!isValueChanged) {
      window.removeEventListener('beforeunload', onUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [cb, isValueChanged]);
};

export const beforeLoad = e => {
  e.preventDefault();
};
