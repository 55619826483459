//Public routes without login access
import Login from '../components/auth/login';
import SetPassword from '../components/auth/setPassword';
import SignUp from '../components/auth/signup';
import ForgetPassword from '../components/auth/forget-password';

//Customer
import Profile from '../components/profile';
import ChangePassword from '../components/profile/changePassword';
import Request from '../components/customer';

// Admin
import Template from '../components/layouts/Template';
import AdminRequests from '../components/admin/requests';
import AboutUs from '../components/public/about';
import Contact from '../components/public/contact';
import FaqPage from '../components/public/FaqPage';
import FindAnExpert from '../components/pages/FindAnExpert';
import ExpertHomePage from '../components/expert/';
import ExpertSignup from '../components/auth/expertSignup';
import ExpertRequest from '../components/expert/steps/expertRequest';
import { RequestList } from '../components/customer/requestList';
import { RequestHistory } from '../components/customer/requestHistory';
import MyRequestDetail from '../components/customer/requestDetail';
import RequestHistoryDetail from '../components/customer/requestHistoryDetail';
import { ExpertRequestList } from '../components/expert/expertRequestList';
import { ExpertActiveRequestDetail } from '../components/expert/expertActiveRequestDetail';
import { ExpertNewRequestDetail } from '../components/expert/expertNewRequestDetail';
import { ExpertDetails } from '../components/expert/expertDetail';
import { RequestMessaging } from '../components/customer/requestMessaging';
import { ExpertMessaging } from '../components/expert/expertMessaging';
import { ExpertHistoryList } from '../components/expert/expertHistoryList';
import { FindAnExpertDetail } from '../components/pages/FindAnExpertDetail';
import AdminReviewsManagement from '../components/admin/reviews';
import AdminQuestionsManagement from '../components/admin/questions';
import AdminUserManagement from '../components/admin/users';
import AdminExpertManagement from '../components/admin/experts';
import AdminDisputeManagement from '../components/admin/disputes';
import AdminCouponManagement from '../components/admin/coupons';
import AdminCreditsManagement from '../components/admin/credits';
import AdminBlogManagement from '../components/admin/blogs';
import { ExpertApplication } from '../components/common/modal/expertDetailModal';
import { ExpertHistoryRequestDetail } from '../components/expert/expertHistoryRequestDetail';
import ExperienceDetails from '../components/expert/experienceDetails';
import Notifications from '../components/common/Notifications';
import GoogleCallback from '../components/common/GoogleCallback';
import Blogs from '../components/public/blogs';
import BlogBox from '../components/common/BlogBox';
import EditBlog from '../components/admin/blogs/EditBlog';


// type 0 = NoHeader Public
// type 1 = Header
// type 2 = Expert
// type 3 = Customer
// type 4 = Admin
export const RoutesList = [
  {
    path: '/',
    type: 1,
    element: <Template />,
    role: [],
  },
  {
    path: '/about-us',
    type: 1,
    element: <AboutUs />,
    role: [],
  },
  {
    path: '/blogs',
    type: 1,
    element: <Blogs />,
    role: [],
  },
  {
    path: '/blogs/:id',
    type: 1,
    element: <BlogBox />,
    role: [],
  },
  {
    path: '/expert/about-us',
    type: 1,
    element: <AboutUs />,
    role: [],
  },
  {
    path: '/find-expert',
    type: 1,
    element: <FindAnExpert />,
    role: [],
  },
  {
    path: '/find-expert-detail/:qid',
    type: 1,
    element: <FindAnExpertDetail />,
    role: [],
  },
  {
    path: '/application-detail/:qid',
    type: 5,
    element: <ExpertApplication />,
    role: [],
  },
  {
    path: '/application-detail/:qid/:print',
    type: 5,
    element: <ExpertApplication />,
    role: [],
  },
  {
    path: '/faq',
    type: 1,
    element: <FaqPage type="client" />,
    role: [],
  },
  {
    path: '/contact',
    type: 1,
    element: <Contact />,
    role: [],
  },
  {
    path: '/expert/contact',
    type: 1,
    element: <Contact />,
    role: [],
  },
  {
    path: '/admin/requests',
    type: 4,
    element: <AdminRequests />,
    role: [],
  },
  {
    path: '/admin/reviews',
    type: 4,
    element: <AdminReviewsManagement />,
    role: [],
  },
  {
    path: '/admin/questions',
    type: 4,
    element: <AdminQuestionsManagement />,
    role: [],
  },
  {
    path: '/admin/users',
    type: 4,
    element: <AdminUserManagement />,
    role: [],
  },
  {
    path: '/admin/disputes',
    type: 4,
    element: <AdminDisputeManagement />,
    role: [],
  },
  {
    path: '/admin/coupons',
    type: 4,
    element: <AdminCouponManagement />,
    role: [],
  },
  {
    path: '/admin/credits',
    type: 4,
    element: <AdminCreditsManagement />,
    role: [],
  },
  {
    path: '/admin/blogs',
    type: 4,
    element: <AdminBlogManagement />,
    role: [],
  },
  {
    path: '/admin/blogs/:id',
    type: 4,
    element: <BlogBox />,
    role: [],
  },
  {
    path: '/admin/edit-blog/:id',
    type: 4,
    element: <EditBlog />,
    role: [],
  },
  {
    path: '/admin/experts',
    type: 4,
    element: <AdminExpertManagement />,
    role: [],
  },
  {
    path: '/login',
    type: 0,
    element: <Login />,
    role: [],
    header: 'no',
  },
  {
    path: '/set-password',
    type: 0,
    element: <SetPassword />,
    role: [],
    header: 'no',
  },
  {
    path: '/change-password',
    type: 2,
    element: <ChangePassword />,
    role: [],
    header: 'no',
  },
  {
    path: '/forget-password',
    type: 0,
    element: <ForgetPassword />,
    role: [],
    header: 'no',
  },
  {
    path: '/profile',
    type: 2,
    element: <Profile />,
    role: [],
    header: 'no',
  },
  {
    path: '/dashboard/expert/request',
    type: 2,
    element: <ExpertRequestList />,
    role: [],
    header: 'no',
  },
  {
    path: '/dashboard/expert/history',
    type: 2,
    element: <ExpertHistoryList />,
    role: [],
    header: 'no',
  },
  {
    path: '/dashboard/expert/detail',
    type: 2,
    element: <ExpertDetails />,
    role: [],
    header: 'no',
  },
  {
    path: '/dashboard/expert/experiences/:print',
    type: 2,
    element: <ExperienceDetails />,
    role: [],
    header: 'no',
  },
  {
    path: '/dashboard/expert/messages',
    type: 2,
    element: <ExpertMessaging />,
    role: [],
    header: 'no',
  },
  {
    path: '/dashboard/expert/request/active/:qid',
    type: 2,
    element: <ExpertActiveRequestDetail />,
    role: [],
    header: 'no',
  },
  {
    path: '/dashboard/expert/request/new/:qid',
    type: 2,
    element: <ExpertNewRequestDetail />,
    role: [],
    header: 'no',
  },
  {
    path: '/dashboard/expert/history/request/:qid',
    type: 2,
    element: <ExpertHistoryRequestDetail />,
    role: [],
    header: 'no',
  },
  {
    path: '/expert',
    type: 1,
    element: <ExpertHomePage />,
    role: [],
    // header: 'no'
  },
  {
    path: '/expert/faq',
    type: 1,
    element: <FaqPage type="expert" />,
    role: [],
    header: 'no',
  },
  {
    path: '/signup',
    type: 3,
    steps: true,
    signup: true,
    element: <SignUp />,
    role: [],
    header: 'no',
  },
  {
    path: '/expert/signup',
    type: 3,
    steps: true,
    signup: true,
    element: <ExpertSignup />,
    role: [],
    header: 'no',
  },
  {
    path: '/expert/profile/:eid?',
    type: 3,
    steps: true,
    // signup: true,
    element: <ExpertRequest />,
    role: [],
    header: 'no',
  },
  {
    path: '/request',
    type: 3,
    steps: true,
    signup: true,
    element: <Request />,
    role: [],
    header: 'no',
  },
  {
    path: '/request/:qid',
    type: 3,
    steps: true,
    element: <Request />,
    role: [],
    header: 'no',
  },
  {
    path: '/my-request/:qid',
    type: 3,
    element: <MyRequestDetail />,
    role: [],
    header: 'no',
  },
  {
    path: '/dashboard/customer',
    type: 3,
    element: <RequestList />,
    role: [],
    header: 'no',
  },


  {
    path: '/dashboard/customer/history',
    type: 3,
    element: <RequestHistory />,
    role: [],
    header: 'no',
  },
  {
    path: '/dashboard/customer/messages',
    type: 3,
    element: <RequestMessaging />,
    role: [],
    header: 'no',
  },
  {
    path: '/dashboard/customer/history/:qid',
    type: 3,
    element: <RequestHistoryDetail />,
    role: [],
    header: 'no',
  },
  {
    path: '/notifications',
    type: 3,
    element: <Notifications />,
    role: [],
    header: 'no',
  },
  {
    path: '/auth/google',
    type: 3,
    steps: true,
    signup: true,
    element: <GoogleCallback />,
    role: [],
    header: 'no',
  },
];
