import { Button, Divider, Spin } from 'antd';
import React, { useEffect } from 'react';
import ExpertDegrees from './steps/expertDegrees';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { OTHER_SUB_CATEGORY_IDS } from '../../helpers/constant';
import { getUserData } from '../../helpers/functions';

export default function ExperienceDetails() {
  const {
    expertDetailById,
    expertExperienceDetailById,
    expertLicensesDetailById,
    expertServiceAndRateDetailById,
    isExpertDetailLoading,
    expertDegreesDetailById,
    isExpertDegreesDetailLoading,
  } = useSelector(state => state.requests);
  const navigate = useNavigate();
  const { print } = useParams();
  const width =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const handlePrint = () => {
    window.print();
  };
  const dispatch = useDispatch();
  const { locationList } = useSelector(state => state.common);
  const { isUpdating } = useSelector(state => state.auth);
  useEffect(() => {
    // only fetch data in print mode, (data is fetched from parent (expertDetails) in non print mode)
    if (print) {
      if (locationList.length === 0) {
        dispatch({ type: 'GET_LOCATION_REQUEST', formData: { status: 'active' } });
      }

      if (getUserData().id) {
        dispatch({ type: 'GET_EXPERT_DETAIL_BY_ID', id: getUserData().id });
        dispatch({
          type: 'GET_EXPERT_SERVICE_AND_RATE_DETAIL_BY_ID',
          formData: { expert_id: getUserData().id },
        });
        dispatch({
          type: 'GET_EXPERT_EXPERIENCE_DETAIL_BY_ID',
          formData: { expert_id: getUserData().id },
        });
        dispatch({
          type: 'GET_EXPERT_LICENSES_DETAIL_BY_ID',
          formData: { expert_id: getUserData().id },
        });
        dispatch({
          type: 'GET_EXPERT_DEGREES_DETAIL_BY_ID',
          formData: { expert_id: getUserData().id },
        });
      }
    }
  }, [dispatch, isUpdating]);
  useEffect(() => {
    if (!!print && !isExpertDetailLoading) {
      // Ensure page is rendered
      setTimeout(() => {
        handlePrint();
      }, 250);
    }
  }, [print, isExpertDetailLoading, expertDetailById]);
  return (
    <section className="expert-detail-body">
      {isExpertDetailLoading ? (
        <div
          style={{ width: (width * 80) / 100, height: (height * 50) / 100 }}
          className="d-flex-a-c"
        >
          <Spin />
        </div>
      ) : (
        <section style={{ backgroundColor: 'white' }} className="expert-detail-right-container">
          <div className="expert-detail-right-container-title">Experience Details</div>
          <div className="d-flex expert-detail-right-wrapper">
            {/*  */}
            <div className="expert-detail-right-container-row-container">
              <div className="expert-detail-right-container-question">
                Are you authorized to work in Canada?
              </div>
              <div className="expert-detail-right-container-answer">
                {expertDetailById?.is_authorized === 0 ? 'No' : 'Yes'}
              </div>
            </div>
            {/*  */}
            <div className="expert-detail-right-container-row-container">
              <div className="expert-detail-right-container-question">
                Location
              </div>
              <div className="expert-detail-right-container-answer">
                {expertDetailById?.default_location?.map((el, i) => {
                  return (
                    <span key={i}>
                      {el?.name}
                      <br />
                    </span>
                  );
                })}
              </div>
            </div>
            {/*  */}
            <div className="expert-detail-right-container-row-container">
              <div className="expert-detail-right-container-question">Linkedin profile</div>
              <div className="expert-detail-right-container-answer">
                {expertDetailById?.linkedin_profile}
              </div>
            </div>
            {/*  */}
            {!!print || (
              <div className="expert-detail-right-container-row-container">
                <div className="expert-detail-right-container-question">Resume</div>
                <div className="expert-detail-right-container-answer">
                  <a
                    style={{ color: '#000', textDecoration: 'underline' }}
                    href={`${expertDetailById?.upload_resume}`}
                  >
                    View file
                  </a>
                </div>
              </div>
            )}
            {/*  */}
            <div className="expert-detail-right-container-row-container">
              <div className="expert-detail-right-container-question">I prefer to serve</div>
              <div className="expert-detail-right-container-answer">
                {expertDetailById['prefer-customer']?.map((el, i) => {
                  return (
                    <span key={i}>
                      {el?.name}
                      <br />
                    </span>
                  );
                })}
              </div>
            </div>
            {/*  */}
            <div className="expert-detail-right-container-row-container">
              <div className="expert-detail-right-container-question">
                I am ready to help them with
              </div>
              <div className="expert-detail-right-container-answer">
                {expertDetailById?.category?.map((el, i) => {
                  return (
                    <span key={i}>
                      {el.name}
                      <br />
                    </span>
                  );
                })}
              </div>
            </div>
            <Divider className="mt10 mb10" />
            {/*  */}
            <ExpertDegrees
              degrees={expertDegreesDetailById?.data}
              isLoading={isExpertDegreesDetailLoading}
            />
            {/*  */}
            {expertServiceAndRateDetailById &&
              expertServiceAndRateDetailById?.data?.length &&
              expertLicensesDetailById?.data?.map((data, i) => {
                return (
                  <article key={i}>
                    <div className="expert-detail-right-container-row-container">
                      <div className="expert-detail-right-container-question">
                        I hold the following professional license(s) and certificates
                      </div>
                      <div className="expert-detail-right-container-answer">
                        {data?.designation}
                      </div>
                    </div>
                    {/*  */}
                    <div className="expert-detail-right-container-row-container">
                      <div className="expert-detail-right-container-question">
                        Issuance date MM/YYYY
                      </div>
                      <div className="expert-detail-right-container-answer">
                        {data?.issuance_date}
                      </div>
                    </div>
                    {/*  */}
                    <div className="expert-detail-right-container-row-container">
                      <div className="expert-detail-right-container-question">
                        Expiration date (If any) MM/YYYY
                      </div>
                      <div className="expert-detail-right-container-answer">
                        {data?.expiration_date}
                      </div>
                    </div>
                    {/*  */}
                    <div className="expert-detail-right-container-row-container">
                      <div className="expert-detail-right-container-question">License No.</div>
                      <div className="expert-detail-right-container-answer">{data?.license_no}</div>
                    </div>
                    {/*  */}
                    <div className="expert-detail-right-container-row-container">
                      <div className="expert-detail-right-container-question">
                        Designation/qualification URL
                      </div>
                      <div className="expert-detail-right-container-answer">
                        {data?.designation_url}
                      </div>
                    </div>
                    <Divider className="mt10 mb10" />
                  </article>
                );
              })}
            {/*  */}
            <div className="expert-detail-right-container-row-container">
              <div className="expert-detail-right-container-question">
                I prefer to serve clients within
              </div>
              <div className="expert-detail-right-container-answer">
                {expertDetailById?.locations?.map((el, i) => {
                  return (
                    <span key={i}>
                      {el?.name}
                      <br />
                    </span>
                  );
                })}
              </div>
            </div>
            <Divider className="mt10 mb10" />
            {/*  */}
            <div className="expert-detail-right-container-row-container">
              <div className="expert-detail-right-container-question">Language I speak</div>
              <div className="expert-detail-right-container-answer">
                {expertDetailById?.languages?.map((el, i) => {
                  return (
                    <span key={i}>
                      {el?.name}
                      <br />
                    </span>
                  );
                })}
              </div>
            </div>
            <Divider className="mt10 mb10" />
            {/*  */}
            {/* <div className='expert-detail-right-container-row-container'>
        <div className='expert-detail-right-container-question'>
          I have experience in this/these industry(ies)
        </div>
        <div className='expert-detail-right-container-answer'>
          {expertExperienceDetailById?.data.map((el, i) => {
            return (
              <span>{el.industry?.name}<br /></span>
            )
          })}
        </div>
      </div> */}
            {expertServiceAndRateDetailById &&
              expertServiceAndRateDetailById?.data?.length &&
              expertServiceAndRateDetailById?.data &&
              expertExperienceDetailById?.data?.map((el, i) => {
                return (
                  <article key={i}>
                    <div className="expert-detail-right-container-row-container">
                      <div className="expert-detail-right-container-question">
                        I have experience in this/these industry(ies)
                      </div>
                      <div className="expert-detail-right-container-answer">
                        {el?.industry?.name}
                        {/* Show industry sub categories */}
                        {el?.industry_sub_categories.length > 0 && (
                          <div>
                            (
                            {el?.industry_sub_categories?.map((subEl, subI, arr) => (
                              <span key={subI}>
                                {subEl?.name}
                                {subI < arr.length - 1 && ', '}
                              </span>
                            ))}
                            )
                          </div>
                        )}
                      </div>
                    </div>
                    {/*  */}
                    <div className="expert-detail-right-container-row-container">
                      <div className="expert-detail-right-container-question">No. years</div>
                      <div className="expert-detail-right-container-answer">
                        {/* {calculateTotalNumberOfExperienceInYear()} */}
                        {el?.number_of_years}
                      </div>
                    </div>
                    {/*  */}
                    <div className="expert-detail-right-container-row-container">
                      <div className="expert-detail-right-container-question">No. months</div>
                      <div className="expert-detail-right-container-answer">
                        {/* {calculateTotalNumberOfExperienceInMonths()} */}
                        {el?.number_of_months}
                      </div>
                    </div>
                    {/*  */}
                    <div className="expert-detail-right-container-row-container">
                      <div className="expert-detail-right-container-question">
                        Experience gained while working
                      </div>
                      <div className="expert-detail-right-container-answer">
                        {el?.experience_gained}
                      </div>
                    </div>
                    {/*  */}
                    <div className="expert-detail-right-container-row-container">
                      <div className="expert-detail-right-container-question">
                        Privately owned business
                      </div>
                      <div className="expert-detail-right-container-answer">
                        {el.privately_owned_business === 0 ? 'No' : 'Yes'}
                      </div>
                    </div>
                    <Divider className="mt10 mb10" />
                  </article>
                );
              })}
            {/*  */}
            {expertServiceAndRateDetailById &&
              expertServiceAndRateDetailById?.data?.length &&
              expertServiceAndRateDetailById?.data &&
              expertServiceAndRateDetailById?.data?.map((data, i) => {
                return (
                  <article key={i}>
                    <div className="expert-detail-right-container-row-container">
                      <div className="expert-detail-right-container-question">
                        {data.main_category.name}
                      </div>
                      <div className="expert-detail-right-container-answer">
                        {OTHER_SUB_CATEGORY_IDS.includes(data.sub_category.id)
                          ? data.other_sub_category_name
                          : data.sub_category?.name}
                      </div>
                    </div>
                    {/*  */}
                    <div className="expert-detail-right-container-row-container">
                      <div className="expert-detail-right-container-question">ASPE</div>
                      <div className="expert-detail-right-container-answer">
                        {data.aspe === 0 ? 'No' : 'Yes'}
                      </div>
                    </div>
                    {/*  */}
                    <div className="expert-detail-right-container-row-container">
                      <div className="expert-detail-right-container-question">IFRS</div>
                      <div className="expert-detail-right-container-answer">
                        {data.ifrs === 0 ? 'No' : 'Yes'}
                      </div>
                    </div>
                    {/*  */}
                    <div className="expert-detail-right-container-row-container">
                      <div className="expert-detail-right-container-question">Experience level</div>
                      <div className="expert-detail-right-container-answer">
                        {data?.experience_level?.name}
                      </div>
                    </div>
                    {/*  */}
                    <div className="expert-detail-right-container-row-container">
                      <div className="expert-detail-right-container-question">Rate type</div>
                      <div className="expert-detail-right-container-answer">
                        {data?.rate_type?.name}
                      </div>
                    </div>
                    {/*  */}
                    <div className="expert-detail-right-container-row-container">
                      <div className="expert-detail-right-container-question">Fees rate</div>
                      <div className="expert-detail-right-container-answer">
                        CAD${data?.rate_from} - CAD${data?.rate_to}
                      </div>
                    </div>
                    <Divider className="mt10 mb10" />
                  </article>
                );
              })}
            {!!print || (
              <div className="expert-detail-update-button-wrapper">
                <Button type="primary" onClick={() => navigate('/expert/profile')}>
                  Update Detail
                </Button>
              </div>
            )}
          </div>
        </section>
      )}
    </section>
  );
}
