/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, Form, Button, Spin, Radio, Space, Checkbox, Divider } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUserData } from '../../../helpers/functions';
import { IoMdArrowRoundBack } from 'react-icons/io';

const Location = ({ handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const { eid } = useParams();
  const [form] = Form.useForm();
  const { isLocationLoading, locationList } = useSelector(state => state.common);
  const { isUpdating, updatingMsg } = useSelector(state => state.auth);
  const { isExpertInfoLoading, expertDetails } = useSelector(state => state.expert);
  const { isExpertUpdating, stepExpertMsg } = useSelector(state => state.steps);
  const [checkedList, setCheckedList] = useState([]);
  const checkAll = locationList.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < locationList.length;

  useEffect(() => {
    if (eid || getUserData().id) {
      dispatch({ type: 'EXPERT_DETAIL_REQUEST', id: eid || getUserData().id });
    }
    dispatch({ type: 'GET_LOCATION_REQUEST', formData: { status: 'active' } });
  }, []);

  const onCheckAllChange = e => {
    setCheckedList(e.target.checked ? locationList.map(item => item.id) : []);
    form.setFieldsValue({
      location_ids: e.target.checked ? locationList.map(item => item.id) : [],
    });
  };
  const onChange = list => {
    setCheckedList(list);
    form.setFieldsValue({
      location_ids: list,
    });
  };

  useEffect(() => {
    if (
      !isExpertInfoLoading &&
      Object.keys(expertDetails).length > 0 &&
      !isLocationLoading &&
      locationList.length > 0
    ) {
      let tmpIds =
        expertDetails.locations !== null && expertDetails.locations.length
          ? expertDetails.locations.map(i => i.id)
          : [];
      form.setFieldsValue({ location_ids: tmpIds });
      // Need to have separate state to handle checkAll logic otherwise form submission won't work correctly
      setCheckedList(tmpIds);
    }
  }, [isExpertInfoLoading, expertDetails, isLocationLoading, locationList]);

  const onFinish = values => {
    dispatch({
      type: 'EXPERT_STEP_UPDATE_REQUEST',
      formData: {
        ...values,
        expert_id: eid || getUserData().id,
      },
      requestType: 'put',
      step: 'location',
    });
  };

  useEffect(() => {
    if (!isExpertUpdating && stepExpertMsg === 'success') {
      handleNext('language');
      dispatch({ type: 'CLEAR_STEPS' });
    }
  }, [isExpertUpdating, stepExpertMsg]);

  return (
    <div>
      <div className="site-card-border-less-wrapper card-wrapper">
        <Card bordered={false}>
          {isLocationLoading && <Spin />}
          {!isLocationLoading && (
            <>
              <p className="m-title tl mb-7 d-flex-center">
                <span className="back-icon" onClick={() => handleBack('location')}>
                  <IoMdArrowRoundBack />
                </span>
                <span>I can virtually serve clients in the following location(s)</span>
              </p>
              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                form={form}
                onFinish={onFinish}
              >
                <Form.Item>
                  <Checkbox
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={checkAll}
                  >
                    Check all
                  </Checkbox>
                  <Divider />
                </Form.Item>
                <Form.Item
                  name="location_ids"
                  rules={[{ required: true, message: 'Please select location' }]}
                >
                  <Checkbox.Group
                    className={'mt3'}
                    style={{ width: '100%' }}
                    onChange={onChange}
                    value={checkedList}
                    key={'checkAllKey'}
                  >
                    <Space direction="vertical" size={'middle'} style={{ width: '100%' }}>
                      {!isLocationLoading &&
                        locationList.map(item => {
                          return (
                            <>
                              <Checkbox key={item.id} value={item.id}>
                                {' '}
                                {item.name}
                              </Checkbox>
                            </>
                          );
                        })}
                    </Space>
                  </Checkbox.Group>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    className="mt-5"
                    disabled={isExpertUpdating}
                    loading={isExpertUpdating}
                  >
                    Next
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </Card>
      </div>
    </div>
  );
};
export default Location;
