import React, { useCallback, useState, useEffect, useRef } from 'react';
import {
  Card,
  Form,
  Button,
  Spin,
  Input,
  Row,
  Col,
  Divider,
  message,
  Radio,
  Select,
  Rate,
  Modal,
  Tooltip,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';
import { getUserData } from '../../../helpers/functions';
import { CloseOutlined } from '@ant-design/icons';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { AddAnotherCard } from '../../common/addAnotherCard';
import { ExpertSignUpSuccessModal } from '../../common/modal/expertSignUpSuccessModal';
import { OTHER_SUB_CATEGORY_IDS, OTHER_SUB_CATEGORY_IDS_OBJ } from '../../../helpers/constant';
import { useUnload } from '../../../helpers/useUnload';
import WarningModal from '../../common/modal/WarningModal';
import Cookies from 'js-cookie';


const ServicesAndRates = ({ handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { eid } = useParams();
  const [form] = Form.useForm();

  const {
    isMainSubCatLoading,
    mainSubList,
    isMainSubEmpty,
    isExpertInfoLoading,
    expertDetails,
    isSubCatLoading,
    allSubCategory,
    isExpTypeLoading,
    expTypeList,
    isRateTypeLoading,
    rateTypeList,
  } = useSelector(state => state.expert);
  const [tmpCategoryList, setTmpCategoryList] = useState([]);
  const [servicesAndRatesHtml, setServicesAndRatesHtml] = useState('');
  const { isAdminDeleteServicesAndRateLoading } = useSelector(state => state.admin);
  const { isCertificateUpdating, certiMsg } = useSelector(state => state.steps);
  const [visibleModal, setVisibleModal] = useState(false);
  const [tempServicesAndRates, setTempServicesAndRates] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateDetailsFormChanged, setIsUpdateDetailsFormChanged] = useState(false);
  const [initialServicesFormValue, setInitialServicesFormValue] = useState();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [rateValue, setRateValue] = useState(null);
  const rateFromRef = useRef(null);
  const rateToRef = useRef(null);
  const handleOk = () => {
    setIsModalOpen(false);
    blocker?.proceed();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    blocker?.reset();
  };

  //🏁 START Logic to block user navigation if form isn't updated

  useUnload(e => {
    // this will show the browser's default confirm
    e.preventDefault();
  }, isUpdateDetailsFormChanged);

  let shouldBlock = React.useCallback(({ currentLocation, nextLocation }) => {
    return currentLocation.search !== nextLocation.search;
  }, []);
  // Block navigating elsewhere when data has been entered into the input
  let blocker = useBlocker(shouldBlock);

  // Reset the blocker on page load (blocker proceed & reset won't be defined otherwise)
  useEffect(() => {
    blocker.state = 'unblocked';
  }, []);

  // set initial details form state once
  useEffect(() => {
    const IS_INITIAL_SERVICES_FORM_STATE_EMPTY =
      initialServicesFormValue && Object.keys(initialServicesFormValue).length === 0;

    const IS_SERVICES_FORM_EMPTY =
      form.getFieldsValue() && Object.keys(form.getFieldsValue()).length !== 0;

    if (
      !initialServicesFormValue ||
      (IS_INITIAL_SERVICES_FORM_STATE_EMPTY && IS_SERVICES_FORM_EMPTY)
    ) {
      setInitialServicesFormValue(form.getFieldsValue());
    }
  }, [form, form.getFieldsValue()]);

  useEffect(() => {
    if (blocker.state === 'blocked' && isUpdateDetailsFormChanged) {
      setIsModalOpen(true);
    } else {
      blocker.proceed?.();
    }
  }, [blocker, blocker.state, isUpdateDetailsFormChanged]);

  //🏁 END Logic to block user navigation if form isn't updated

  useEffect(() => {
    const getExpertMainSub = () => {
      dispatch({
        type: 'EXPERT_MAINSUB_CAT_REQUEST',
        formData: { expert_id: eid || getUserData().id },
      });
    };

    const getExpertDetail = () => {
      dispatch({ type: 'EXPERT_DETAIL_REQUEST', id: eid || getUserData().id });
      dispatch({ type: 'EXPERT_ALL_SUB_CATEGORY_REQUEST' });
      dispatch({ type: 'EXPERINCE_TYPE_LIST_REQUEST' });
      dispatch({ type: 'RATE_TYPE_LIST_REQUEST' });
    };

    getExpertMainSub();
    getExpertDetail();
  }, [isAdminDeleteServicesAndRateLoading, eid, dispatch]);

  useEffect(() => {
    if (
      !isExpertInfoLoading &&
      Object.keys(expertDetails).length > 0 &&
      !isSubCatLoading &&
      allSubCategory.length > 0 &&
      !isMainSubCatLoading
    ) {
      if (expertDetails.category !== null && expertDetails.category.length > 0) {
        setTmpCategoryList(expertDetails.category);
      }
    }
  }, [
    isExpertInfoLoading,
    expertDetails,
    isSubCatLoading,
    allSubCategory,
    isMainSubCatLoading,
    mainSubList,
    isAdminDeleteServicesAndRateLoading,
  ]);

  const filterSubCategory = useCallback(
    parentId => {
      return allSubCategory.filter(
        item => item.parent_id === parentId && !OTHER_SUB_CATEGORY_IDS.includes(item.id),
      );
    },
    [allSubCategory],
  );

  const optionsList = items => {
    return (
      items?.map(item => ({
        value: item.id,
        label: item.name,
      })) ?? []
    );
  };

  useEffect(() => {
    if (!isMainSubCatLoading && isMainSubEmpty !== undefined && !isSubCatLoading) {
      // Initialize form with all categories
      let servicesAndRates = [];

      servicesAndRates = tmpCategoryList.map(category => {
        return {
          expert_id: eid || getUserData().id,
          tableForm: [],
          category_id: category.id || '',
          sub_category_id: [],
        };
      });

      if (mainSubList.length > 0) {
        const GROUP_BY_CATEGORY_ID = Object.groupBy(
          mainSubList,
          ({ main_category }) => main_category.id,
        );
        for (const [, groupedArr] of Object.entries(GROUP_BY_CATEGORY_ID)) {
          let currentObj = {
            sub_category_id: [],
            tableForm: [],
            category_id: '',
          };
          groupedArr.forEach(arr => {
            currentObj = {
              ...currentObj,
              ...arr,
            };
            // Don't add subCategories of 'others' category to the selection list to avoid showing and id without a label
            // It gets added to the tableform property and is submitted correctly
            if (!OTHER_SUB_CATEGORY_IDS.includes(arr.sub_category.id)) {
              currentObj.sub_category_id.push(arr.sub_category.id);
            }
            currentObj.category_id = arr.main_category.id;
            currentObj.tableForm.push({
              ...arr,
              category_id: arr?.main_category?.id,
              sub_category_id: arr?.sub_category?.id,
              experience_level_id: arr?.experience_level?.id,
              rate_type_id: arr?.rate_type?.id,
              other_sub_category_name: arr?.other_sub_category_name,
            });
          });
          // Remove redundant categories already filled by user
          servicesAndRates = servicesAndRates.map(item => {
            let newItem = item;
            if (item.category_id === currentObj.category_id) {
              newItem = currentObj;
            }
            return newItem;
          });
        }
      }
      setTempServicesAndRates({
        servicesAndRates,
      });

      setSubCategoryOptions(
        servicesAndRates.reduce((acc, item, index) => {
          return {
            ...acc,
            [index]: optionsList(filterSubCategory(item.category_id)),
          };
        }, {}),
      );
    }
  }, [
    isMainSubCatLoading,
    isSubCatLoading,
    allSubCategory,
    mainSubList,
    isMainSubEmpty,
    isAdminDeleteServicesAndRateLoading,
    eid,
    filterSubCategory,
    tmpCategoryList,
  ]);

  useEffect(() => {

    function handleRateTypeClick(RateType, rowID, field, event) {
      const categoryid = event.target.dataset.categoryid;
      const formFields = form.getFieldsValue();
      const servicesAndRates = formFields.servicesAndRates;
      console.log(servicesAndRates, categoryid);
      for (let i = 0; i < servicesAndRates.length; i++) {
        if (servicesAndRates[i]?.category_id == categoryid) {
          const tableForm = servicesAndRates[i].tableForm;
          for (let j = tableForm.length - 1; j >= 0; j--) {
            console.log(i, j, rowID, "row");
            if (tableForm[j]?.rate_type_id == RateType?.id) {

              form.setFieldValue(
                ['servicesAndRates', i, 'tableForm', rowID.name, 'rate_from'],
                tableForm[j].rate_from
              );

              form.setFieldValue(
                ['servicesAndRates', i, 'tableForm', rowID.name, 'rate_to'],
                tableForm[j].rate_to
              );
              return;
            }
          }
        }
      }
    }

    function onInputChange(event, rowID, field, value) {
      // console.log('change', rowID, field, value, event.target.value);
    }
    const onFinish = values => {
      const SELECTIONS_ARR = values.servicesAndRates
        .map(item => {
          return item.tableForm.map(innerItem => {
            return innerItem;
          });
        })
        .flat(Infinity);
      const data = SELECTIONS_ARR.map(item => ({
        ...Object.keys(item).reduce(
          (acc, key) => {
            if (item[key] != null && item[key] !== '') {
              acc[key] = item[key];
            }
            return acc;
          },
          {
            expert_id: eid || getUserData().id,
          },
        ),
      }));

      if (data.length === 0) {
        message.error({
          content: 'Please add at least one service and rate',
          className: 'error-class',
          duration: 4,
        });
        return;
      }

      dispatch({
        type: 'EXPERT_CERTIFICATE_UPDATE_REQUEST',
        formData: data,
        moduleType: 'mainSubCat',
      });
      setIsUpdateDetailsFormChanged(false);
    };

    const handleServiceAndRateDelete = (field, innerField, id, subCatId, innerRemove) => {
      const FILTERED_SUBCATEGORIES = form
        .getFieldValue(['servicesAndRates', field.name, 'sub_category_id'])
        .filter(subCat => subCat !== subCatId);
      form.setFieldValue(
        ['servicesAndRates', field.name, 'sub_category_id'],
        FILTERED_SUBCATEGORIES,
      );
      // Must be removed after filtering to ensure form's change detection is triggered
      innerRemove(innerField.name);
      // ID comes form BE and gets set after submission
      // if (id) {
      //   dispatch({ type: 'ADMIN_DELETE_SERVICES_AND_RATE', id });
      // }
    };
    const handleSubCategorySelect = (subCategoryId, name, innerAdd) => {
      const CATEGORY_ID = form.getFieldValue(['servicesAndRates', name, 'category_id']);
      // Add the selected subcategory to the inner form list item
      innerAdd({
        category_id: CATEGORY_ID,
        sub_category_id: subCategoryId,
      });
    };

    const handleSubCategoryDeSelect = (subCategoryId, name, innerRemove) => {
      const index = form.getFieldValue(['servicesAndRates', name, 'tableForm']).findIndex(item => {
        return item.sub_category_id === subCategoryId;
      });
      innerRemove(index);
    };

    const handleSubcategoryClear = name => {
      form.setFieldValue(['servicesAndRates', name, 'tableForm'], []);
    };

    const handleAddOtherSubCategories = (categoryId, innerAdd) => {
      innerAdd({
        category_id: categoryId,
        sub_category_id: OTHER_SUB_CATEGORY_IDS_OBJ[categoryId],
        rate_from: 0,
        rate_to: 0,

      });
    };
    const loadServicesAndRates = () => {
      return (
        <Form.Provider
          onFormChange={() => {
            if (
              JSON.stringify(form.getFieldsValue()) !== JSON.stringify(initialServicesFormValue)
            ) {
              setIsUpdateDetailsFormChanged(true);
            } else {
              setIsUpdateDetailsFormChanged(false);
            }
          }}
        >
          <Form layout="vertical" name="basic" form={form} onFinish={onFinish}>
            <Form.List name="servicesAndRates">
              {(fields, { add, remove }) => {
                return (
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {fields.map(field => {
                      let innerAdd = null;
                      let innerRemove = null;
                      const category = tmpCategoryList?.find(category => {
                        return (
                          category.id ===
                          form.getFieldValue(['servicesAndRates', field.name, 'category_id']) ||
                          null
                        );
                      });
                      const { id: CATEGORY_ID, name: CATEGORY_NAME } = category;

                      return (
                        <div className="certificate-section" key={field.name}>
                          <Row gutter={30} className={'mb20'}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <h1>{CATEGORY_NAME}</h1>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <Form.Item
                                style={{ display: 'none' }}
                                label="Main Category"
                                name={[field.name, 'category_id']}
                                className="form-field"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please enter designation(s) / qualification(s)',
                                  },
                                ]}
                              ></Form.Item>
                              <Form.Item
                                style={{ display: 'none' }}
                                name={[field.name, 'id']}
                                className="form-field"
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <Form.Item
                                label="Sub Category"
                                name={[field.name, 'sub_category_id']}
                                className="form-field"
                                loading={allSubCategory.length > 0 ? false : true}
                              >
                                <Select
                                  mode="multiple"
                                  placeholder="Select an option"
                                  style={{
                                    width: '100%',
                                  }}
                                  size="large"
                                  showSearch
                                  allowClear
                                  onClear={() => handleSubcategoryClear(field.name)}
                                  onDeselect={e =>
                                    handleSubCategoryDeSelect(e, field.name, innerRemove)
                                  }
                                  onSelect={e => handleSubCategorySelect(e, field.name, innerAdd)}
                                  filterOption={(input, option) =>
                                    (option?.label ?? '')
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  options={subCategoryOptions[field.name]}
                                />
                              </Form.Item>
                            </Col>

                            {/* TABLE FORM  */}

                            <Form.List
                              name={[field.name, 'tableForm']}
                              style={{ width: '100%' }}
                              layout="vertical"
                            >
                              {(innerFields, { add, remove }) => {
                                innerAdd = add;
                                innerRemove = remove;
                                return (
                            
                                  <article className="table-container" style={{ textAlign: 'left' }}>
                                    <table>
                                      <thead>
                                        <tr style={{ textAlign: 'left' }}>
                                          <th>Sub Category</th>
                                          <th>Experience Level</th>
                                          <th>Rate Type</th>
                                          <th className="width-12 ">Rate From</th>
                                          <th className="width-12 ">Rate To</th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                      {innerFields.map(innerField => {
                                        // CONSTANTS
                                        const innerFormSubCatId = form.getFieldValue([
                                          'servicesAndRates',
                                          field.name,
                                          'tableForm',
                                          innerField.name,
                                          'sub_category_id',
                                        ]);
                                        const SUBCATEGORY_NAME = subCategoryOptions[
                                          field.name
                                        ].filter(subCat => {
                                          return subCat?.value === innerFormSubCatId;
                                        })[0]?.label;
                                        // CONSTANTS
                                        return (
                                          <tbody key={innerField.name}>
                                            <tr>
                                              <td>
                                                <Form.Item
                                                  style={{ display: 'none' }}
                                                  label="Sub Category"
                                                  name={[innerField.name, 'sub_category_id']}
                                                  className="form-field"
                                                  loading={allSubCategory.length > 0 ? false : true}
                                                ></Form.Item>
                                                <p className="subcategory-name">
                                                  {SUBCATEGORY_NAME}
                                                </p>
                                                {OTHER_SUB_CATEGORY_IDS.includes(
                                                  innerFormSubCatId,
                                                ) && (
                                                    <Form.Item
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message: 'Required',
                                                        },
                                                      ]}
                                                      name={[
                                                        innerField.name,
                                                        'other_sub_category_name',
                                                      ]}
                                                      className="form-field"
                                                    >
                                                      <Input size="large" placeholder="Type here" />
                                                    </Form.Item>
                                                  )}
                                              </td>
                                              <td>
                                                <Form.Item
                                                  // label="Experience level"
                                                  name={[innerField.name, 'experience_level_id']}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: 'Please select the option',
                                                    },
                                                  ]}
                                                >
                                                  <Rate
                                                    style={{ width: 'max-content' }}
                                                    tooltips={expTypeList.map(item => item.name)}
                                                  />
                                                </Form.Item>
                                              </td>
                                              <td>
                                                <Form.Item
                                                  // label="Rate Type"
                                                  name={[innerField.name, 'rate_type_id']}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: 'Please select the option',
                                                    },
                                                  ]}
                                                >
                                                  <Radio.Group
                                                    size="small"
                                                    style={{ width: 'max-content' }}
                                                    buttonStyle="solid"
                                                  >
                                                    {!isRateTypeLoading &&
                                                      rateTypeList.length > 0 &&
                                                      rateTypeList.map(item => {
                                                        return (
                                                          <Radio.Button
                                                            key={item.id}
                                                            value={item.id}
                                                            data-categoryid={CATEGORY_ID}
                                                            onClick={e =>
                                                              handleRateTypeClick(
                                                                item,
                                                                innerField,
                                                                fields,
                                                                e,
                                                              )
                                                            }
                                                          >
                                                            {item.name}
                                                          </Radio.Button>
                                                        );
                                                      })}
                                                  </Radio.Group>
                                                </Form.Item>
                                              </td>
                                              <td>
                                                <Tooltip title="From (CA$)">
                                                  <Form.Item
                                                    // label="Fees rate from (CA$)"
                                                    name={[innerField.name, 'rate_from']}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: 'Required',
                                                      },
                                                      {
                                                        pattern: new RegExp(/^[0-9]{1,5}$/),
                                                        message: 'Max 5 digits',
                                                      },
                                                    ]}
                                                  >
                                                    {/* <Tooltip title="From (CA$)"> */}
                                                    <Input
                                                      type="number"
                                                      size="large"
                                                      placeholder="From (CA$)"
                                                      onChange={e =>
                                                        onInputChange(
                                                          e,
                                                          innerField,
                                                          fields,
                                                          'rate_from',
                                                        )
                                                      }
                                                    ></Input>
                                                    {/* </Tooltip> */}
                                                  </Form.Item>
                                                </Tooltip>
                                              </td>
                                              <td>
                                                <Tooltip title="To (CA$)">
                                                  <Form.Item
                                                    // label="Fees rate to (CA$)"
                                                    name={[innerField.name, 'rate_to']}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: 'Required',
                                                      },
                                                      {
                                                        pattern: new RegExp(/^[0-9]{1,5}$/),
                                                        message: 'Max 5 digits',
                                                      },
                                                    ]}
                                                  >
                                                    <Input
                                                      type="number"
                                                      size="large"
                                                      placeholder="To (CA$)"
                                                      controls={false}
                                                      onChange={e =>
                                                        onInputChange(
                                                          e,
                                                          innerField,
                                                          fields,
                                                          'rate_to',
                                                        )
                                                      }
                                                    ></Input>
                                                  </Form.Item>
                                                </Tooltip>
                                              </td>
                                              <td>
                                                <div className="remove-icon-container">
                                                  <CloseOutlined
                                                    onClick={() =>
                                                      handleServiceAndRateDelete(
                                                        field,
                                                        innerField,
                                                        form.getFieldValue([
                                                          'servicesAndRates',
                                                          field.name,
                                                          'id',
                                                        ]),
                                                        form.getFieldValue([
                                                          'servicesAndRates',
                                                          field.name,
                                                          'tableForm',
                                                          innerField.name,
                                                          'sub_category_id',
                                                        ]),
                                                        innerRemove,
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        );
                                      })}
                                    </table>
                                    {/* Hide button when category is payroll */}
                                    {CATEGORY_ID !== 2 && (
                                      <AddAnotherCard
                                        handleClick={() =>
                                          handleAddOtherSubCategories(
                                            form.getFieldValue([
                                              'servicesAndRates',
                                              field.name,
                                              'category_id',
                                            ]),
                                            innerAdd,
                                          )
                                        }
                                        text={'Add other sub categories'}
                                      />
                                    )}
                                  </article>
                                );
                              }}
                            </Form.List>
                          </Row>
                        </div>
                      );
                    })}
                  </Col>
                );
              }}
            </Form.List>
            <Divider className="mt40 mb40" />
            <Form.Item>
              <Button
                type="primary"
                // htmlType="submit"
                size="middle"
                className="mt5"
                disabled={isCertificateUpdating}
                loading={isCertificateUpdating}
                onClick={() => handleButtonClick()}
              >
                Next
              </Button>
            </Form.Item>
          </Form>
        </Form.Provider>
      );
    };

    if (!tempServicesAndRates?.servicesAndRates?.length) {
      return;
    }

    const html = loadServicesAndRates();

    setServicesAndRatesHtml(html);
  }, [
    tempServicesAndRates,
    form,
    isMainSubCatLoading,
    eid,
    dispatch,
    allSubCategory,
    tmpCategoryList,
    isExpTypeLoading,
    expTypeList,
    isRateTypeLoading,
    rateTypeList,
    subCategoryOptions,
    filterSubCategory,
    isCertificateUpdating,
    initialServicesFormValue,
    rateValue,
  ]);

  const inputRef = useRef(null);
  useEffect(() => {
    inputRef?.current?.focus({
      cursor: 'start',
    });
  }, [servicesAndRatesHtml]);

  useEffect(() => {
    if (!isCertificateUpdating && certiMsg === 'success') {
      handleNext('degree');
      dispatch({ type: 'CLEAR_STEPS' });
    }
  }, [isCertificateUpdating, certiMsg, eid, dispatch, navigate]);

  // Set form manually to avoid value not being updated correctly
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(tempServicesAndRates);
  }, [tempServicesAndRates, form]);


  const handleButtonClick = () => {
    if (form.getFieldValue('servicesAndRates')?.length > 1 || Cookies.get('expert_signup_service_warning_message') === '1') {
      form.submit();
    } else {
      setShowWarningModal(true);
    }
    return;
  }

  const handleWarningModelGotIt = () => {
    Cookies.set('expert_signup_service_warning_message', '1', { expires: 100 });
    setShowWarningModal(false);
  }


  return (
    <div>
      <div  className="site-card-border-less-wrapper card-wrapper">
        <Card bordered={false}>
          {isMainSubCatLoading && <Spin />}
          {!isMainSubCatLoading && (
            <>
              <p className="m-title tl mb-7 d-flex-center">
                <span className="back-icon" onClick={() => handleBack('mainSubCategories')}>
                  <IoMdArrowRoundBack />
                </span>
                <span>The service(s) I intend to provide</span>
              </p>
              {servicesAndRatesHtml}
            </>
          )}
        </Card>
      </div>
      {visibleModal && (
        <ExpertSignUpSuccessModal
          visible={visibleModal}
          setVisible={setVisibleModal}
          navigateTo="/dashboard/expert/request"
        />
      )}
      {/* Modal on navigation without saving changes */}
      <Modal title="Confirm Navigation" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>Changes you made might not be saved, Click on next to save them</p>
      </Modal>
      {
        showWarningModal &&
        <WarningModal
          showWarningModal={showWarningModal}
          handleWarningModelGotIt={handleWarningModelGotIt}
          text={`Make sure to add all services that you are providing. if you have any other service that isn't listed, please use the <strong>"Add Other Sub Categories"</strong> button below each category section to type it.`}
        />
      }
    </div>
  );
};
export default ServicesAndRates;
