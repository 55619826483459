import React from 'react';
import { Helmet as HelmetCompnent } from 'react-helmet';
import  accLogo  from '../../assets/images/Acc_logo/acc-logo.jpg';

const Helmet = ({ title = '', desc, type, image }) => {
  const windowLocation = window.location;
  return (
    <>
      <HelmetCompnent>
        <title>{`Accountizer${title ? ' - ' + title : ''}`}</title>
        <meta name="description" content={desc || 'AI-Powered Accounting Services Platform'} />
        <meta property="og:title" content={`Accountizer${title ? ' - ' + title : ''}`} />
        <meta property="og:description" content={desc || 'AI-Powered Accounting Services Platform'} />
        <meta property="og:type" content={type || 'website'} />
        <meta property="og:url" content={windowLocation} />
        <meta property="og:image" content={image || windowLocation.host+accLogo} />

        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content={`Accountizer${title ? ' - ' + title : ''}`} />
        <meta name="twitter:description" content={desc || 'AI-Powered Accounting Services Platform'} />
        <meta name="twitter:image" content={image || windowLocation.host+accLogo} />

      </HelmetCompnent>
    </>
  );
};
export default Helmet;
