import { useNavigate } from 'react-router-dom';
import logo3 from '../../assets/images/Acc_logo/logo_blue.svg';
import './style.scss';
import PublicHeader from '../layouts/publicHeader';

const Parent = props => {
  const navigate = useNavigate();

  const handleRedirectHome = () => {
    navigate('/');
  };

  return (
    <div className="bg-img">
      <div
        className=""
        style={{ height: '100%', alignContent: !props.isSignupToggle ? 'flex-start' : 'center' }}
      >
        <div
          className={!props.isSignupToggle ? 'w100' : 'auto'}
          style={{ height: !props.isSignupToggle ? '100%' : 'auto' }}
        >
          {/* <div
            style={{
              cursor: 'pointer',
              position: 'absolute',
              top: '3%',
              left: '3.9%',
              width: '20rem',
            }}
            className="w100 tc"
            onClick={handleRedirectHome}
          >
            <img src={logo3} loading="lazy" className="mb25" alt="Accountizer Logo" />
          </div> */}
          <PublicHeader />
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Parent;
