/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, Form, Button, Spin, Radio, Space, Checkbox } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getUserData } from '../../../helpers/functions';
import { IoMdArrowRoundBack } from 'react-icons/io';

const MainCategory = ({ handleNext, dependent = '', handleBack }) => {
  const dispatch = useDispatch();
  const { eid } = useParams();
  const [form] = Form.useForm();
  const { isSCLoading, serviceCategoryList } = useSelector(state => state.common);
  const { isExpertUpdating, stepExpertMsg } = useSelector(state => state.steps);
  const { isExpertInfoLoading, expertDetails } = useSelector(state => state.expert);

  useEffect(() => {
    dispatch({ type: 'GET_SERVICE_CATEGORY_REQUEST', formData: { status: 'active' } });
    if (eid || getUserData().id) {
      dispatch({ type: 'EXPERT_DETAIL_REQUEST', id: eid || getUserData().id });
    }
  }, []);

  useEffect(() => {
    if (
      !isExpertInfoLoading &&
      Object.keys(expertDetails).length > 0 &&
      serviceCategoryList.length > 0 &&
      !isSCLoading
    ) {
      let tmpIds =
        expertDetails['category'] !== null && expertDetails['category'].length
          ? expertDetails['category'].map(i => i.id)
          : [];
      form.setFieldsValue({ category_ids: tmpIds });
    }
  }, [isExpertInfoLoading, expertDetails, serviceCategoryList, isSCLoading]);

  const onChange = e => {};

  const onFinish = values => {
    dispatch({
      type: 'EXPERT_STEP_UPDATE_REQUEST',
      formData: {
        ...values,
        expert_id: eid || getUserData().id,
      },
      requestType: 'put',
      step: 'main-category',
    });

    const getExpertMainSub = () => {
      dispatch({
        type: 'EXPERT_MAINSUB_CAT_REQUEST',
        formData: { expert_id: eid || getUserData().id },
      });
    };
    getExpertMainSub();
  };
  useEffect(() => {
    if (!isExpertUpdating && stepExpertMsg === 'success') {
      handleNext('mainSubCategories');
      dispatch({ type: 'CLEAR_STEPS' });
    }
  }, [isExpertUpdating, stepExpertMsg]);

  return (
    <div>
      <div className="site-card-border-less-wrapper card-wrapper">
        <Card bordered={false}>
          {isSCLoading && <Spin />}
          {!isSCLoading && (
            <>
              <p className="m-title tl mb-7 d-flex-center">
                <span className="back-icon" onClick={() => handleBack('main-category')}>
                  <IoMdArrowRoundBack />
                </span>
                <span>I am ready to help them with</span>
              </p>
              <p className="m-title tl mb-7"></p>
              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                form={form}
                onFinish={onFinish}
              >
                <Form.Item
                  name="category_ids"
                  rules={[{ required: true, message: 'Please select at least one option' }]}
                >
                  <Checkbox.Group className={'mt3'} style={{ width: '100%' }} onChange={onChange}>
                    <Space direction="vertical" size={'middle'} style={{ width: '100%' }}>
                      {serviceCategoryList.map(item => {
                        return (
                          <>
                            <Checkbox key={item.id} value={item.id}>
                              {' '}
                              {item.name}
                            </Checkbox>
                          </>
                        );
                      })}
                    </Space>
                  </Checkbox.Group>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    className="mt5"
                    disabled={isExpertUpdating}
                    loading={isExpertUpdating}
                  >
                    Next
                  </Button>
                </Form.Item>
              </Form>

              {/* <Form
                                layout="vertical"
                                name="basic"
                                hideRequiredMark={true}
                                autoComplete="off"
                            >
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="float-r"
                                        size="middle"
                                    >
                                        Next
                                    </Button>
                                </Form.Item>
                            </Form> */}
            </>
          )}
        </Card>
      </div>
    </div>
  );
};
export default MainCategory;
